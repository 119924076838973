import React from 'react'
import MainTitle from '../molecules/titles/MainTitle.molecule'
import PrimaryButton from '../molecules/buttons/PrimaryButton.molecule'

/**
 * @param {import("./ContentLayoutHeader.d").ContentLayoutHeaderProps} props
 * @returns {JSX.Element}
 */
const ContentLayoutHeader = ({
  title,
  subtitle,
  buttonProps,
  extraContent
}) => {
  return (
    <div className='flex justify-content-between mb-4'>
      <MainTitle
        title={title}
        subtitle={subtitle}
      />
      {buttonProps !== undefined
        ? <div className='flex justify-content-between gap-2'>
          <PrimaryButton
            {...buttonProps}
          />
          {extraContent}
        </div>
        : <div className='flex justify-content-between gap-2'></div>
      }
    </div>
  )
}

export default ContentLayoutHeader
