import React from 'react'
import ContentLayoutHeader from '../../../components/layouts/ContentLayoutHeader.layout'

const IndividualReportHeader = React.forwardRef(({ currentTab }, ref) => {
  const exportReport = () => {
    ref.current.exportCSV()
  }

  if (currentTab !== 1) {
    return (
      <ContentLayoutHeader
        title='Reporte individual'
        buttonProps={{
          label: 'Descargar',
          icon: 'pi pi-download',
          className: 'h-fit',
          onClick: () => {
            exportReport()
          }
        }}
      />
    )
  } else if (currentTab === 1) {
    return (
      <ContentLayoutHeader
        title='Reporte individual'
      />
    )
  }
})

export default IndividualReportHeader
