/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import FormSectionTitle from './FormSectionTitle'
import InputText from '../../../../components/atoms/InputText.atom'
import InputTextForm from '../../../../components/molecules/InputTextForm.molecule'
import Button from '../../../../components/atoms/Button.atom'
import GridContainer from '../../../../components/layouts/containers/GridContainer.container'
import GridItem from '../../../../components/layouts/containers/GridItem.container'
import { InputCheckbox as Checkbox } from '../../../../components/atoms/InputCheckbox.atom'
import { Panel } from '../../../../components/atoms/Panel.atom'
import Card from '../../../../components/atoms/Card.atom'
import ErrorMessageForm from '../../../../components/atoms/ErrorMessageForm.atom'
import { getClient } from '../../../../services/client.service'
import { isNotEmpty, isNotMaxLength } from '../../../../utils/validations.utils'

import { PickList } from 'primereact/picklist';

const ItemType = 'MODULE'

const BaseModuleInput = ({ className, module, onChange, actionElement, onKeyDown }) => {
  return (
    <span className={'p-inputgroup'}>
      <InputText
        label='Módulo'
        placeholder='Agregar módulo'
        value={module.name || ''}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      {actionElement}
    </span>
  )
}

const CreateModuleInput = ({ onSubmit, isFirst, modules }) => {
  const [moduleValue, setModuleValue] = React.useState('')
  const [suggestions, setSuggestions] = React.useState([])
  const [error, setError] = React.useState(null)
  const [showSuggestions, setShowSuggestions] = React.useState(false)

  const handleChange = (e) => {
    setError(null)
    const value = e.target.value
    setModuleValue(value)
    const filteredModules = modules.filter(mod => mod.name.toLowerCase().includes(value.toLowerCase()))
    setSuggestions(filteredModules)
    setShowSuggestions(true)
  }

  const handleSubmit = () => {
    if (!moduleValue) return
    if (modules.find(mod => mod.name === moduleValue)) {
      setError('Los nombres de los módulos no pueden repetirse')
      return
    }
    onSubmit({ name: moduleValue })
    setModuleValue('')
    setSuggestions([])
    setShowSuggestions(false)
  }

  return (
    <div className={`relative`}>
      <BaseModuleInput
        className={`${error ? 'border-danger' : ''}`}
        module={{ name: moduleValue }}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit()
          }
        }}
        actionElement={
          <Button
            icon='pi pi-plus'
            className='bg-success border-none'
            onClick={handleSubmit}
          />
        }
      />
      {(suggestions.length > 0 && showSuggestions) && (
        <Card className="autocomplete-suggestions">
          {suggestions.map(mod => (
            <div key={mod.id} className="suggestion-item" onClick={() => {
              setModuleValue(mod.name)
              setShowSuggestions(false)
            }}>{mod.name}</div>
          ))}
        </Card>
      )}
      {error ? <ErrorMessageForm errorMessage={error} /> : null}
    </div>
  )
}

const ButtonModuleInput = ({ module, onSubmit, onDelete, onChange }) => {
  return (
    <BaseModuleInput
      module={module}
      onChange={onChange}
      actionElement={
        <Button
          icon={module ? 'pi pi-trash' : 'pi pi-plus'}
          className={module ? 'bg-danger border-none' : 'bg-success border-none'}
          onClick={() => {
            if (module) {
              return onDelete()
            }
          }}
        />
      }
    />
  )
}

const CheckboxModuleInput = ({ modules, module, onToggleActive, onChange }) => {
  const [error, setError] = React.useState(null)
  const handleOnChange = (e) => {
    setError(null)
    if (!e.target.value.trim()) {
      setError('El nombre del módulo no puede estar vacío')
    }
    if (modules.find(mod => mod.name === e.target.value)) {
      setError('Los nombres de los módulos no pueden repetirse')
    }
    onChange(e)
  }
  return (
    <div className='relative w-full'>
      <BaseModuleInput
        module={module}
        onChange={(e) => {
          handleOnChange(e)
        }}
        actionElement={
          <div className='w-3rem flex align-items-center justify-content-center' style={{ background: 'var(--surface-shadow)', borderRadius: '0 6px 6px 0' }}>
            <Checkbox
              className='module-checkbox'
              checked={Boolean(module.active) || false}
              value={Boolean(module.active) || false}
              onChange={(e) => onToggleActive(e.checked)}
            />
          </div>
        }
      />
      {error ? <ErrorMessageForm errorMessage={error} /> : null}
    </div>
  )
}

const DraggableItem = ({ id, index, moveItem, children, className }) => {
  const ref = React.useRef(null)
  const dragHandleRef = React.useRef(null)

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item) {
      if (item.index !== index) {
        moveItem(item.id, index)
        item.index = index
      }
    }
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemType,
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  drag(dragHandleRef)
  drop(preview(ref))

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, display: 'flex', alignItems: 'center' }} className={className}>
      <div ref={dragHandleRef} style={{ cursor: 'grab', marginRight: '8px' }}>
        <i className="pi pi-bars"></i>
      </div>
      {children}
    </div>
  )
}

const ModuleSearchBar = ({
  searchValue,
  setSearchValue
}) => {
  return (
    <span className="p-input-icon-right">
      <i className="pi pi-search" />
      <InputTextForm
        name='module'
        placeholder='Buscar módulo'
        className='w-20'
        icon='pi pi-search'
        value={searchValue}
        onChange={(e) => {
          e.preventDefault()
          setSearchValue(e.target.value)
        }}
      />
    </span>
  )
}

const ModulesWrapper = () => {
  const [client, setClient] = useState({
    name: '',
    code: ''
  })
  const [projects, setProjects] = useState([])
  const [createdProjects, setCreatedProjects] = useState()
  const [error, setError] = useState()
  const index = 0
  useEffect(() => {
    gettingClient()
  }, [])

  useEffect(() => {
    setCreatedProjects(
      projects.map(project => ({
        ...project,
        formData: {
          name: project?.name || '',
          code: project?.code || '',
          active: undefined || project?.active
        },
        validations: {
          name: {
            errors: [],
            value: project?.name || '',
            typeValidations: [isNotEmpty]
          },
          code: {
            errors: [],
            value: project?.code || '',
            typeValidations: [isNotEmpty, isNotMaxLength],
            validationOptions: { isNotMaxLength: { max: 3 } }
          },
          active: {
            errors: [],
            value: project?.active || false,
            typeValidations: [isNotEmpty]
          }
        }
      })) || []
    )
  }, [projects])
  const gettingClient = async () => {
    const response = await getClient(106) // NEO
    if (response.success) {
      setClient(response.result.client)
      setProjects(response.result.projects)
    }
  }
  return (
    projects.length ?
    <FormClientModules
      modules={projects[0].modules}
      setModules={(modules) => {
        const project = createdProjects[index]
        project.modules = modules?.sort((a, b) => a.order - b.order)
        setCreatedProjects([
          ...createdProjects.slice(0, index),
          project,
          ...createdProjects.slice(index + 1)
        ])
      }}
      setError={setError}
    />
    : 'cargando'
  )
}
const FormClientModules = ({ client, project, modules, setModules, isCreate, setError }) => {
  modules = modules.sort((a, b) => a.order - b.order).sort((a, b) => b.active - a.active)
  const [searchValue, setSearchValue] = useState('')
  const [filteredModules, setFilteredModules] = useState([])

  useEffect(() => {
    if (!modules) {
      setFilteredModules([])
      return
    }
    setFilteredModules(modules.filter((module) => module.name?.toLowerCase().includes(searchValue.toLowerCase())))
  }, [modules, searchValue])

  const moveItem = (fromId, toIndex) => {
    const updatedModules = [...modules]
    const fromIndex = updatedModules.findIndex(module => module.id === fromId)
    if (fromIndex === -1) return
    const [movedModule] = updatedModules.splice(fromIndex, 1)
    updatedModules.splice(toIndex, 0, movedModule)
    setModules(updatedModules)
  }

  const handleModuleChange = (module, value) => {
    setError(false)
    if (!value.trim()) {
      setError(true)
    }
    const isDuplicate = modules.some((module) => module.name.toLowerCase() === value.toLowerCase())
    console.log(isDuplicate)
    if (isDuplicate) {
      setError(true)
    }
    setModules(
      modules.map((m) => m.id === module.id ? { ...m, name: value } : m)
    )
  }

  const renderIsCreate = () => {
    return (
      modules?.map((module, index) => (
        <GridItem key={module.id} md={6}>
          <DraggableItem
            id={module.id}
            index={index}
            moveItem={moveItem}
          >
            <ButtonModuleInput
              module={module}
              onSubmit={(val) => {
                setModules(
                  modules.map((m) => m.id === module.id ? val : m)
                )
              }}
              onDelete={() => {
                setModules(modules.filter((m) => m.id !== module.id))
              }}
            />
          </DraggableItem>
        </GridItem>
      ))
    )
  }

  const renderIsEdit = () => {
    let activeModules = filteredModules?.filter((module) => module.hasOwnProperty('active') && module.active)
    let newModules = filteredModules?.filter((module) => !module.hasOwnProperty('active'))
    let inactiveModules = filteredModules?.filter((module) => module.hasOwnProperty('active') && !module.active)

    const activeModulesLength = activeModules.length
    const newModulesLength = newModules.length

    activeModules = activeModules.map((module, index) => ({ ...module, order: index }))
    newModules = newModules.map((module, index) => ({ ...module, order: activeModulesLength + index }))
    inactiveModules = inactiveModules.map((module, index) => ({ ...module, order: activeModulesLength + newModulesLength + index }))

    return (
      <div className='flex flex-column mt-3 gap-3'>
        <h5 className='m-0'>Activos</h5>
        <GridContainer>
          <DndProvider backend={HTML5Backend}>
            {activeModules.map((module, index) => (
              <GridItem key={module.id + '-' + index} md={6} className='flex'>
                <p className='mt-auto mb-auto mr-2 block flex-grow-0' style={{ transform: 'translateY(-1px)', opacity: 0.8, width: '2ch' }}>
                  {index + 1}
                </p>
                <DraggableItem
                  id={module.id}
                  index={index}
                  moveItem={moveItem}
                  className='flex-1'
                >
                  {
                    module.isDeletable
                      ? (
                        <ButtonModuleInput
                          module={module}
                          onChange={(e) => handleModuleChange(module, e.target.value)}
                          onSubmit={(val) => {
                            setModules(
                              modules.map((m) => m.id === module.id ? val : m)
                            )
                          }}
                          onDelete={() => {
                            setModules(modules.filter((m) => m.id !== module.id))
                          }}
                        />
                      )
                      : (
                        <CheckboxModuleInput
                          module={module}
                          modules={modules}
                          onChange={(e) => handleModuleChange(module, e.target.value)}
                          onToggleActive={(active) => {
                            setModules(
                              modules.map((m) => m.id === module.id ? { ...m, active } : m)
                            )
                          }}
                        />
                      )
                  }
                </DraggableItem>
              </GridItem>
            ))}
            {newModules.map((module, index) => (
              <GridItem key={activeModulesLength + index} md={6}>
                <DraggableItem
                  id={module.id}
                  index={index + activeModulesLength}
                  moveItem={moveItem}
                >
                  <ButtonModuleInput
                    module={module}
                    onChange={(e) => handleModuleChange(module, e.target.value)}
                    onSubmit={(val) => {
                      setModules(
                        modules.map((m) => m.id === module.id ? val : m)
                      )
                    }}
                    onDelete={() => {
                      setModules(modules.filter((m) => m.name !== module.name))
                    }}
                  />
                </DraggableItem>
              </GridItem>
            ))}
          </DndProvider>
          <GridItem md={6}>
            <CreateModuleInput
              isFirst={!newModules.length && !activeModules.length}
              onSubmit={(val) => {
                setModules([...modules, val])
              }}
              modules={modules}
            />
          </GridItem>
        </GridContainer>
        <Panel headerTemplate={(options) => {
          const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
          const className = `flex gap-2 justify-content-start my-auto cursor-pointer`;
          const titleClassName = `${options.titleClassName} my-auto`;

          return (
            <div className={className} onClick={options.onTogglerClick}>
              <h5 className={titleClassName}>Inactivos</h5>
              <span
                className={`${toggleIcon} text-xs my-auto flex align-items-center h-full`}
                style={{
                  lineHeight: '150%',
                  color: 'var(--text-color)'
                }}
              ></span>
            </div>
          )
        }} className='module-panel flex flex-column gap-3' toggleable >
          <GridContainer>
            {inactiveModules.map((module, index) => (
              <GridItem key={module.id} md={6}>
                <CheckboxModuleInput
                  module={module}
                  onToggleActive={(active) => {
                    setModules(
                      modules.map((m) => m.id === module.id ? { ...m, active } : m)
                    )
                  }}
                />
              </GridItem>
            ))}
          </GridContainer>
        </Panel>
      </div>
    )
  }
  //   let activeModules = filteredModules?.filter((module) => module.hasOwnProperty('active') && module.active)
  //   let newModules = filteredModules?.filter((module) => !module.hasOwnProperty('active'))
  //   let inactiveModules = filteredModules?.filter((module) => module.hasOwnProperty('active') && !module.active)

  //   const activeModulesLength = activeModules.length
  //   const newModulesLength = newModules.length

  //   activeModules = activeModules.map((module, index) => ({ ...module, order: index }))
  //   newModules = newModules.map((module, index) => ({ ...module, order: activeModulesLength + index }))
  //   inactiveModules = inactiveModules.map((module, index) => ({ ...module, order: activeModulesLength + newModulesLength + index }))
  const itemTemplate = (item) => {
    const [value, setValue] = useState(item.name)
    const [isEdit, setIsEdit] = useState(false)
    const handleEdit = () => {
      const { id, active } = item
      const elements = active ? activeModules : inactiveModules
      const setter = active ? setActiveModules : inactiveModules
      
      setter([
        ...elements,
        {
          ...item,
          name: value
        }
      ])
      console.log([
        ...elements,
        {
          ...item,
          name: value
        }
      ])
      setIsEdit(false)
    }
    const handleDelete = () => {
      const { id, active } = item
      const elements = active ? activeModules : inactiveModules
      const elementsWithoutDeleted = elements.filter((e) => e.id !== id)
      const setter = active ? setActiveModules : inactiveModules
      setter(elementsWithoutDeleted)
    }
    return (
      <div className="flex flex-wrap p-2 align-items-center justify-content-between">
        
        {
          isEdit ?
            <InputText
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleEdit()
                }
              }}
            />
            : <span className="font-bold text-900">{item.name}</span>
        }
        <div className='flex gap-2'>
          {
            isEdit ? 
            <i className="pi pi-check" style={{color:'green'}} onClick={handleEdit}></i>
            : <i className="pi pi-pencil" style={{color:'red'}} onClick={() => setIsEdit(true)}></i>
          }
          <i className="pi pi-trash" style={{color:'red'}} onClick={handleDelete}></i>
        </div>
      </div>
    );
  };

  const [activeModules, setActiveModules] = useState()
  const [inactiveModules, setInactiveModules] = useState()
  const [newModules, setNewModules] = useState()
  useEffect

  useEffect(() => {
    let activeTemp = filteredModules?.filter((module) => module.hasOwnProperty('active') && module.active)
    let newTemp = filteredModules?.filter((module) => !module.hasOwnProperty('active'))
    let inactiveTemp = filteredModules?.filter((module) => module.hasOwnProperty('active') && !module.active)
    const activeModulesLength = activeTemp.length
    const newModulesLength = newTemp.length
    setActiveModules(activeTemp.map((module, index) => ({ ...module, order: index })))
    setNewModules(newTemp.map((module, index) => ({ ...module, order: activeModulesLength + index })))
    setInactiveModules(inactiveTemp.map((module, index) => ({ ...module, order: activeModulesLength + newModulesLength + index })))
  }, [filteredModules])

  const onChange = (event) => {
    console.log(event)
    setActiveModules(event.source);
    setInactiveModules(event.target);
  };
  return (
    <div className='flex flex-column gap-2 mt-3'>
      <div className='flex w-full justify-content-between'>
        <FormSectionTitle title='Módulos' />
        <ModuleSearchBar
          key={1}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </div>
      {/* {isCreate ? renderIsCreate() : renderIsEdit()} */}
      <PickList
        dataKey="id"
        source={activeModules}
        target={inactiveModules}
        onChange={onChange}
        itemTemplate={itemTemplate}
        breakpoint="1280px"
        sourceHeader="Activos"
        targetHeader="Inactivos"
        dragdrop
      // ourceStyle={{ height: '24rem' }}
      // targetStyle={{ height: '24rem' }}
      />
    </div>
  )
}

export default ModulesWrapper
