/* eslint-disable */
import { completeAfterTaskCreationTutorial, completeBasicTutorial } from '../services/teamMember.service'

const newLine = "<div style='height: 5px; padding: 0px; margin: 0px; display: block;'></div>"

const waitForElement = (selector, callback, interval = 100, timeout = 100000) => {
  const startTime = new Date().getTime()
  const intervalId = setInterval(() => {
    const element = document.querySelector(selector)
    const currentTime = new Date().getTime()
    if (element) {
      clearInterval(intervalId)
      callback()
    } else if (currentTime - startTime >= timeout) { // Proteccion en dev
      clearInterval(intervalId)
      console.warn(`Elemento ${selector} no encontrado en el tiempo esperado`)
    }
  }, interval)
}

function blockEvents(event) {
  event.stopPropagation();
}

/**
 * Devuelve el tour de inicio para crear una nueva tarea en Aika
 * @param {*} tourDriver
 * @returns
 */
export const getAfterTaskCreationTour = (tourDriver) => {
  return [
    {
      element: '.pi-ellipsis-v',
      popover: {
        title: 'Conoce tus opciones',
        description: `Todavía puedes editar, duplicar, eliminar o seleccionar tus tareas registradas.${newLine}Haz clic para ver tus opciones.`,
        prevBtnText: 'Omitir',
        onPrevClick: async () => {
          await completeAfterTaskCreationTutorial()
          tourDriver.destroy()
        },
        onPopoverRender: () => {
          waitForElement('.p-contextmenu', () => {
            tourDriver.moveNext()
          })
        },
        disableButtons: 'next'
      }
    },
    {
      element: '.p-contextmenu li:nth-child(2) a',
      popover: {
        title: '¿Necesitas editar tu tarea?',
        description: `Aquí puedes modificar los detalles de tu tarea antes de finalizar el día.${newLine}Toma en cuenta que si lo haces después, el administrador será notificado de estos cambios.`,
        disableButtons: 'previous',
        onPopoverRender() {
          const editListEl = document.querySelector('.p-menuitem-link.driver-active-element')
          editListEl.addEventListener('click', blockEvents, true)
          document.querySelector('body').addEventListener('click', blockEvents)
        },
        onNextClick: () => {
          const editListEl = document.querySelector('.p-menuitem-link.driver-active-element')
          editListEl.removeEventListener('click', blockEvents, true)

          document.querySelector(".desktop-header-container").click()
          document.querySelector('body').removeEventListener('click', blockEvents)
          document.querySelector('body').click()
          tourDriver.moveNext()

        }
      }
    },
    {
      element: '.tab-menu-content-container:last-child',
      popover: {
        title: '¿Quieres ver cuánto tiempo has registrado?',
        description: 'En esta sección podrás consultar el tiempo registrado durante la semana y el día seleccionado, según la duración de tus tareas.'
      }
    },
    {
      element: '.tab-menu-content-container:last-child .p-button',
      popover: {
        title: 'No te vayas sin finalizar tu día',
        /* eslint-disable-next-line */
        description: `Cuando termines de registrar tus tareas, haz clic en “Finalizar día” para guardar correctamente tus registros.${newLine}Toma en cuenta que esta acción será necesaria para continuar el registro del día siguiente.`,
        onNextClick: async () => {
          await completeAfterTaskCreationTutorial()
          tourDriver.moveNext()
        }
      }
    }
  ]
}
/**
 * Devuelve el tour de inicio para crear una nueva tarea en Aika
 * @param {*} tourDriver
 * @returns
 */
export const getAutomaticStartupTour = (tourDriver, user) => {
  function nextStep() {
    tourDriver.moveNext()
    const cancelBtn = document.querySelector('button[aria-label="Cancelar"]')
    const acceptBtn = document.querySelector('button[aria-label="Aceptar"]')

    cancelBtn.removeEventListener('click', nextStep)
    acceptBtn.removeEventListener('click', nextStep)
  }

  function next() {
    tourDriver.moveNext()
  }

  /**
   * @type {import("driver.js").DriveStep[]} steps
   */
  const steps = [
    {
      element: 'button.p-button.p-component.border-round-3xl.bg-primary-highlight.h-fit',
      popover: {
        title: '¡Hola!',
        description: 'Antes de empezar, te recomendamos seleccionar los proyectos en los que participas, esto te servirá para hacer tu registro más rápido.',
        prevBtnText: 'Omitir',
        onPrevClick: async () => {
          // console.log(tourDriver)
          await completeBasicTutorial()
          tourDriver.destroy()
        },
        disableButtons: [],
        popoverClass: 'first-step'
      }
    },
    {
      element: '#time-register-section .empty-tasks-wrapper .p-button',
      popover: {
        title: 'Inicia tu registro de tareas',
        description: 'Para comenzar puedes hacer clic en "Añadir una tarea"',
        onPopoverRender() {
          const button = document.querySelector('#time-register-section .empty-tasks-wrapper .p-button')
          button.addEventListener('click', next)
        },
        onNextClick: () => {
          const button = document.querySelector('#time-register-section .empty-tasks-wrapper .p-button')
          button.removeEventListener('click', next)
          tourDriver.moveNext()
        }
      }
    },
    {
      element: '.task-creation-name .p-inplace',
      popover: {
        title: 'Inicia tu registro de tareas',
        description: 'O escribe el nombre de tu tarea en este campo.'
      }
    },
    {
      element: '.comment-button',
      popover: {
        title: '¿Necesitas agregar información?',
        description: `Puedes agregar un comentario a tu tarea.${newLine}Haz clic para probarlo.`,
        disableButtons: 'next', // Directamente quitar botones? Ya que hacen ruido deshabilitados
        onPopoverRender: () => {
          waitForElement('.p-overlaypanel', () => { // Esperar a que de click
            tourDriver.moveNext()
          })
        }
      }
    },
    {
      element: '.p-overlaypanel-content',
      popover: {
        title: '¿Necesitas agregar información?',
        description: 'En este campo podrás escribir un comentario para describir mejor tu tarea; es opcional y recuerda que el administrador puede leerlo.',
        onNextClick: () => {
          const element = document.querySelector('.comment-button')
          if (element) element.click() // Cerrar el panel para continuar el flujo
          tourDriver.moveNext()
          const cancelBtn = document.querySelector('button[aria-label="Cancelar"]')
          const acceptBtn = document.querySelector('button[aria-label="Aceptar"]')

          document.querySelector('body').removeEventListener('click', blockEvents)
          document.querySelector('body').click()

          cancelBtn.removeEventListener('click', nextStep)
          acceptBtn.removeEventListener('click', nextStep)
        },
        onPopoverRender() {
          document.querySelector('body').addEventListener('click', blockEvents)
          const cancelBtn = document.querySelector('button[aria-label="Cancelar"]')
          const acceptBtn = document.querySelector('button[aria-label="Aceptar"]')

          acceptBtn.addEventListener('click', nextStep)
          cancelBtn.addEventListener('click', nextStep)
        }
      }
    },
    {
      element: '.new-task-form > :nth-child(2)',
      popover: {
        title: 'Elige un proyecto',
        description: `Todas tus tareas deben registrarse con el proyecto al que pertenecen.${newLine}Haz clic para ver tus opciones.`,
        disableButtons: 'next', // Directamente quitar botones? Ya que hacen ruido deshabilitados
        onPopoverRender: () => {
          waitForElement('.p-dropdown-panel', () => { // Esperar a que de click
            tourDriver.moveNext()
          })
        }
      }
    },
    {
      element: '.p-dropdown-panel',
      popover: {
        title: 'Elige un proyecto',
        description: 'Recuerda, solo los proyectos seleccionados en "Mis proyectos" estarán disponibles aquí.',
        onNextClick() {
          document.querySelector(".new-task-form > :nth-child(2).field .p-dropdown")?.click()
          tourDriver.moveNext()
          document.querySelector('body').removeEventListener('click', blockEvents)
          document.querySelector('body').click()
          document.querySelector('.p-dropdown-header .p-inputtext').classList.remove("pointer-events-none")
        },
        onPopoverRender() {
          document.querySelector('.p-dropdown-header .p-inputtext').classList.add("pointer-events-none")
          const dropdownItems = document.querySelectorAll('.p-dropdown-item')
          dropdownItems.forEach(item => {
            item.addEventListener('click', next)
          })
          document.querySelector('body').addEventListener('click', blockEvents)
        }
      }
    },
    {
      element: '.new-task-form > :nth-child(3)',
      popover: {
        title: 'Elige una categoría',
        description: `Es muy importante para nosotros que todas tus tareas tengan una categoría.${newLine}Haz clic para conocerlas.`,
        onPopoverRender: () => {
          waitForElement('.p-dropdown-panel', () => { // Esperar a que de click
            tourDriver.moveNext()
          })
        },
        disableButtons: 'next',
      }
    },
    {
      element: '.p-dropdown-panel',
      popover: {
        title: 'Elige una categoría',
        description: 'Cada opción tiene una pequeña descripción como guía. Si aún tienes dudas sobre cuál elegir, tu líder de equipo o un administrador estarán disponibles para ayudarte.',
        onNextClick() {
          document.querySelector('.p-dropdown-header .p-inputtext').classList.remove("pointer-events-none")
          document.querySelector(".new-task-form > :nth-child(3).field .p-dropdown")?.click()
          tourDriver.moveNext()
        },
        onPopoverRender() {
          document.querySelector('.p-dropdown-header .p-inputtext').classList.add("pointer-events-none")
          const dropdownItems = document.querySelectorAll('.p-dropdown-item')
          dropdownItems.forEach(item => {
            item.addEventListener('click', next)
          })
        }
      }
    },
    {
      element: '.p-chip:has(.pi-stopwatch)',
      popover: {
        title: '¿Cuánto tiempo trabajaste en esta tarea?',
        description: 'Ingresa al menos 15 minutos por tarea. Si lo necesitas, ajusta las horas cliente, pero recuerda que esta información es clave para los reportes de los clientes.',
        onPopoverRender() {
          const dropdownItems = document.querySelectorAll('.p-dropdown-item')
          dropdownItems.forEach(item => {
            item.removeEventListener('click', next)
          })
          document.querySelector('.p-chip:has(.pi-stopwatch)').addEventListener('click', blockEvents)
        },
        onNextClick() {
          document.querySelector('.p-chip:has(.pi-stopwatch)').removeEventListener('click', blockEvents)
          tourDriver.moveNext()
        }
      }
    },
    {
      element: '.p-button-success',
      popover: {
        title: 'Finaliza el registro de tu tarea',
        description: 'Al seleccionar este botón, la tarea se agrega al registro del día.',
        onNextClick: async () => {
          await completeBasicTutorial()
          tourDriver.moveNext()
          // window.location.reload()
          if (!user?.hasCompletedTaskTutorial) {
            window.location.reload()
          }
        }
      }
    }
  ]

  return steps
}
