import { useRef } from 'react'
import { severities } from '../consts/common.const'

export function useMessage () {
  /** @type {React.MutableRefObject<import("primereact/messages").Messages | null>} */
  const ref = useRef(null)

  function setMessage (severity, summary = '', detail = '', life = 5000, sticky = false) {
    if (ref.current === null) throw new Error('ref.current is null. please pass ref to the Message component')
    ref.current.show([
      { severity, summary, detail, life, sticky }
    ])
  }

  function setErrorMessage ({ summary = 'Error', detail = '', life = 5000, sticky = false }) {
    setMessage(severities.error, summary, detail, life, sticky)
  }

  function setSuccessMessage ({ summary = 'Éxito', detail = '', life = 5000, sticky = false }) {
    setMessage(severities.success, summary, detail, life, sticky)
  }

  function setInfoMessage ({ summary = 'Información', detail = '', life = 5000, sticky = false }) {
    setMessage(severities.info, summary, detail, life, sticky)
  }

  function setWarnMessage ({ summary = 'Advertencia', detail = '', life = 5000, sticky = false }) {
    setMessage(severities.warnig, summary, detail, life, sticky)
  }

  return { ref, setErrorMessage, setSuccessMessage, setInfoMessage, setWarnMessage }
}
