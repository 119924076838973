import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function GoBackIcon () {
  const navigate = useNavigate()
  return (
    <>
      <i className="pi pi-arrow-left mr-3 text-md text-white bg-primary border-circle"
        style={{
          cursor: 'pointer',
          padding: '8.5px'
        }}
        onClick={() => navigate(-1)}>
      </i>
    </>
  )
}
