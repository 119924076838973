import React from 'react'
import AppHeader from '../../routers/controllers/AppHeader'

export default function MainContentLayout ({ children }) {
  return (
    <div className="layout-main-content p-5">
      <AppHeader/>
      {children}
    </div>
  )
}
