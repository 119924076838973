import React, { useEffect } from 'react'
import { DataTable as DataTablePrime } from 'primereact/datatable'
import PropTypes from 'prop-types'
import { ScreenMessage } from '../../compounds/customMessages/ScreenMessage.compound'
import { SkeletonDataTable } from '../../compounds/misc/SkeletonDataTable.compound'

const DataTable = React.forwardRef(
  /**
   * @param {import("primereact/datatable").DataTableProps} props
   */
  (props, ref) => {
    const {
      noFixedColumns,
      noScrollHeight,
      withOutHeader,
      emptyCustomMessage,
      noSameWidthColumns,
      hasScroll,
      defaultVirtualScroller,
      hasVirtualScroll,
      emptyMessageTitle,
      emptyMessageContent,
      loading,
      loadingConfig,
      ...rest
    } = props

    const fixedColumnClass = noFixedColumns ? '' : 'table-fixed-columns'
    const withOutHeaderClass = withOutHeader ? 'remove-table-header' : ''
    const sameWidthColumnsClass = noSameWidthColumns ? '' : 'same-width-columns'
    const tableScrollClass = hasScroll ? 'p-datatable-scrollable-custom' : ''

    const relocateFooter = (elementToTakeCurrentHeight, footerElement) => {
      const footerHeight = elementToTakeCurrentHeight?.offsetHeight - footerElement?.offsetHeight || 0
      footerElement.style.top = `${footerHeight}px`
    }

    const relocateDataTable = (element) => {
      const items = ref.current.props?.value?.length
      const itemSize = element.getElementsByTagName('tbody')[0]?.getElementsByTagName('tr')[0]?.offsetHeight || ref.current.props.virtualScrollerOptions?.itemSize || 47
      const alturaHeader = element.getElementsByTagName('thead')[0]?.offsetHeight || 0
      const alturaFooter = element.getElementsByTagName('tfoot')[0]?.offsetHeight || 0
      const dataTableHeight = alturaFooter + (items * itemSize) + alturaHeader
      element.getElementsByTagName('table')[0].style.height = `${dataTableHeight}px`
      element.getElementsByTagName('table')[0].getElementsByTagName('tbody')[0].style.top = `${alturaHeader}px`
    }

    useEffect(() => {
      const element = ref?.current?.getElement()
      const virtualScroller = ref?.current?.getVirtualScroller()
      if (!hasVirtualScroll || defaultVirtualScroller) return

      const footerElement = element.getElementsByTagName('tfoot')[0]
      relocateDataTable(element)

      const observerVirtualScroller = new ResizeObserver(() => {
        relocateFooter(virtualScroller.getElementRef().current, footerElement)
      })

      const observerTable = new ResizeObserver(() => {
        relocateFooter(element, footerElement)
      })

      const observerTableBody = new ResizeObserver(() => {
        relocateDataTable(element)
      })

      observerVirtualScroller.observe(virtualScroller?.getElementRef()?.current)
      observerTable.observe(element)
      observerTableBody.observe(element.getElementsByTagName('table')[0])

      return () => {
        observerVirtualScroller.disconnect()
        observerTable.disconnect()
      }
    }, [ref?.current?.props?.value?.length])

    const emptyMessage = (
      <div className='empty-message-center mx-auto'>
        <ScreenMessage
          className="mx-auto"
          title={emptyMessageTitle || 'No se encontró información'}
          message={emptyMessageContent || 'Intenta aplicar otros filtros'}
        />
      </div>
    )

    if (loading) {
      return <SkeletonDataTable
        {...rest}
        ref={ref}
        responsiveLayout={'stack'}
        breakpoint={'960px'}
        loadingConfig={loadingConfig}
        noSameWidthColumns={noSameWidthColumns}
        noFixedColumns={noFixedColumns}
        hasScroll={hasScroll}
      >
        {props.children}
      </SkeletonDataTable>
    }

    return (
      <DataTablePrime
        breakpoint={'960px'}
        responsiveLayout={'stack'}
        className={`${tableScrollClass} ${fixedColumnClass} ${withOutHeaderClass} ${sameWidthColumnsClass} ${props.className}`}
        ref={ref}
        emptyMessage={emptyCustomMessage || emptyMessage}
        {...rest}
      >
        {props.children}
      </DataTablePrime>
    )
  }
)

DataTable.propTypes = {
  noFixedColumns: PropTypes.bool,
  scrollHeight: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  withOutHeader: PropTypes.bool
}

DataTable.defaultProps = {
  defaultVirtualScroller: false,
  hasVirtualScroll: false
}

export default DataTable
