import React from 'react'
import PropTypes from 'prop-types'
import { getMonthYearFromISODate, getNombreSemana } from '../../../utils/date.utils'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import Tag from '../../atoms/Tag.atom'
import IconMaterial from '../../atoms/IconMaterial.atom'
import DateRangeIcon from '@mui/icons-material/DateRange'
import LoopIcon from '@mui/icons-material/Loop'
import { DateTime } from 'luxon'
import InvernaderoSubtitleWithBadge from './InvernaderoSubtitleWithBadge.compounds'
import SimpleHeaderTitle from './SimpleHeaderTitle'
import ProductSubtitleWithBadge from './ProductSubtitleWithBadge.compunds'
import ButtonRowContainer from '../../layouts/containers/ButtonRowContainer.container'
import MultiplesInvernaderosSubtitleWithBadge from './MultiplesInvernaderosSubtitleWithBadge.compounds'
import ClientSubtitleWithBadge from './ClientSubtitleWithBadge.compounds'

const SimpleIconDataContainer = ({ icon, data }) => {
  return (
    <div className='flex align-items-center gap-2'>
      <Tag className='subtitle-tag'>
        <IconMaterial icon={icon}/>
      </Tag>
      <span className='text-2xl'>{data}</span>
    </div>
  )
}

const YearWeek = ({ week }) => {
  const isoWeek = DateTime.fromJSDate(week).set({ weekday: 1, hour: 0, minute: 0, second: 0 }).toISODate()
  const nombreSemana = getNombreSemana(isoWeek, false)
  return <SimpleIconDataContainer icon={DateRangeIcon} data={nombreSemana}/>
}

const MonthYear = ({ month }) => {
  const isoDay = month.toISOString()
  const nombreMes = getMonthYearFromISODate(isoDay)
  return <SimpleIconDataContainer icon={CalendarMonthIcon} data={nombreMes}/>
}

const GlobalTitle = (props) => {
  return (
    <div className='flex align-items-center justify-content-between flex-wrap mb-4'>
      <div className='flex flex-column mb-2'>
        <div>
          <SimpleHeaderTitle title={props.title} goBack={props.goBack}/>
        </div>
        <div className='flex gap-4 flex-wrap align-items-center subtitle-container'>
          {props.withYearWeek && props.semana && <YearWeek week={props.semana}/>}
          {props.withMonthYear && props.mes && <MonthYear month={props.mes}/>}
          {props.withCycle && props.ciclo && <SimpleIconDataContainer icon={LoopIcon} data={props.ciclo}/>}
          {props.invernadero && <InvernaderoSubtitleWithBadge invernadero={props.invernadero}/>}
          {props.invernaderos && <MultiplesInvernaderosSubtitleWithBadge invernaderos={props.invernaderos}/>}
          {((props.withProduct && props.invernadero) || props.productoBaseConfig) &&
          <ProductSubtitleWithBadge invernadero={props.invernadero} productoBaseConfig={props.productoBaseConfig} withTooltip={props.productWithTooltip}/>}
          {props.cliente && <ClientSubtitleWithBadge cliente={props.cliente} withTooltip={props.clientWithTooltip}/>}
          {props.aditionalData && <span className='text-2xl'>{props.aditionalData}</span>}
        </div>
      </div>
      {
        props.withButtons &&
        <ButtonRowContainer>
          {props.buttons}
        </ButtonRowContainer>
      }
    </div>
  )
}

GlobalTitle.propTypes = {
  title: PropTypes.string,
  withYearWeek: PropTypes.bool,
  withMonthYear: PropTypes.bool,
  withCycle: PropTypes.bool,
  withProduct: PropTypes.bool,
  withButtons: PropTypes.bool,
  buttons: PropTypes.element,
  productWithTooltip: PropTypes.bool,
  clientWithTooltip: PropTypes.bool,
  semana: PropTypes.instanceOf(Date),
  mes: PropTypes.instanceOf(Date),
  ciclo: PropTypes.any,
  invernadero: PropTypes.shape({
    colorValor: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    cicloNombre: PropTypes.string,
    // Puede ser nombre o invernaderoVirtualnombre
    nombre: PropTypes.string,
    invernaderoVirtualNombre: PropTypes.string
  }),
  invernaderos: PropTypes.arrayOf(PropTypes.shape({
    // Puede ser colorValor o color
    colorValor: PropTypes.string,
    color: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    cicloNombre: PropTypes.string,
    nombre: PropTypes.string,
    tooltipValue: PropTypes.string,
    tooltipPosition: PropTypes.string
  })),
  aditionalData: PropTypes.string,
  productoBaseConfig: PropTypes.shape({
    variedad: PropTypes.string,
    nombre: PropTypes.string,
    withTooltip: PropTypes.bool
  }),
  cliente: PropTypes.shape({
    nombre: PropTypes.string,
    imagen: PropTypes.string
  })

}

export default GlobalTitle
