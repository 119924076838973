/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomTreeTable as TreeTable } from '../../../components/atoms/table/CustomTreeTable.atom'
import Column from '../../../components/atoms/table/Column.atom'
import { getClients } from '../../../services/client.service'
import ClientChip from '../../../components/molecules/ClientChip.molecule'
import Dropdown from '../../../components/atoms/Dropdown.atom'
import ClientsTableContextMenu from './components/ClientsTableContextMenu'
import ColumnGroup from '../../../components/atoms/table/ColumnGroup.atom'
import Row from '../../../components/atoms/table/RowColumnGroup.atom'
import ActionModal from './ActionModal.controller'
import useClientColorsContext from '../../../hooks/contextConsumers/useClientColorsContext.hook'
import Card from '../../../components/atoms/Card.atom'
import ProgressSpinner from '../../../components/atoms/misc/ProgressSpinner.atom'

const ClientsTable = () => {
  const [loading, setLoading] = useState(false)
  const [prefilteredClients, setPrefilteredClients] = useState([])
  const [filter, setFilter] = useState(1)
  const [datatableFilter, setDatatableFilter] = useState({
    'data.active': {
      value: 1,
      matchMode: 'equals'
    }
  })
  const [clients, setClients] = useState([])
  const [expandedKeys, setExpandedKeys] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [modalAction, setModalAction] = useState(null)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const filterApplyCallbackRef = useRef()
  const cmRef = useRef()
  const { setRefresh } = useClientColorsContext()
  const navigate = useNavigate()
  useEffect(() => {
    gettingClients()
    setRefresh(prev => !prev)
  }, [])

  useEffect(() => {
    if (isFirstRender && clients.length > 0 && filterApplyCallbackRef.current) {
      filterApplyCallbackRef.current(1)
      setIsFirstRender(false)
      setPrefilteredClients(clients.filter(client => client.data.active === 1))
    }
  }, [clients])

  useEffect(() => {
    if (modalAction && modalAction === 'reactivate') {
      gettingClients()
    }
  }, [modalAction])

  const gettingClients = async () => {
    setLoading(true)
    const response = await getClients()
    if (response.success) {
      setClients(response.result)
    }
    setLoading(false)
  }

  const statusFilterElement = (options) => {
    if (isFirstRender) {
      filterApplyCallbackRef.current = options.filterApplyCallback
    }
    return (
      <Dropdown
        value={options.value}
        options={[
          { label: 'Activo', value: 1 },
          { label: 'Inactivo', value: 0 }
        ]}
        onChange={(e) => {
          options.filterCallback(e.value)
          setFilter(e.value)
        }}
        optionLabel="label"
        optionValue="value"
        placeholder="Filtrar estatus"
      />
    )
  }

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Clientes / Proyectos" colSpan={2} sortable sortField='data.code' />
        <Column header="ID" sortable sortField='data.clientId' />
        <Column header="Código" sortable sortField='data.code' />
        <Column
          header="Estatus"
          filter
          filterField='data.active'
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterElement={statusFilterElement}
          filterMatchMode="equals"
          onFilterClear={() => setFilter(null)}
          onFilterApplyClick={() => {
            setDatatableFilter({
              'data.active': {
                value: filter,
                matchMode: 'equals'
              }
            })
          }}
        />
        <Column />
      </Row>
    </ColumnGroup>
  )

  return (
    <Card id='clients-table' className='p-1 shadow-none'>
      <TreeTable
        value={isFirstRender ? prefilteredClients : clients}
        filters={datatableFilter}
        expandedKeys={expandedKeys}
        onToggle={(e) => setExpandedKeys(e.value)}
        removableSort
        headerColumnGroup={headerGroup}
        expanderConditions={(node) => {
          if (filter === null) {
            return node?.children?.length > 1
          } else if (filter === 0) {
            return (node?.children?.length > 1 && node?.children?.some(project => project.data.active === 0))
          } else {
            return (node?.children?.length > 1 && node?.children?.some(project => project.data.active === 1))
          }
        }}
        onContextMenu={({ originalEvent, data: node }) => {
          cmRef?.current.show(originalEvent)
          setSelectedRow({
            ...node,
            id: node?.data?.clientId || node?.data?.projectId,
            type: node?.data?.projectId ? 'project' : 'client'
          })
        }}
      >
        <Column
          field="data.code"
          body={(node) => {
            if (node?.children?.length) {
              return (
                <div className='flex gap-2 align-items-center'>
                  <ClientChip className='w-fit' code={node?.data?.code} />
                  {<span>{node?.data?.name}</span>}
                </div>
              )
            } else {
              return <span>{node?.data?.name}</span>
            }
          }}
          expander
          sortable
          style={{ width: '45%' }}
        />
        <Column
          // field="data.clientId"
          colSpan={1}
          style={{ width: '18%' }}
          body={(rowData) => {
            return rowData?.data?.projectId || rowData?.data?.clientId
          }}
        />
        <Column
          field="data.code"
          sortable
          colSpan={1}
          style={{ width: '18%' }}
        />
        <Column
          field="data.active"
          body={(node) => node?.data?.active ? 'Activo' : 'Inactivo'}
          filterMatchMode="equals"
          colSpan={1}
          style={{ width: '18%' }}
        />
        <Column
          body={(node) => {
            return (
              <i
                className='pi pi-ellipsis-v cursor-pointer px-3'
                onClick={(event) => {
                  cmRef?.current?.show(event)
                  setSelectedRow({
                    ...node,
                    id: node?.data?.clientId || node?.data?.projectId,
                    type: node?.data?.projectId ? 'project' : 'client',
                    // eslint-disable-next-line
                    isClientActive: node?.data?.projectId ? clients.find(client => client.data.clientId == node?.parentKey)?.data?.active : node?.data?.active
                  })
                }}
              />
            )
          }}
        />
      </TreeTable>
      <ClientsTableContextMenu
        selectedRow={selectedRow}
        ref={cmRef}
        setOpenModal={setOpenModal}
        setModalAction={setModalAction}
        onEdit={() => navigate(`/administracion/clientes/editar/${selectedRow?.data?.clientId}`)}
      />
      <ActionModal
        row={selectedRow}
        visible={openModal}
        setVisible={setOpenModal}
        modalAction={modalAction}
        onFinishedSubmit={gettingClients}
      />
    </Card>
  )
}

export default ClientsTable
