import React, { useState } from 'react'
import FormClientDetails from './formSections/FormClientDetails.controller'
import FormClientProjects from './formSections/FormClientProjects.controller'
import FormClientFooter from './formSections/FormClientFooter.controller'
import TabMenuContentContainer from '../../../../components/layouts/containers/TabMenuContentContainer.container'
import Divider from '../../../../components/atoms/Divider.atom'
import Card from '../../../../components/atoms/Card.atom'

const FormCreateClient = () => {
  const [client, setClient] = useState({
    name: '',
    code: ''
  })
  const [projects, setProjects] = useState([])
  const [error, setError] = useState(null)

  return (
    <div id='client-form'>
      <Card className='p-0 overflow-hidden flex flex-column gap-3 p-1 shadow-none'>
        <TabMenuContentContainer>
          <FormClientDetails
            client={client}
            setClient={setClient}
            isCreate={true}
            projects={projects}
            setProjects={setProjects}
          />
          <Divider className='my-3' stroke='sm'/>
          <FormClientProjects
            client={client}
            projects={projects}
            setProjects={setProjects}
            setError={setError}
          />
        </TabMenuContentContainer>
        <FormClientFooter
          client={client}
          projects={projects}
          error={error}
        />
      </Card>
    </div>
  )
}

export default FormCreateClient
