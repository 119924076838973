/* eslint-disable */
import React, { useState, useEffect } from 'react'
import ClientChip from '../../../../../components/molecules/ClientChip.molecule'
import IconWithTooltip from '../../../../../components/molecules/IconWithTooltip.molecule'
import { ErrorBadge, WarningBadge } from '../../../../../components/molecules/CellReportBadges.molecule'
import { TimeDifferenceBadge } from '../../components/CellReportBadges'

/**
 * @returns {import("primereact/column").ColumnProps[]}
 */
const ClientColumnContent = (
  clientFilterTemplate,
  teamMemberFilterTempalte,
  moduleFilterTemplate,
  categoryFilterTemplate,
  cm,
  setSelectedTask,
  isHoldingCtrlDown,
  onLeftClickTeamMemberName,
  onLeftClickModuleName,
  onLeftClickCategoryName,
  onLeftClickProjectName,
  onLeftClickClientCode,
  updatingTask,
  hasSpecialCategories
) => {
  const [isHoldingCtrl, setIsHoldingCtrl] = useState(false);

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === 'Control') {
        setIsHoldingCtrl(true);
      }
    };

    const onKeyUp = (e) => {
      if (e.key === 'Control') {
        setIsHoldingCtrl(false);
      }
    };

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  return [
    {
      body: (rowData) => {
        return (
          <div className='flex gap-2 align-items-center'>
            <ClientChip onClick={
              () => {
                if (isHoldingCtrlDown) { onLeftClickClientCode(rowData.clientCode) }
              }
            }
              className={`${isHoldingCtrlDown ? 'ctrl-selectable-badge' : ''}`}
              code={rowData.clientCode}
            />
            <span className={`${isHoldingCtrlDown ? 'ctrl-selectable' : ''}`} onClick={() => {
              if (isHoldingCtrlDown) { onLeftClickProjectName(rowData.projectName) }
            }}>{rowData.projectCode}</span>
          </div>
        )
      },
      field: 'projectCode',
      header: 'Proyecto',
      colSpan: 1,
      style: { width: '100px', minWidth: '100px', padding: '0.5rem' },
      filter: true,
      filterElement: clientFilterTemplate,
      showFilterMenuOptions: false,
      showFilterMatchModes: false,
      filterMatchMode: 'in',
      filterField: 'projectName'
    },
    {
      field: 'teamMemberNameTag',
      header: 'Integrante',
      colSpan: 1,
      hidden: true,
      filter: true,
      filterMatchMode: 'in',
      filterElement: teamMemberFilterTempalte,
      showFilterMenuOptions: false,
      showFilterMatchModes: false,
      filterField: 'teamMemberNameTag',
      body: (rowData) => (
        <div
          className={`overflow-hidden white-space-nowrap text-overflow-ellipsis ${isHoldingCtrlDown ? 'ctrl-selectable' : ''}`}
          onClick={() => {
            if (isHoldingCtrlDown) { onLeftClickTeamMemberName(rowData.teamMemberNameTag) }
          }
          }
        >
          {rowData?.teamMemberNameTag}
        </div>
      )
    },
    {
      field: 'module',
      header: 'Módulo',
      colSpan: 1,
      style: { width: '122px', minWidth: '122px', padding: '0.5rem' },
      filter: true,
      filterMatchMode: 'in',
      filterElement: moduleFilterTemplate,
      showFilterMenuOptions: false,
      showFilterMatchModes: false,
      filterField: 'moduleId',
      body: (rowData) => (
        <div
          className={`overflow-hidden white-space-nowrap text-overflow-ellipsis ${isHoldingCtrlDown ? 'ctrl-selectable' : ''}`}
          onClick={() => {
            if (isHoldingCtrlDown) { onLeftClickModuleName(rowData.moduleId) }
          }
          }
        >
          {rowData?.module}
        </div>
      )
    },
    {
      field: 'categoryTitle',
      header: 'Categoría',
      colSpan: 1,
      className: 'white-space-nowrap overflow-hidden text-overflow-ellipsis',
      style: { width: '122px', minWidth: '122px', padding: '0.5rem' },
      filter: true,
      filterMatchMode: 'in',
      filterField:  hasSpecialCategories ? 'specialCategoryId' : 'categoryId' ,
      filterElement: categoryFilterTemplate,
      showFilterMenuOptions: false,
      showFilterMatchModes: false,
      body: (rowData) => (
        <div className={`overflow-hidden white-space-nowrap text-overflow-ellipsis ${isHoldingCtrlDown ? 'ctrl-selectable' : ''}`}
          onClick={() => {
            if (isHoldingCtrlDown) { onLeftClickCategoryName(rowData[hasSpecialCategories ? 'specialCategoryId' : 'categoryId']) }
          }
          }
        >
          {rowData?.specialCategoryTitle || rowData?.categoryTitle}
        </div>
      )
    },
    {
      body: (rowData) => {
        return (
          <div className='w-full flex justify-content-between align-items-center gap-2'>
            <span>{rowData.task}</span>
            {rowData.comment
              ? (
                <IconWithTooltip tooltipValue={rowData.comment} iconClassName='pi pi-comment' />
              )
              : null}
          </div>
        )
      },
      field: 'task',
      header: 'Tarea',
      style: { width: '360px', minWidth: '360px', padding: '0.5rem' },
      filter: true,
      filterMatchMode: 'contains',
      filterField: 'task',
      showFilterMenuOptions: false
    },
    {
      field: 'sisuTime',
      header: 'Horas trabajadas',
      colSpan: 1,
      style: { width: '120px', minWidth: '106px', padding: '0.5rem' },
      body: (rowData) => {
        return (
          <div className='flex gap-1'>
            <span>{rowData?.sisuTime}</span>
            {rowData?.sisuTimeModifiedAfterFinished
              ? (
                <WarningBadge
                  iconClassName='pi pi-history'
                  tooltipValue='Los tiempos registrados fueron editados después de finalizar el día.'
                />
              )
              : null}
          </div>
        )
      },
      sortable: true,
      sortField: 'sisuTime'
    },
    {
      field: 'billedTime',
      header: 'Horas facturadas',
      sortable: true,
      sortField: 'billedTime',
      colSpan: 1,
      body: (rowData) => {
        return (
          <div className='flex gap-1'>
            <span>{rowData?.billedTime}</span>
            {rowData?.billedTimeModifiedAfterFinished
              ? (
                <WarningBadge
                  iconClassName='pi pi-history'
                  tooltipValue='Los tiempos registrados fueron editados después de finalizar el día.'
                />
              )
              : null}
          </div>
        )
      },
      style: { width: '120px', minWidth: '106px', padding: '0.5rem' }
    },
    {
      header: 'Diferencia',
      field: 'timeDifference',
      sortable: true,
      style: { paddingLeft: '7px' },
      body: (rowData) => (
        <div className='flex gap-1 w-4rem justify-content-between'>
          {rowData.timeDifference}
          {rowData.billedTime !== rowData.sisuTime &&
            (
              // <ErrorBadge />
              <TimeDifferenceBadge
                value={rowData.timeDifference}
                minTreshold={0}
                maxTreshold={0}
                referenceValue={0}
                tooltip={rowData.timeDifferenceComment ? `Motivo: ${rowData.timeDifferenceComment}` : 'Sin comentarios'}
              />
            )
          }
        </div>
      )
    },
    {
      body: (item) => {
        return (
          item.taskId === updatingTask?.taskId
            ? (
              <IconWithTooltip
                iconClassName='pi pi-spin pi-spinner'
                tooltipValue='Guardando cambios'
              />
            )
            : (
              <i
                className='pi pi-ellipsis-v text-xl cursor-pointer'
                onClick={(event) => {
                  cm?.current.show(event)
                  setSelectedTask(item)
                }}
              />
            )
        )
      },
      colSpan: 1,
      style: {
        maxWidth: '30px',
        padding: '0.5rem',
        display: 'flex',
        justifyContent: 'center'
      }
    }
  ]
}

export default ClientColumnContent
