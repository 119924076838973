import React from 'react'
import { Skeleton } from '../../atoms/misc/Skeleton.atom'
// import Divider from '../../atoms/Divider.atom'
import DataTable from '../../atoms/table/DataTable.atom'
import Column from '../../atoms/table/Column.atom'
import { DEFAULT_SKELETON_TABLE_CONFIG as DEFAULT_CONFIG } from '../../../consts/tables.consts'
export const SkeletonDataTable = React.forwardRef((props, ref) => {
  const config = { ...DEFAULT_CONFIG, ...props.loadingConfig }
  const rows = Array.from({ length: config.qtyOfRows })
  return (
    <DataTable
      headerColumnGroup={props.headerColumnGroup}
      value={rows}
      ref={ref}
      scrollable={props.scrollable}
      virtualScrollerOptions={props.virtualScrollerOptions}
      className={`p-datatable-striped ${props.className}`}
      filterDisplay={props.filterDisplay}
    >
      {console.log(props.children)}
      {props.children.map((col, index) => <Column key={index}

        {...col.props}
        body={<Skeleton />}/>)}
    </DataTable>)
})

export default SkeletonDataTable
