import React from 'react'
import Modal from '../atoms/Modal.atom'
import PrimaryButton from './buttons/PrimaryButton.molecule'
import Divider from '../atoms/Divider.atom'

const CustomDialog = ({ children, className = '', closable = false, ...props }) => {
  const defaultFooter = (
    <div>
      <PrimaryButton rounded icon='pi pi-check' label={props.saveLabel || 'Entendido'} onClick={props.onAccept} disabled={props.disabledSaveBtn}/>
    </div>
  )
  const onHideHandler = () => {
    props.setVisible(false)
  }
  return (
    <Modal
      header={() => {
        if (props.withoutHeader) {
          return null
        }
        if (props.header) {
          return props.header
        }
        if (props?.headerIconClass || props?.headerTitle) {
          return (
            <div className="flex gap-3 align-items-center">
              <i className={`${props?.headerIconClass} text-4xl clr-info`}/>
              <span className="text-3xl font-semibold font-cairo">{props?.headerTitle}</span>
            </div>
          )
        }
      }}
      visible={props.visible}
      breakpoints={{ '960px': '75vw' }} style={{ width: '50vw', maxWidth: '648px' }}
      footer={ props.withoutFooter ? '' : props.footer || defaultFooter }
      onHide={onHideHandler}
      draggable={false}
      className = {className}
      closable={closable}
      contentClassName='pb-0'
    >
      {children}
      {!props.withoutDivider && <Divider className='mb-1'/>}
    </Modal>
  )
}

export default CustomDialog
