import React from 'react'
import FormHeaderCreateClient from './controllers/FormHeaderClient.controller'
import FormEditClient from './controllers/editClient/FormEditClient.controller'

const EditClient = () => {
  return (
    <>
      <FormHeaderCreateClient isCreate={false}/>
      <FormEditClient/>
    </>
  )
}

export default EditClient
