import React from 'react'
import ContentLayoutHeader from '../../../components/layouts/ContentLayoutHeader.layout'

const GeneralReportHeader = React.forwardRef((props, ref) => {
  const exportReport = () => {
    ref.current.exportCSV()
  }

  return (
    <ContentLayoutHeader
      title='Reporte General'
      buttonProps={{
        label: 'Descargar',
        icon: 'pi pi-download',
        className: 'h-fit',
        onClick: () => {
          exportReport()
        }
      }}
    />
  )
})

export default GeneralReportHeader
