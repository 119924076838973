import React from 'react'
import { Chart as PrimeChart } from 'primereact/chart'
/**
 * @param {import("primereact/chart").ChartProps} props
 * @returns
 */
const Chart = (props) => {
  return (
    <PrimeChart {...props}/>
  )
}
export default Chart
