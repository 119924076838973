import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router'
import ModalConfirmation from '../../../components/molecules/ModalConfirmation.molecule'
import { toastMessages } from '../../../consts/common.const'
import { USER_PERMISSIONS } from '../../../consts/modules/usuarios.consts'
import UsersOptionsContext from '../../../contexts/data/UsersOptions.context'
import useToast from '../../../hooks/useToast.hook'
import { changePermissionUsuario, changeUsuarioAccess } from '../../../services/usuario.service'
import { createToastConfig } from '../../../utils/toast.utils'
import PrimaryButton from '../../../components/molecules/buttons/PrimaryButton.molecule'
import ButtonRowContainer from '../../../components/layouts/containers/ButtonRowContainer.container'
import FormModalChangePasswordUser from './FormModalChangePasswordUser.controller'
import { AppRoutes } from '../../../consts/appRoutes.consts'

const FormFooterUserActions = ({ userId, uid, hasPermissions, isDisable, onSave }) => {
  const [visibilityModalEnableUser, setVisibilityModalEnableUser] = useState(false)
  const [visibilityModalDisableUser, setVisibilityModalDisableUser] = useState(false)
  const [visibilityModalGivePermissionUser, setVisibilityModalGivePermissionUser] = useState(false)
  const [visibilityModalRemovePermissionsUser, setVisibilityModalRemovePermissionsUser] = useState(false)
  const [visibilityModalChangePassword, setVisibilityModalChangePassword] = useState(false)

  const { usersOptions } = useContext(UsersOptionsContext)
  const toast = useToast()
  const navigate = useNavigate()

  const goToUsuariosIndex = () => {
    navigate(AppRoutes.usuarios.index)
  }

  const handleRemovePermissions = async () => {
    if (usersOptions) {
      const defaultPermission = usersOptions.permisosDeEdicion.find(
        permiso => permiso.nombre === USER_PERMISSIONS.DEFAULT
      )
      const response = await changePermissionUsuario({
        id: userId,
        permisoDeEdicionId: defaultPermission.id
      })

      if (!response.success) {
        const failMessage = createToastConfig(toastMessages.failToSave)
        return toast.setMessage(...failMessage)
      }
      const successMessage = createToastConfig(toastMessages.successToSave)
      toast.setMessage(...successMessage)

      goToUsuariosIndex()
    }
  }
  const handleGivePermissions = async () => {
    if (usersOptions) {
      const unlimitedPermission = usersOptions.permisosDeEdicion.find(
        permiso => permiso.nombre === USER_PERMISSIONS.UNLIMITED
      )
      const response = await changePermissionUsuario({
        id: userId,
        permisoDeEdicionId: unlimitedPermission.id
      })

      if (!response.success) {
        const failMessage = createToastConfig(toastMessages.failToSave)
        return toast.setMessage(...failMessage)
      }
      const successMessage = createToastConfig(toastMessages.successToSave)
      toast.setMessage(...successMessage)
      goToUsuariosIndex()
    }
  }

  const handleEnable = async () => {
    if (usersOptions) {
      const response = await changeUsuarioAccess({
        id: userId,
        uid,
        access: true
      })

      if (!response.success) {
        const failMessage = createToastConfig(toastMessages.failToSave)
        return toast.setMessage(...failMessage)
      }
      const successMessage = createToastConfig(toastMessages.successToSave)
      toast.setMessage(...successMessage)
      goToUsuariosIndex()
    }
  }
  const handleDisable = async () => {
    if (usersOptions) {
      const response = await changeUsuarioAccess({
        id: userId,
        uid,
        access: false
      })

      if (!response.success) {
        const failMessage = createToastConfig(toastMessages.failToSave)
        return toast.setMessage(...failMessage)
      }
      const successMessage = createToastConfig(toastMessages.successToSave)
      toast.setMessage(...successMessage)
      goToUsuariosIndex()
    }
  }
  const buttonSaveLabel = 'Confirmar'

  const contentModalGivePermission = {
    title: 'Dar permisos de edición ilimitada',
    message: 'Se aplicarán permisos para editar algunos registros del sistema a este usuario',
    buttonSaveLabel
  }
  const contentModalRemovePermissionsUser = {
    title: 'Dar permisos de edición por defecto',
    message: 'Se aplicarán permisos de edición de semanas configuradas por defecto',
    buttonSaveLabel
  }
  const contentModalEnableUser = {
    title: 'Habilitar acceso al sistema',
    message: '¿Seguro que quieres habilitar al usuario?',
    buttonSaveLabel
  }
  const contentModalDisableUser = {
    title: 'Deshabilitar acceso al sistema',
    message: '¿Seguro que quieres deshabilitar al usuario?',
    buttonSaveLabel
  }
  const DisableActions = isDisable
    ? <PrimaryButton
      label="Habilitar"
      onClick={() => setVisibilityModalEnableUser(true)}
    />
    : <PrimaryButton
      label="Deshabilitar"
      onClick={() => setVisibilityModalDisableUser(true)}
    />
  const ChangePasswordButton = (
    <PrimaryButton
      label="Cambiar contraseña"
      onClick={() => setVisibilityModalChangePassword(true)}
    />
  )
  return (
    <>
      <ButtonRowContainer>
        {
          hasPermissions
            ? <PrimaryButton
              label="Quitar permisos"
              onClick={() => setVisibilityModalRemovePermissionsUser(true)}
            />
            : <PrimaryButton
              label="Dar permisos"
              onClick={() => setVisibilityModalGivePermissionUser(true)}
            />
        }
        {
          uid && DisableActions
        }
        {
          uid && ChangePasswordButton
        }
        <PrimaryButton label='Guardar' onClick={onSave}/>
      </ButtonRowContainer>
      <FormModalChangePasswordUser
        uid={uid}
        visible={visibilityModalChangePassword}
        setVisible={setVisibilityModalChangePassword}
      />
      <ModalConfirmation
        visible={visibilityModalRemovePermissionsUser}
        onVisibleChange={(newValue) => setVisibilityModalRemovePermissionsUser(newValue)}
        onAccept={handleRemovePermissions}
        content={contentModalRemovePermissionsUser}
      />
      <ModalConfirmation
        visible={visibilityModalGivePermissionUser}
        onVisibleChange={(newValue) => setVisibilityModalGivePermissionUser(newValue)}
        onAccept={handleGivePermissions}
        content={contentModalGivePermission}
      />
      <ModalConfirmation
        visible={visibilityModalEnableUser}
        onVisibleChange={(newValue) => setVisibilityModalEnableUser(newValue)}
        onAccept={handleEnable}
        content={contentModalEnableUser}
      />
      <ModalConfirmation
        visible={visibilityModalDisableUser}
        onVisibleChange={(newValue) => setVisibilityModalDisableUser(newValue)}
        onAccept={handleDisable}
        content={contentModalDisableUser}
        btnAcceptType='danger'
      />
    </>
  )
}

export default FormFooterUserActions
