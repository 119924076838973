import React from 'react'
import { FileUpload as PrimeFileUpload } from 'primereact/fileupload'

const FileUpload = React.forwardRef(
  /**
   * @param {import('primereact/fileupload').FileUploadProps} props
   * @param {*} ref
   */
  (props, ref) => {
    return (
      <PrimeFileUpload
        ref={ref}
        chooseLabel={props.chooseLabel ? props.chooseLabel : 'Selecciona un archivo'}
        {...props}
      />
    )
  })

export default FileUpload
