/* eslint-disable */
import React, { useLayoutEffect, useRef, useState } from 'react'
import DataTable from '../../../../../components/atoms/table/DataTable.atom'
import Column from '../../../../../components/atoms/table/Column.atom'
import ClientChip from '../../../../../components/molecules/ClientChip.molecule'
import MultiSelect from '../../../../../components/atoms/MultiSelect.atom'
import { createRoot } from 'react-dom/client'
import ColumnContent from './ColumnContent.controller'
import { groupFooterTemplate, groupHeaderTemplate, StickyFooter } from './GroupTemplates.controller'
import { arraysContainSameStrings } from '../../../../../utils/arrays.utils'
import useIsHoldingCtrlDown from '../../../../../hooks/useIsHoldingCtrlDown'
import { DateTime } from 'luxon'
import useDynamicHeight from '../../../../../hooks/useDynamicHeight.hook'

const RegisteredTasksDataTable = React.forwardRef(({
  setSelectedTask,
  data,
  cm,
  period,
  updatingTask
}, ref) => {
  if (!data) return null
  const {
    tasks,
    totals
  } = data
  const [filteredTasks, setFilteredTasks] = useState(tasks || [])
  const [totalsByDay, setTotalsByDay] = useState([])
  const [hasMounted, setHasMounted] = useState(false)
  const fullHeight = useDynamicHeight()
  const modules = [...new Map(tasks?.map(item => {
    return [item.module, { label: item.module, value: item.moduleId }]
  })).values()]
  const categories = [...new Map(tasks?.map(item => {
    if (item?.specialCategoryId) {
      return [item.specialCategoryId, { label: item.specialCategoryTitle, value: item.specialCategoryId, isSpecialCategory: true }]
    }
    return [item.categoryId, { label: item.categoryTitle, value: item.categoryId, isSpecialCategory: false }]
  })).values()]

  const categoryFilterOptionsRef = useRef(null)

  const categoryFilterTemplate = (options) => {
    categoryFilterOptionsRef.current = options

    return (
      <MultiSelect
        value={options.value}
        options={categories}
        onChange={(e) => {
          options.filterCallback(e.value)
        }}
        placeholder='Filtrar categoría'
        emptyFilterMessage='Misión fallida... no encontramos nada'
        maxSelectedLabels={1}
        selectedItemsLabel='{0} seleccionados'
        disabled={categories.length === 0}
      />
    )
  }

  const moduleFilterOptionsRef = useRef(null)

  const moduleFilterTemplate = (options) => {
    moduleFilterOptionsRef.current = options
    const nonEmptyModules = modules.filter(m => m.value !== null)
    const modulesWithEmpty = [{
      label: 'Sin módulo',
      value: null
    }, ...nonEmptyModules]

    return (
      <MultiSelect
        value={options.value}
        options={modulesWithEmpty}
        onChange={(e) => {
          options.filterCallback(e.value)
        }}
        placeholder='Filtrar módulo'
        emptyFilterMessage='Misión fallida... no encontramos nada'
        maxSelectedLabels={1}
        selectedItemsLabel='{0} seleccionados'
        disabled={modules.length === 0}
      />
    )
  }

  const clientFilterOptionsRef = useRef(null)

  const clientFilterTemplate = (options) => {
    clientFilterOptionsRef.current = options
    const projects = []
    tasks?.forEach((item, index) => {
      let project = projects.find(project => project.name === item.clientName)
      if (!project) {
        project = {
          name: item.clientName,
          code: item.clientCode,
          items: []
        }
        projects.push(project)
      }
      let projectItem = project.items.find(project => project.name === item.projectName)
      if (!projectItem) {
        projectItem = {
          name: item.projectName,
          code: item.projectCode
        }
        project.items.push(projectItem)
      }
    })
    return (
      <MultiSelect
        value={options.value}
        options={projects}
        onChange={(e) => {
          options.filterCallback(e.value)
        }}
        optionLabel='name'
        optionValue='name'
        placeholder='Todos'
        optionGroupLabel='name'
        optionGroupChildren='items'
        optionGroupTemplate={(option) => {
          return (
            <ClientChip code={option.code} className='w-fit' />
          )
        }}
        emptyFilterMessage='Misión fallida... no encontramos nada'
        maxSelectedLabels={1}
        selectedItemsLabel='{0} seleccionados'
        disabled={projects.length === 0}
      />
    )
  }

  useLayoutEffect(() => {
    if (!hasMounted || filteredTasks?.length) {
      const dataTable = document.querySelector('.p-datatable-table')
      const footerDiv = document.createElement('table')
      footerDiv.className = 'custom-datatable-footer'
      dataTable.appendChild(footerDiv)
      createRoot(footerDiv).render(<StickyFooter tasks={filteredTasks} totals={totals}/>)
      setHasMounted(true)

      const dailyTotals = calculateTotalsByDay(filteredTasks)
      setTotalsByDay(dailyTotals)

      return () => {
        const dataTable = document.querySelector('.p-datatable-table')
        const footerDiv = document.querySelector('.custom-datatable-footer')
        if (footerDiv) {
          dataTable.removeChild(footerDiv)
        }
      }
    }
  }, [hasMounted, filteredTasks])

  const calculateTotalsByDay = (tasks) => {
    return tasks.reduce((acc, task) => {
      const day = new Date(task.date).toISOString().split('T')[0]
      if (!acc[day]) {
        acc[day] = {
          sisuTime: 0,
          billedTime: 0,
          timeDifference: 0
        }
      }
      acc[day].sisuTime += task.sisuTime
      acc[day].billedTime += task.billedTime
      acc[day].timeDifference += Math.abs(task.timeDifference)
      return acc
    }, {})
  }

  const isHoldingCtrlDown = useIsHoldingCtrlDown()

  return (
    <>
      <DataTable
        ref={ref}
        className='individual-report-table'
        value={
          tasks?.map(task =>
            ({
              ...task,
              date: DateTime.fromISO(task.date?.split('T')[0]).set({ hour: 12 }).toISODate(),
              categoryId: task.specialCategoryId || task.categoryId,
              categoryTitle: task.specialCategoryTitle || task.categoryTitle
            })
          )
        }
        rowGroupMode='subheader'
        groupRowsBy='date'
        rowGroupHeaderTemplate={groupHeaderTemplate}
        rowGroupFooterTemplate={(rowData) => groupFooterTemplate(rowData, totalsByDay, period)}
        removableSort
        scrollable
        scrollHeight={`${fullHeight}px`}
        onValueChange={filteredData => setFilteredTasks(filteredData)}
        onContextMenu={({ originalEvent, data }) => {
          cm?.current.show(originalEvent)
          setSelectedTask(data)
        }}
      >
        {ColumnContent(
          clientFilterTemplate,
          moduleFilterTemplate,
          categoryFilterTemplate,
          cm,
          setSelectedTask,
          isHoldingCtrlDown,
          (moduleId) => {
            const currValue = moduleFilterOptionsRef.current.value
            if (currValue?.length === 1) {
              moduleFilterOptionsRef.current.filterApplyCallback([])
            } else {
              moduleFilterOptionsRef.current.filterApplyCallback([moduleId])
            }
          },
          (categoryId) => {
            const currValue = categoryFilterOptionsRef.current.value
            if (currValue?.length === 1) {
              categoryFilterOptionsRef.current.filterApplyCallback([])
            } else {
              categoryFilterOptionsRef.current.filterApplyCallback([categoryId])
            }
          },
          (projectName) => {
            const currValue = clientFilterOptionsRef.current.value
            if (currValue?.length === 1) {
              clientFilterOptionsRef.current.filterApplyCallback([])
            } else {
              clientFilterOptionsRef.current.filterApplyCallback([projectName])
            }
          },
          (clientCode) => {
            const currValues = clientFilterOptionsRef.current.value
            const filteredClients = Array.from(new Set(tasks.filter(task => task.clientCode === clientCode).map(client => client.projectName)))
            if (currValues && arraysContainSameStrings(currValues, filteredClients)) {
              clientFilterOptionsRef.current.filterApplyCallback([])
            } else {
              clientFilterOptionsRef.current.filterApplyCallback(filteredClients)
            }
          },
          updatingTask
        )?.map((column, index) => (
          <Column
            key={index}
            {...column}
          />
        ))}
      </DataTable>
    </>
  )
})

export default React.memo(RegisteredTasksDataTable)
