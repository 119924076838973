import { APP_MENU } from '../consts/appMenu.consts'
import { DEFAULT_ROUTES } from '../routers/roleRoutes/defaultRoutes.routes'
import { getRoutesByRole } from './routes.utils'

function _getApplicationPathsByRole (role) {
  const routes = getRoutesByRole(role)
  const defaultPaths = DEFAULT_ROUTES.map(({ path }) => path)
  return routes.map(({ path }) => path).concat(defaultPaths)
}

function _filterModules (menu = [], paths = []) {
  const newMenu = menu.filter((item) => {
    if (item.modules) {
      return _filterModules(item.modules, paths).length > 0
    }
    return paths.includes(item.path)
  })

  return newMenu
}

/**
   *
   * @param {string[]} paths
   * @returns {(item) => boolean}
   */
const allowedMenu = (paths) => ({ modules, path }) => {
  if (modules) {
    return modules.length > 0
  }

  return paths.includes(path)
}

/**
   * Returns the app menu based on the user's role
   * @param {string} role
   */
export function getAppMenuByRole (role) {
  const paths = _getApplicationPathsByRole(role)

  return APP_MENU.map((item) => {
    const { name, icon, iconSvg, iconClass } = item
    if (!item.modules) {
      return { name, icon, iconSvg, iconClass, path: item.path }
    }
    return { name, icon, iconSvg, iconClass, modules: _filterModules(item.modules, paths) }
  }).filter(allowedMenu(paths))
}
