/* eslint-disable */
import React from 'react'
import DataTable from '../../../../../components/atoms/table/DataTable.atom'
import Column from '../../../../../components/atoms/table/Column.atom'
import useThemeContext from '../../../../../hooks/contextConsumers/useThemeContext.hook'
import Chip from '../../../../../components/atoms/misc/Chip.atom'
import ClientChip from '../../../../../components/molecules/ClientChip.molecule'
import { formatHours } from '../../../../../utils/time.utils'
import SimpleMessage from '../../../../../components/atoms/SimpleMessage.atom'
import Tooltip from '../../../../../components/atoms/Tooltip.atom'
import PrimaryButton from '../../../../../components/molecules/buttons/PrimaryButton.molecule'
import { InputCheckbox } from '../../../../../components/atoms/InputCheckbox.atom'
import clsx from '../../../../../utils/strings.utils'
import { compareDates } from '../../../../../utils/date.utils'
import { DateTime } from 'luxon'
import useTimeRegisterContext from '../../../hooks/useTimeRegisterContext.hook'

const TaskList = ({
  dailyTasks,
  handleItemDrop,
  isDayEditable = true,
  setSelectedTask,
  contextMenuRef: cm,
  messageProps,
  onClickBeginEditingNewTaskName,
  selectedTasks,
  onClickSelectCheckbox,
  multiSelectCMRef,
  lastWorkDay,
  selectedDate,
  loading
}) => {
  const {
    showedMissingDayDialog,
    editingMissingDay
  } = useTimeRegisterContext()
  const isSelectingTasks = selectedTasks.length > 0
  const { theme } = useThemeContext()

  const taskTemplate = (item) => {
    const textColor = theme === 'light' ? 'clr-secondary' : ''
    const isSelected = selectedTasks.some(task => task.taskID === item.taskID)
    // console.log('taskTemplate', item)
    return (
      <div className={`flex align-items-center justify-content-flex-start task-wrapper h-fit py-2 ${isSelected ? 'p-datatable-cell-selected' : ''}`}>
        <div className="w-full flex justify-content-between align-items-center task-data gap-2">
          <span className="flex-1">{item.task}</span>
          <div className='task-data-chips flex-1 flex gap-2 align-items-center'>
            <Chip
              icon={`pi pi-comment p-chip-text justify-content-center p-0 m-0 text-xs ${item?.comment ? `comment-${item?.taskID}` : ''} ${item?.comment ? 'bg-info text-white' : ''}`}
              className={`${item?.comment ? 'bg-info text-white' : ''} h-full p-chip-text justify-content-center p-0 mx-1 ${item?.comment ? `comment-bubble-${item?.taskID}` : ''}`}
            />
            {item?.comment && (<Tooltip target={`.comment-bubble-${item?.taskID}`} position='bottom' content={item?.comment} />)}
            <div style={{ minWidth: '180px' }}>
              <Chip
                template={
                  <span className='p-chip-text flex align-items-center m-0 text-overflow-ellipsis white-space-nowrap overflow-hidden max-w-12rem w-fit'>
                    {item?.specialCategory || item?.category?.split('(')?.[0]}
                  </span>
                }
              />
            </div>
            <ClientChip code={item.clientCode} style={{ minHeight: '32px' }} />
            <span className={`${textColor}`}>{item.projectCode}{item.module ? ` - ${item.module}` : null}</span>
          </div>
          <div className={`flex flex-column justify-content-between gap-1 ${textColor}`}>
            <span>
              <i className='pi pi-stopwatch pr-1' style={{ color: 'var(--color-text)' }} />
              {formatHours(item.sisuTime)}
            </span>
            {
              item.sisuTime !== item.billedTime &&
              <span>
                <i className='pi pi-dollar pr-1' style={{ color: 'var(--color-text)' }} />
                {formatHours(item.billedTime)}
              </span>
            }
          </div>
          {
            isDayEditable
              ? <i
                className='pi pi-ellipsis-v text-xl cursor-pointer px-2'
                onClick={(event) => {
                  cm.current.show(event)
                  setSelectedTask(item)
                }}
              />
              : null
          }
        </div>
      </div>
    )
  }
  const editableDayEmptyTaskTemplate = () => {
    return (
      <div className="empty-tasks-wrapper w-full">
        <div className='flex flex-column gap-2 align-items-center justify-content-center max-w-25rem m-auto text-center'>
          <i className="pi pi-folder-open text-3xl" />
          <span className='text-xl font-semibold'>Nada por aquí (todavía)</span>
          <span>Pequeños pasos, grandes avances y tus mejores victorias ¡Registra todas tus tareas del día!</span>
          <PrimaryButton
            label={'¡Añade una tarea!'}
            icon={'pi pi-plus'}
            disabled={!isDayEditable}
            className='w-fit'
            onClick={onClickBeginEditingNewTaskName}
          />
        </div>
      </div>
    )
  }

  const nonEditableEmptyTaskTemplate = () => {
    return (
      <div className="empty-tasks-wrapper w-full">
        <div className='flex flex-column gap-2 align-items-center justify-content-center max-w-25rem m-auto text-center'>
          <i className="pi pi-folder-open text-3xl" />
          <span className='text-xl font-semibold'>¡Día libre!... de tareas</span>
          <span>Si no es lo que esperabas, contacta al administrador para que te ayude a registrar tus tareas.</span>
        </div>
      </div>
    )
  }

  const loadingTasksTemplate = () => {
    return (
      <div className="empty-tasks-wrapper w-full">
        <div className='flex flex-column gap-2 align-items-center justify-content-center max-w-25rem m-auto'>
          <i className="pi pi-spin pi-spinner text-3xl" />
          <span className='text-xl font-semibold'>Cargando tareas...</span>
        </div>
      </div>
    )
  }

  const ignoredDayTemplate = () => {
    return (
      <div className='empty-tasks-wrapper w-full'>
        <div className='flex flex-column gap-2 align-items-center justify-content-center max-w-25rem m-auto text-center'>
          <i className='pi pi-folder-open text-3xl' />
          <span className='text-xl font-semibold'>¡Día!... ignorado ...</span>
          <span>Decidiste ignorar este día, por lo que puedes seguir con tu registro normal.</span>
        </div>
      </div>
    )
  }
  // console.log(selectedDate, DateTime.local(),compareDates(selectedDate, DateTime.local().toJSDate()))
  // console.log('dailyTasks', (dailyTasks?.length === 0 && !isDayEditable  && !loading), loading, dailyTasks, isDayEditable, lastWorkDay, selectedDate)
  return (
    <>
      {
        messageProps && <SimpleMessage {...messageProps} className='w-full justify-content-start mt-0 mb-1' />
      }
      {
        dailyTasks && dailyTasks.length > 0 && !loading &&
        <div className='card w-full p-0'>
          <DataTable
            className={clsx('today-tasks-list', isSelectingTasks && 'is-multiselect')}
            value={dailyTasks}
            reorderableRows={dailyTasks.length !== 1 && selectedTasks.length === 0}
            onRowReorder={handleItemDrop}
            responsiveLayout='scroll'
            noSameWidthColumns
            noFixedColumns
            withOutHeader
            onContextMenu={!isDayEditable
              ? null
              : ({ originalEvent, data }) => {
                if (isSelectingTasks) {
                  multiSelectCMRef?.current.show(originalEvent)
                } else {
                  cm?.current.show(originalEvent)
                  setSelectedTask(data)
                }
              }}
            onRowClick={({ e, data }) => {
              if (isSelectingTasks) onClickSelectCheckbox(data)
            }}
          >
            {
              isSelectingTasks
                ? (
                  <Column
                    style={{ width: '1rem', padding: '7px' }}
                    body={(rowData) =>
                      <InputCheckbox
                        checked={selectedTasks.some(task => task.taskID === rowData.taskID)}
                      />
                    }
                  />
                )
                : (
                  isDayEditable &&
                  <Column
                    style={{ width: '1rem', padding: '7px' }}
                    className={`${dailyTasks.length === 1 ? 'non-reorderable' : ''}`}
                    rowReorder
                  />
                )
            }
            <Column
              body={taskTemplate}
              style={{ padding: '7px 7px 7px 0px' }}
              className={clsx(isSelectingTasks && 'p-datatable-cell-multiselect-content')}
            />
          </DataTable>
        </div>
      }
      {
        (loading) &&
        loadingTasksTemplate()
      }
      {
        (dailyTasks?.length === 0 && isDayEditable && compareDates(DateTime.local().toJSDate(), selectedDate) && !loading) &&
        editableDayEmptyTaskTemplate()
      }
      {
        (!compareDates(DateTime.local().toJSDate(), selectedDate) && dailyTasks?.length === 0 && !loading) &&
        nonEditableEmptyTaskTemplate()
      }
{/* {
  loading && loadingTasksTemplate()
}

{
  !loading && dailyTasks?.length === 0 && isDayEditable &&
  showedMissingDayDialog &&
  compareDates(selectedDate, DateTime.local().toJSDate()) &&
  editableDayEmptyTaskTemplate()
}

{
  !loading && dailyTasks?.length === 0 &&
  // !compareDates(DateTime.local().toJSDate(), selectedDate) &&
  nonEditableEmptyTaskTemplate()
}
{/* 
{
  !loading && dailyTasks?.length === 0 &&
  !compareDates(selectedDate, DateTime.local().toJSDate()) && !editingMissingDay &&
  showedMissingDayDialog &&
  ignoredDayTemplate()
} */}





    </>
  )
}

export default TaskList
