import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import TabView from '../../../components/atoms/tabView/TabView.atom'
import TabPanel from '../../../components/atoms/tabView/TabPanel.atom'
import WeekHoursReport from './WeekHoursReport.controller'
import HoursByProjectReport from './HoursByProjectReport.controller'
import HoursByClientReport from './HoursByClientReport.controller'
import HoursByTeamAndTeamMemberReport from './HoursByTeamAndTeamMemberReport.controller'

const GeneralReportContent = React.forwardRef((props, ref) => {
  const location = useLocation()
  const navigate = useNavigate()

  const tabs = [
    '/reporte-general/horas-semana',
    '/reporte-general/horas-proyecto',
    '/reporte-general/horas-cliente',
    '/reporte-general/horas-equipo'
  ]

  const initialTabIndex = tabs.indexOf(location.pathname)
  const [activeIndex, setActiveIndex] = useState(initialTabIndex === -1 ? 0 : initialTabIndex)

  const handleTabChange = (e) => {
    setActiveIndex(e.index)
    navigate(tabs[e.index])
  }

  useEffect(() => {
    const newIndex = tabs.indexOf(location.pathname)
    if (newIndex !== -1 && newIndex !== activeIndex) {
      setActiveIndex(newIndex)
    } else if (location.pathname === '/reporte-general') {
      navigate(tabs[activeIndex], { replace: true })
    }
  }, [location.pathname, activeIndex, tabs, navigate])

  return (
    <div id="general-report">
      <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
        <TabPanel header='Horas de la semana'>
          <WeekHoursReport ref={ref} />
        </TabPanel>
        <TabPanel header='Horas totales por proyecto'>
          <HoursByProjectReport ref={ref} />
        </TabPanel>
        <TabPanel header='Horas totales por cliente'>
          <HoursByClientReport ref={ref} />
        </TabPanel>
        <TabPanel header='Horas del equipo'>
          <HoursByTeamAndTeamMemberReport ref={ref} />
        </TabPanel>
      </TabView>
    </div>
  )
})

export default GeneralReportContent
