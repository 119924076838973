import React, { useEffect } from 'react'
import useSessionContext from '../../hooks/contextConsumers/useSessionContext.hook'
import { useNavigate } from 'react-router-dom'
import { Rol as TEAM_MEMBER_ROLES } from '../../consts/roles.consts'
export default function Home () {
  const { user } = useSessionContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (user.rol === TEAM_MEMBER_ROLES.ADMIN) {
      navigate('/reporte-general')
    } else {
      navigate('/registro-de-tiempos')
    }
  }, [])
  return (
    <span>👀</span>
  )
}
