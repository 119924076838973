import React, { useRef } from 'react'
import GeneralReportContent from './controllers/GeneralReportContent.controller'
import GeneralReportHeader from './controllers/GeneralReportHeader.controller'

const GeneralReport = () => {
  const currentDataTableRef = useRef(null)

  return (
    <>
      <GeneralReportHeader ref={currentDataTableRef}/>
      <GeneralReportContent ref={currentDataTableRef}/>
    </>
  )
}

export default GeneralReport
