import React, { useState, useRef } from 'react'
import IndividualReportContent from './controllers/IndividualReportContent.controller'
import IndividualReportHeader from './controllers/IndividualReportHeader.controller'
const IndividualReport = () => {
  const currentDataTableRef = useRef(null)
  const [currentTab, setCurrentTab] = useState(0)

  return (
    <>
      <IndividualReportHeader
        ref={currentDataTableRef}
        currentTab={currentTab}
      />
      <IndividualReportContent
        ref={currentDataTableRef}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
    </>
  )
}

export default IndividualReport
