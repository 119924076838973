import React, { forwardRef } from 'react'
import DropdownCustomFilterSearch from './DropdownCustomFilterSearch.molecule'
import DropdownInput from './DropdownInput.molecule'
import {
  checkAllFields,
  fieldsDoesNotHaveErrors
} from '../../utils/validations.utils'

const DropdownWithTitleSubtitle = forwardRef(({
  placeholder,
  label,
  name,
  options,
  optionValue,
  optionLabel,
  value,
  onChange,
  titleKey = 'title',
  subtitleKey = 'subtitle',
  fullWidth = false,
  onPressEnter,
  formValidations,
  setFormValidations,
  handleFormSubmit,
  loading,
  groupKey,
  ...props
}, ref) => {
  const valueTemplate = (option) => {
    const currentOption = options?.find(o => o?.[optionValue] === option?.[optionValue])
    return (
      <div className='flex flex-column justify-content-between'>
        <span className={`${fullWidth ? 'w-full' : 'max-w-10rem'} text-overflow-ellipsis white-space-nowrap overflow-hidden`}>{currentOption?.[titleKey] || label}</span>
      </div>
    )
  }

  const itemTemplate = (option) => {
    const currentOption = options?.find(o => o?.[optionValue] === option?.[optionValue])
    return (
      <div key={currentOption?.[subtitleKey]} className='flex flex-column justify-content-between category-item'>
        <span className={`${fullWidth ? 'w-full' : 'max-w-10rem'} text-overflow-ellipsis white-space-nowrap overflow-hidden`}>{currentOption?.[titleKey]}</span>
        <span className={`text-sm ${fullWidth ? 'w-full' : 'max-w-10rem'} white-space-normal`} style={{ color: '#737E8D' }}>{currentOption?.[subtitleKey]}</span>
      </div>
    )
  }
  const canGroupByKey = options?.findIndex(o => o[groupKey]) !== -1
  let optionsToUse = options
  if (canGroupByKey) {
    optionsToUse = options.reduce((acc, value) => {
      const index = acc.findIndex(c => c.label === value[groupKey])
      if (index === -1) {
        acc.push({
          label: value[groupKey],
          items: [{
            ...value
          }]
        })
      } else {
        acc[index].items.push({
          ...value
        })
      }
      return acc
    }, [])
  }

  return (
    <DropdownInput
      ref={ref}
      placeholder={label}
      options={optionsToUse}
      optionValue={optionValue}
      optionLabel={optionLabel}
      value={value}
      itemTemplate={itemTemplate}
      valueTemplate={(option) => valueTemplate(option)}
      onChange={onChange}
      name={name}
      filter
      filterTemplate={props => <DropdownCustomFilterSearch
        props={props}
        inputProps={{
          autoFocus: true,
          onKeyDown: (e) => {
            if (e.key === 'Tab') {
              e.preventDefault()
            }
            props.filterInputKeyDown(e)
          },
          onKeyUp: (e) => {
            if (e.key === 'Enter') {
              checkAllFields(formValidations, setFormValidations)
              if (fieldsDoesNotHaveErrors(formValidations)) {
                handleFormSubmit()
                return
              }
            }

            if (e.key === 'Enter' || e.key === 'Tab') {
              onPressEnter(e)
            }
          }
        }}
      />}
      emptyFilterMessage={'Misión fallida... no encontramos nada'}
      {...props}
    />
  )
})

export default DropdownWithTitleSubtitle
