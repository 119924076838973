import React from 'react'

const FieldContainer = ({ col = 12, md = 6, children, className = '' }) => {
  return (
    <div className={`field col-${col} md:col-${md} ${className || ''}`}>
      {children}
    </div>
  )
}

export default FieldContainer
