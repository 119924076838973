import { DateTime, Interval } from 'luxon'
import { DIAS_INDEX } from '../consts/modules/cosecha.consts'
import { capitalizeFirstLetter } from '../utils/strings.utils'

export const getWeekByDate = (date) => {
  if (date) {
    const dateISO = new Date(date).toISOString()
    return `Semana ${getWeek(dateISO)} - ${new Date(date).getFullYear()} `
  }
}
export const getNombreWeekByDate = (date) => {
  if (date) {
    const dateISO = new Date(date).toISOString()
    return `${getWeek(dateISO)} - ${new Date(date).getFullYear()}`
  }
}

export const isActive = (date) => {
  return new Date(date) < new Date()
}

const getWeek = (date) => {
  return DateTime.fromISO(date).toUTC().weekNumber
}

export function dateFormatLong (fecha = undefined, { zone = 'UTC-6' } = {}) {
  const now = DateTime.now().setZone(zone)
  const date = fecha ? toDateTime(fecha, { zone }) : now
  // Ejemplo de formato: 01 de enero de 2023
  return date.setLocale('es').toLocaleString({
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  })
}

export const dateFormat = (date) => {
  if (date) {
    const dateLuxon = DateTime.fromISO(date)
    return dateLuxon.setLocale('es').toLocaleString({
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
  }
}
/* Obtiene la fecha del lunes de la semana que pertenece la fecha ingresada. */
export const getMondayOfTheWeek = (date, dayOfWeekToGet = 1) => {
  let dateToUse = null
  if (typeof date === 'string') {
    dateToUse = DateTime.fromISO(date)
  } else {
    dateToUse = DateTime.fromJSDate(date)
  }
  const plusDay = dateToUse.weekday - 1
  const generateFinalDate = dateToUse.minus({ days: plusDay, hours: -1 })
    .set({ hour: 0, minute: 0, second: 0, weekday: dayOfWeekToGet })
  return generateFinalDate
}

export const getSundayOfTheWeek = (date, dayOfWeekToGet = 7) => {
  let dateToUse = null
  if (typeof date === 'string') {
    dateToUse = DateTime.fromISO(date)
  } else {
    dateToUse = DateTime.fromJSDate(date)
  }
  const plusDay = dateToUse.weekday - 1
  const generateFinalDate = dateToUse.minus({ days: plusDay, hours: -1 })
    .set({ hour: 0, minute: 0, second: 0, weekday: dayOfWeekToGet })
  return generateFinalDate
}

export const dateTimeFormat = (date, { separator = '-' } = {}) => {
  if (date) {
    const dateLuxon = DateTime.fromISO(date)
    return dateLuxon.setLocale('es').toFormat(`dd/LL/yyyy ${separator} HH:mm`)
  }
}

export const dateTimeFormat12Hours = (isoDate) => {
  if (isoDate) {
    const dateLuxon = DateTime.fromISO(isoDate)
    return dateLuxon.setLocale('es').toFormat('dd/LL/yyyy - hh:mm a')
  }
}

export const timeFormat12Hours = (isoDate) => {
  if (isoDate) {
    const dateLuxon = DateTime.fromISO(isoDate)
    return dateLuxon.setLocale('es').toFormat('hh:mm a')
  }
}

/* Genera un array de strings con el nombre de cada semana "23 - 2021" con el rango de fechas ingresado. */
export const generateWeeksOfADateRange = (fistDate, secondDate) => {
  const weekGenerated = []
  let fistDateRaw = null
  let secondDateRaw = null
  if (typeof fistDate === 'string') {
    fistDateRaw = DateTime.fromISO(fistDate)
  } else {
    fistDateRaw = DateTime.fromJSDate(fistDate)
  }
  if (typeof secondDate === 'string') {
    secondDateRaw = DateTime.fromISO(secondDate)
  } else {
    secondDateRaw = DateTime.fromJSDate(secondDate)
  }

  const inverval = Interval.fromDateTimes(fistDateRaw.set({ hour: 0 }).toJSDate(), secondDateRaw.set({ hour: 0 }).toJSDate())
  const weeks = Math.round(inverval.length('week'))
  for (let i = 0; i < weeks; i++) {
    const newTime = fistDateRaw.plus({ weeks: i })
    weekGenerated.push({
      weekName: getWeekByDate(newTime),
      associatedDate: newTime.set({ hour: 0 }).toJSDate()
    })
  }
  return weekGenerated
}
/* Convierte una fecha en formato ISO. */
export const convertDateToISO = (date) => {
  let dateRaw = null
  if (typeof fistDate === 'string') {
    dateRaw = DateTime.fromISO(date)
  } else {
    dateRaw = DateTime.fromJSDate(date)
  }
  return dateRaw.toUTC().toISO()
}

export const compareDates = (fistDate, secondDate, options) => {
  const defaultConfig = {
    compareYear: true,
    compareMonth: true,
    compareDay: true
  }
  const compareYear = options?.compareYear ?? defaultConfig.compareYear
  const compareMonth = options?.compareMonth ?? defaultConfig.compareMonth
  const compareDay = options?.compareDay ?? defaultConfig.compareDay
  let fistDateRaw = null
  let secondDateRaw = null
  if (typeof fistDate === 'string') {
    fistDateRaw = DateTime.fromISO(fistDate)
  } else {
    fistDateRaw = DateTime.fromJSDate(fistDate)
  }
  if (typeof secondDate === 'string') {
    secondDateRaw = DateTime.fromISO(secondDate)
  } else {
    secondDateRaw = DateTime.fromJSDate(secondDate)
  }

  const fistDay = fistDateRaw.day
  const fistMonth = fistDateRaw.month
  const fistYear = fistDateRaw.year

  const secondDay = secondDateRaw.day
  const secondMonth = secondDateRaw.month
  const secondYear = secondDateRaw.year

  const resultDay = compareDay ? (fistDay === secondDay) : true
  const resultMonth = compareMonth ? (fistMonth === secondMonth) : true
  const resultYear = compareYear ? (fistYear === secondYear) : true

  return resultDay && resultMonth && resultYear
}

export const generateDateAndTimeNow = () => DateTime.now().toUTC().toJSDate()

export const generateDateMondayCurrentWeek = () => getMondayOfTheWeek(DateTime.now().toUTC().toJSDate()).toJSDate()

export const getWeekDayFromISODate = (date) => {
  return DateTime.fromISO(date).toUTC().weekday
}

export const convertISODateToJSDate = (date) => {
  return DateTime.fromISO(date).toUTC().toJSDate()
}

export const getTimestampFromJSDate = (date) => {
  return DateTime.fromJSDate(date).toSeconds()
}

export const getTimestampFromISODate = (date) => {
  return DateTime.fromISO(date).toSeconds()
}

export const getNombreSemana = (date, withSemana = true, withYear = true, capitalize = true) => {
  const yearPart = ` - ${DateTime.fromISO(date).toUTC().set({ weekday: 7 }).year}`
  const textOfWeek = capitalize ? 'Semana ' : 'semana '
  return `${withSemana ? textOfWeek : ''}${DateTime.fromISO(date).toUTC().weekNumber}${withYear ? yearPart : ''}`
}

export const getNombreSemanaShort = (date, withYear = true) => {
  if (!date) return null
  const year = `${DateTime.fromISO(date).toUTC().set({ weekday: 7 }).year}`
  const yearFormat = `${year[year.length - 2]}${year[year.length - 1]}`
  const yearPart = ` - ${yearFormat}`
  return `${DateTime.fromISO(date).toUTC().weekNumber}${withYear ? yearPart : ''}`
}

export const getNombreSemanaFromJSDate = (date, withSemana = true, withYear = true) => {
  const yearPart = ` - ${DateTime.fromJSDate(date).toUTC().set({ weekday: 7 }).year}`
  return `${withSemana ? 'Semana ' : ''}${DateTime.fromJSDate(date).toUTC().weekNumber}${withYear ? yearPart : ''}`
}

export const getNombreSemanaShortFromJSDate = (date, withYear = true) => {
  const year = `${DateTime.fromJSDate(date).toUTC().set({ weekday: 7 }).year}`
  return `${DateTime.fromJSDate(date).toUTC().weekNumber} - ${withYear ? year : ''}`
}

export const minusWeeksFromJSDate = (date, weeksToRest) => {
  const rawDate = DateTime.fromJSDate(date)
  const newDateMinusWeeks = rawDate.minus({ weeks: weeksToRest })
  return newDateMinusWeeks.toJSDate()
}

export const minusWeeksFromISODate = (date, weeksToRest) => {
  const rawDate = DateTime.fromISO(date)
  const newDateMinusWeeks = rawDate.minus({ weeks: weeksToRest })
  return newDateMinusWeeks.toISODate()
}

export const getDayNameAndDate = (fecha, { capitalize = true, includeTime = false, zone = 'UTC-6' } = {}) => {
  const now = DateTime.now().setZone(zone)
  const date = fecha ? DateTime.fromISO(fecha).setZone(zone) : now
  const formattedDate = date.setLocale('es').toLocaleString({
    weekday: 'long',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: includeTime ? '2-digit' : undefined,
    minute: includeTime ? '2-digit' : undefined,
    hour12: true
  }).replace(',', '')
  return capitalize ? formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1) : formattedDate
}

export const getDayNameAndDateFormat = (date) => {
  const isoDate = DateTime.fromISO(date).toUTC()
  const days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']
  const weekDayNumber = isoDate.weekday - 1
  return `${days[weekDayNumber]} ${isoDate.toFormat('dd/LL/yyyy')}`
}

export const getDateDDMMYYY = (date) => {
  if (!date) return ''
  const isoDate = DateTime.fromISO(date).toUTC()
  return isoDate.toFormat('dd/MM/yyyy')
}

export function getMonthYearFromISODate (isoDate, capitalize = true) {
  const dateTime = DateTime.fromISO(isoDate)
  const monthYear = dateTime.setLocale('es').toLocaleString({ month: 'long', year: 'numeric' })
  return capitalize ? monthYear.charAt(0).toUpperCase() + monthYear.slice(1) : monthYear
}

export function removeTimeFromISODate (isoDate) {
  const date = DateTime.fromISO(isoDate)
  return date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toUTC().toISO()
}

export function isSameDay (isoDate1, isoDate2) {
  const date1 = DateTime.fromISO(isoDate1)
  const date2 = DateTime.fromISO(isoDate2)
  return date1.hasSame(date2, 'day')
}
export function isSameDayToUTC (isoDate1, isoDate2) {
  const date1 = DateTime.fromISO(isoDate1).toUTC()
  const date2 = DateTime.fromISO(isoDate2).toUTC()
  return date1.hasSame(date2, 'day')
}

export const plusWeeksFromISODate = (date, weeksToAdd) => {
  const rawDate = DateTime.fromISO(date)
  const newDatePlusWeeks = rawDate.plus({ weeks: weeksToAdd })
  const zoneSetted = newDatePlusWeeks
  return zoneSetted.toISO()
}

export const plusWeeksFromJSDate = (date, weeksToAdd) => {
  const rawDate = DateTime.fromJSDate(date)
  const newDatePlusWeeks = rawDate.plus({ weeks: weeksToAdd })
  const zoneSetted = newDatePlusWeeks
  return zoneSetted.toJSDate()
}

export const minusDaysFromJSDate = (date, daysToMinus) => {
  const dateRow = DateTime.fromJSDate(date)
  const newDate = dateRow.minus({ days: daysToMinus })
  return newDate.toJSDate()
}

export const minusDaysFromISODate = (date, daysToMinus) => {
  const dateRow = DateTime.fromISO(date)
  const newDate = dateRow.minus({ days: daysToMinus })
  return newDate.toISO()
}

export const plusDaysFromJSDate = (date, daysToPlus) => {
  const dateRow = DateTime.fromJSDate(date)
  const newDate = dateRow.plus({ days: daysToPlus })
  return newDate.toJSDate()
}

export const plusMonthsFromJSDate = (date, monthsToPlus) => {
  const dateRow = DateTime.fromJSDate(date)
  const newDate = dateRow.plus({ months: monthsToPlus })
  return newDate.toJSDate()
}

export const plusMonthsFromObject = (date, monthsToPlus) => {
  const dateRow = DateTime.fromObject(date)
  const newDate = dateRow.plus({ months: monthsToPlus })
  return newDate.toObject()
}

export const plusDaysFromISODate = (date, daysToPlus) => {
  const dateRow = DateTime.fromISO(date)
  const newDate = dateRow.plus({ days: daysToPlus })
  return newDate.toISO()
}
/**
 * Verifica si una fecha esta entre dos fechas
 * @param {string} isoDate Fecha a verificar
 * @param {string} isoDateInicial Fecha inicial
 * @param {string} isoDateFinal Fecha final
 * @returns {boolean}
 */
export function isBetweenDates (isoDate, isoDateInicial, isoDateFinal) {
  const date = DateTime.fromISO(isoDate)
  const fechaInicial = DateTime.fromISO(isoDateInicial)
  const fechaFinal = DateTime.fromISO(isoDateFinal)
  return date >= fechaInicial && date <= fechaFinal
}

export function isGreaterThanEquals (isoDate1, isoDate2) {
  const date1 = DateTime.fromISO(isoDate1)
  const date2 = DateTime.fromISO(isoDate2)
  return date1 >= date2
}

export function isGreaterThan (isoDate1, isoDate2) {
  const date1 = DateTime.fromISO(isoDate1)
  const date2 = DateTime.fromISO(isoDate2)
  return date1 > date2
}

export function addMinutes (isoDate, minutes) {
  const date = DateTime.fromISO(isoDate)
  return date.plus({ minutes }).toUTC().toISO()
}

export const checkDateIfIsBeforeOfTheCurrent = (weekDateMonday) => {
  const modayCurrentWeekDate = generateDateMondayCurrentWeek()
  const minusADay = minusDaysFromJSDate(modayCurrentWeekDate, 1)
  const actualWeekTimestamp = getTimestampFromJSDate(minusADay)
  const weekDateSelected = getTimestampFromJSDate(weekDateMonday)
  return weekDateSelected < actualWeekTimestamp
}

export const checkDateIfIsThePreviousWeek = (weekDateMonday) => {
  const modayCurrentWeekDate = generateDateMondayCurrentWeek()
  const dateNextWeekReference = minusWeeksFromJSDate(modayCurrentWeekDate, 1)
  const minusADay = minusDaysFromJSDate(dateNextWeekReference, 1)
  const plusADay = plusDaysFromJSDate(dateNextWeekReference, 1)

  const firstDayToCompare = getTimestampFromJSDate(minusADay)
  const secondDayToCompare = getTimestampFromJSDate(plusADay)

  const weekDateSelected = getTimestampFromJSDate(weekDateMonday)
  return weekDateSelected >= firstDayToCompare && weekDateSelected <= secondDayToCompare
}
/**
 * Convierte una fecha al objeto DateTime de Luxon.
 * La zona horaria por defecto es UTC-6.
 * @param {Date | string | DateTime} date
 * @param {{ zone?: string }} config
 * @returns
 */
function toDateTime (date, { zone = 'UTC-6' } = {}) {
  if (typeof date === 'string') {
    return DateTime.fromISO(date).setZone(zone)
  }
  if (date instanceof Date) {
    return DateTime.fromJSDate(date).setZone(zone)
  }
  return date
}

/**
 * Devuelve la fecha de inicio y fin de la semana de la fecha indicada.
 * En caso de no proporcionar una fecha, devolverá los límites de la semana actual.
 * La zona horaria por defecto es UTC-6.
 * @param {Date | string | DateTime} fecha
 * @param {{ zone?: string }} config
 * @returns {{ fechaInicio: Date, fechaFin: Date }}
 */
export function getLimitesSemana (fecha = undefined, { zone = 'UTC-6' } = {}) {
  const now = DateTime.now().setZone(zone)
  const date = fecha ? toDateTime(fecha, { zone }) : now

  // Obtener el primer día de la semana (lunes)
  const fechaInicio = date.startOf('week').toJSDate()
  // Obtener el último día de la semana (domingo)
  const fechaFin = date.endOf('week').toJSDate()

  return {
    fechaInicio,
    fechaFin
  }
}

export function getLimitesMes (fecha = undefined, { zone = 'UTC-6' } = {}) {
  const now = DateTime.now().setZone(zone)
  const date = fecha ? toDateTime(fecha, { zone }) : now

  const fechaInicio = date.startOf('month').toJSDate()
  const fechaFin = date.endOf('month').toJSDate()

  return {
    fechaInicio,
    fechaFin
  }
}

export const checkDateIfIsTheNextWeek = (weekDateMonday) => {
  const modayCurrentWeekDate = generateDateMondayCurrentWeek()
  const dateNextWeekReference = plusWeeksFromJSDate(modayCurrentWeekDate, 1)
  const minusADay = minusDaysFromJSDate(dateNextWeekReference, 1)
  const plusADay = plusDaysFromJSDate(dateNextWeekReference, 1)

  const firstDayToCompare = getTimestampFromJSDate(minusADay)
  const secondDayToCompare = getTimestampFromJSDate(plusADay)

  const weekDateSelected = getTimestampFromJSDate(weekDateMonday)
  return weekDateSelected >= firstDayToCompare && weekDateSelected <= secondDayToCompare
}

export const checkDateIfIsTheCurrentWeek = (weekDateMonday) => {
  const dateNextWeekReference = generateDateMondayCurrentWeek()
  const minusADay = minusDaysFromJSDate(dateNextWeekReference, 1)
  const plusADay = plusDaysFromJSDate(dateNextWeekReference, 1)

  const firstDayToCompare = getTimestampFromJSDate(minusADay)
  const secondDayToCompare = getTimestampFromJSDate(plusADay)

  const weekDateSelected = getTimestampFromJSDate(weekDateMonday)
  return weekDateSelected >= firstDayToCompare && weekDateSelected <= secondDayToCompare
}

export const isDayEditable = (dayName) => {
  const actualWeekDay = DateTime.now().toUTC().weekday
  const weekDayRow = DIAS_INDEX[dayName]
  return weekDayRow >= actualWeekDay
}

export const getDateFormatDDMMYYYFromJSDate = (dateJSDate) => {
  if (!dateJSDate) return ''
  const date = DateTime.fromJSDate(dateJSDate)
  return date.toFormat('dd/LL/yyyy')
}

export const getIndexDay = (date) => {
  const dateLuxon = DateTime.fromJSDate(date)
  const index = dateLuxon.weekday - 1
  return index
}

export function isSameMonth (isoDate1, isoDate2) {
  const date1 = DateTime.fromISO(isoDate1)
  const date2 = DateTime.fromISO(isoDate2)
  return date1.month === date2.month && date1.year === date2.year
}

export const getDateFormatDDDFromISODate = (date) => {
  return DateTime.fromISO(date).setLocale('es').toFormat('DDD')
}

export const generateDaysOptionsByWeekDate = (weekDate) => {
  const selectedWeek = DateTime.fromJSDate(weekDate).toUTC()
  const daysToGetOptions = [1, 2, 3, 4, 5, 6]
  const days = []
  daysToGetOptions.forEach(weekday => {
    const dataDay = {
      dia: getDayNameAndDateFormat(selectedWeek.set({ weekday }).toISO()),
      fecha: selectedWeek.set({ weekday }).toISO(),
      weekDay: weekday
    }
    days.push(dataDay)
  })
  return days
}

export const getMondayOfCurrentWeek = () => {
  const currentDate = new Date()
  const dayOfWeek = currentDate.getDay()
  const diff = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek
  const monday = new Date(currentDate.setDate(currentDate.getDate() + diff))
  monday.setHours(0, 0, 0, 0)
  return monday
}

export const getSundayOfCurrentWeek = () => {
  // const currentDate = new Date()
  // const dayOfWeek = currentDate.getDay()
  // const diff = (dayOfWeek === 0 ? 0 : 7 - dayOfWeek)
  // const sunday = new Date(currentDate.setDate(currentDate.getDate() + diff))
  // sunday.setHours(23, 59, 59, 999)
  // return sunday
  const currentDate = DateTime.local()
  const sunday = currentDate.endOf('week')
  return sunday
}

export const getSundayOfWeek = (givenDate) => {
  const date = DateTime.fromJSDate(givenDate)
  const sunday = date.endOf('week')
  return sunday
}
export const getMondayOfWeek = (givenDate) => {
  const date = DateTime.fromJSDate(givenDate)
  const monday = date.startOf('week')
  return monday.toJSDate()
}
// export const generatePeriodPickerOptions = () => {
//   const today = new Date()
//   const currentWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1)
//   const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6)
//   const currentMonth = new Date(today.getFullYear(), today.getMonth(), 1)
//   const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
//   const currentYear = new Date(today.getFullYear(), 0, 1)
//   return [
//     {
//       label: `Hoy (${today.toLocaleDateString()})`,
//       value: {
//         startDate: today.toISOString().split('T')[0],
//         endDate: today.toISOString().split('T')[0]
//       }
//     },
//     {
//       label: `Semana actual (${currentWeek.toLocaleDateString()} - ${today.toLocaleDateString()})`,
//       value: {
//         startDate: currentWeek.toISOString().split('T')[0],
//         endDate: today.toISOString().split('T')[0]
//       }
//     },
//     {
//       label: `Semana anterior (${lastWeek.toLocaleDateString()} - ${currentWeek.toLocaleDateString()})`,
//       value: {
//         startDate: lastWeek.toISOString().split('T')[0],
//         endDate: currentWeek.toISOString().split('T')[0]
//       }
//     },
//     {
//       label: `Este mes (${capitalizeFirstLetter(currentMonth.toLocaleString('es-Mx', { month: 'long' }))})`,
//       value: {
//         startDate: currentMonth.toISOString().split('T')[0],
//         endDate: today.toISOString().split('T')[0]
//       }
//     },
//     {
//       label: `Mes anterior (${capitalizeFirstLetter(lastMonth.toLocaleString('es-Mx', { month: 'long' }))})`,
//       value: {
//         startDate: lastMonth.toISOString().split('T')[0],
//         endDate: currentMonth.toISOString().split('T')[0]
//       }
//     },
//     {
//       label: `Este año (${today.getFullYear()})`,
//       value: {
//         startDate: currentYear.toISOString().split('T')[0],
//         endDate: today.toISOString().split('T')[0]
//       }
//     },
//     {
//       label: 'Personalizado',
//       value: {
//         startDate: '',
//         endDate: ''
//       }
//     }
//   ]
// }
// export const generatePeriodPickerOptions = () => {
//   const today = new Date()
//   const currentWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (today.getDay() === 0 ? 6 : today.getDay() - 1))
//   const currentWeekEnd = new Date(currentWeekStart)
//   currentWeekEnd.setDate(currentWeekStart.getDate() + 6)
//   const lastWeekStart = new Date(currentWeekStart)
//   lastWeekStart.setDate(currentWeekStart.getDate() - 7)
//   const lastWeekEnd = new Date(lastWeekStart)
//   lastWeekEnd.setDate(lastWeekStart.getDate() + 6)
//   const currentMonth = new Date(today.getFullYear(), today.getMonth(), 1)
//   const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
//   const currentYear = new Date(today.getFullYear(), 0, 1)
//   return [
//     {
//       label: `Hoy (${today.toLocaleDateString()})`,
//       value: {
//         startDate: today.toISOString().split('T')[0],
//         endDate: today.toISOString().split('T')[0]
//       }
//     },
//     {
//       label: `Semana actual (${currentWeekStart.toLocaleDateString()} - ${currentWeekEnd.toLocaleDateString()})`,
//       value: {
//         startDate: currentWeekStart.toISOString().split('T')[0],
//         endDate: currentWeekEnd.toISOString().split('T')[0]
//       }
//     },
//     {
//       label: `Semana anterior (${lastWeekStart.toLocaleDateString()} - ${lastWeekEnd.toLocaleDateString()})`,
//       value: {
//         startDate: lastWeekStart.toISOString().split('T')[0],
//         endDate: lastWeekEnd.toISOString().split('T')[0]
//       }
//     },
//     {
//       label: `Este mes (${capitalizeFirstLetter(currentMonth.toLocaleString('es-Mx', { month: 'long' }))})`,
//       value: {
//         startDate: currentMonth.toISOString().split('T')[0],
//         endDate: today.toISOString().split('T')[0]
//       }
//     },
//     {
//       label: `Mes anterior (${capitalizeFirstLetter(lastMonth.toLocaleString('es-Mx', { month: 'long' }))})`,
//       value: {
//         startDate: lastMonth.toISOString().split('T')[0],
//         endDate: currentMonth.toISOString().split('T')[0]
//       }
//     },
//     {
//       label: `Este año (${today.getFullYear()})`,
//       value: {
//         startDate: currentYear.toISOString().split('T')[0],
//         endDate: today.toISOString().split('T')[0]
//       }
//     },
//     {
//       label: 'Personalizado',
//       value: {
//         startDate: '',
//         endDate: ''
//       }
//     }
//   ]
// }
export const generatePeriodPickerOptions = () => {
  const today = DateTime.now()

  // Fecha de inicio y fin de la semana actual
  const currentWeekStart = today.startOf('week')
  const currentWeekEnd = today.endOf('week')

  // Fecha de inicio y fin de la semana anterior
  const lastWeekStart = currentWeekStart.minus({ weeks: 1 })
  const lastWeekEnd = currentWeekEnd.minus({ weeks: 1 })

  // Fecha de inicio del mes actual y del mes anterior
  const currentMonthStart = today.startOf('month')
  const lastMonthStart = currentMonthStart.minus({ months: 1 })
  const lastMonthEnd = currentMonthStart.minus({ days: 1 })

  // Fecha de inicio del año actual
  const currentYearStart = today.startOf('year')

  return [
    {
      label: `Hoy (${today.toLocaleString(DateTime.DATE_SHORT)})`,
      value: {
        startDate: today.toISODate(),
        endDate: today.toISODate()
      }
    },
    {
      label: `Semana actual (${currentWeekStart.toLocaleString(DateTime.DATE_SHORT)} - ${currentWeekEnd.toLocaleString(DateTime.DATE_SHORT)})`,
      value: {
        startDate: currentWeekStart.toISODate(),
        endDate: currentWeekEnd.toISODate()
      }
    },
    {
      label: `Semana anterior (${lastWeekStart.toLocaleString(DateTime.DATE_SHORT)} - ${lastWeekEnd.toLocaleString(DateTime.DATE_SHORT)})`,
      value: {
        startDate: lastWeekStart.toISODate(),
        endDate: lastWeekEnd.toISODate()
      }
    },
    {
      label: `Este mes (${capitalizeFirstLetter(currentMonthStart.toLocaleString({ month: 'long' }))})`,
      value: {
        startDate: currentMonthStart.toISODate(),
        endDate: today.toISODate()
      }
    },
    {
      label: `Mes anterior (${capitalizeFirstLetter(lastMonthStart.toLocaleString({ month: 'long' }))})`,
      value: {
        startDate: lastMonthStart.toISODate(),
        endDate: lastMonthEnd.toISODate()
      }
    },
    {
      label: `Este año (${today.year})`,
      value: {
        startDate: currentYearStart.toISODate(),
        endDate: today.toISODate()
      }
    },
    {
      label: 'Personalizado',
      value: {
        startDate: '',
        endDate: ''
      }
    }
  ]
}
/**
 * Obtiene el nombre del día de la semana de una fecha.
 * @param {string} date Fecha en ISO o JSDate
 * @param {boolean} capitalize Indica si la primera letra del día debe ser en mayúscula
 * @param {string} locale Idioma
 * @returns {string}
 */
export const getDayName = (date, capitalize = true, locale = 'es') => {
  if (typeof date === 'string') {
    date = DateTime.fromISO(date)
  } else {
    date = DateTime.fromJSDate(date)
  }
  const dayName = DateTime.fromISO(date).setLocale(locale).toFormat('cccc')
  return capitalize ? capitalizeFirstLetter(dayName) : dayName.toLowerCase()
}
