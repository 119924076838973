/* eslint-disable */
import React, { createContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AppRoutes } from '../consts/appRoutes.consts'
import { APP_FILTERS_MENU_ROUTES } from '../routers/routes/appFiltersMenuRoutes.router'
import { APP_MENU } from '../consts/appMenu.consts'

export const SideBarContextValues = createContext()
export default function SidebarContext ({ children }) {
  const location = useLocation()
  const [keyPath, setKeyPath] = useState(null)
  const [screenTitle, setScreenTitle] = useState(null)
  const [mobileTitle, setMobileTitle] = useState({
    name: 'Inicio',
    icon: 'pi pi-home',
    typeOfIcon: 'iconClass'
  })
  const [sidebarActive, setSidebarActive] = useState(true)
  const [bockOpenTheNormalMenu, setBockOpenTheNormalMenu] = useState(false)

  const defineRoute = (path) => {
    const pathSplited = path.split('/')
    const fistPart = pathSplited[1]
    const secondPart = pathSplited[2]

    const firstPath = `/${fistPart}`
    const secondPath = secondPart
      ? `/${fistPart}/${secondPart}`
      : `/${fistPart}`
    const checkFirstPath = Boolean(APP_FILTERS_MENU_ROUTES[firstPath])
    const checkSecondPath = Boolean(APP_FILTERS_MENU_ROUTES[secondPath])
    const check = checkSecondPath || checkFirstPath

    let pathToUse = null
    if (checkFirstPath) {
      pathToUse = firstPath
    } else if (checkSecondPath) {
      pathToUse = secondPath
    }
    return { pathToUse, check }
  }

  useEffect(() => {
    if (/* !keyPath && */ location.pathname /* && location.pathname !== AppRoutes.home.index || location.pathname !== AppRoutes.reporteIndividual.index */) {
      if (location.pathname.includes(AppRoutes.clientes.index)) {
        const pathSegments = location.pathname.split('/')
        let newPath = pathSegments
        if (pathSegments.length > 3) {
          newPath = pathSegments.slice(0, -1)
        }
        newPath = newPath.join('/')
        if (keyPath !== AppRoutes.clientes.index) {
          setKeyPath(newPath.trim())
        }
        return
      }
      const pathSegments = location.pathname.split('/')
      let newPath = pathSegments
      console.warn('Ruta: ', location.pathname, newPath, '/' + newPath[1])
      setKeyPath('/' + newPath[1])
    }
  }, [location])
  useEffect(() => {
    for (let i = 0; i < APP_MENU.length; i++) {
      if (APP_MENU[i].modules) {
        for (let j = 0; j < APP_MENU[i].modules.length; j++) {
          if (APP_MENU[i].modules[j].name === screenTitle) {
            if (APP_MENU[i].modules[j].icon) {
              setMobileTitle({
                name: APP_MENU[i].modules[j].name,
                icon: APP_MENU[i].modules[j].icon,
                typeOfIcon: 'icon'
              })
            } else if (APP_MENU[i].modules[j].iconClass) {
              setMobileTitle({
                name: APP_MENU[i].modules[j].name,
                icon: APP_MENU[i].modules[j].iconClass,
                typeOfIcon: 'iconClass'
              })
            } else if (APP_MENU[i].modules[j].iconSvg) {
              setMobileTitle({
                name: APP_MENU[i].modules[j].name,
                icon: APP_MENU[i].modules[j].iconSvg,
                typeOfIcon: 'iconSvg'
              })
            }
            return
          }
        }
      } else if (APP_MENU[i].name === screenTitle) {
        if (APP_MENU[i].icon) {
          setMobileTitle({
            name: APP_MENU[i].name,
            icon: APP_MENU[i].icon,
            typeOfIcon: 'icon'
          })
        } else if (APP_MENU[i].iconClass) {
          setMobileTitle({
            name: APP_MENU[i].name,
            icon: APP_MENU[i].iconClass,
            typeOfIcon: 'iconClass'
          })
        } else if (APP_MENU[i].iconSvg) {
          setMobileTitle({
            name: APP_MENU[i].name,
            icon: APP_MENU[i].iconSvg,
            typeOfIcon: 'iconSvg'
          })
        }
        return
      }
    }
  }, [screenTitle])

  return (
    <SideBarContextValues.Provider value={{
      keyPath,
      setKeyPath,
      screenTitle,
      setScreenTitle,
      mobileTitle,
      setMobileTitle,
      sidebarActive,
      setSidebarActive,
      bockOpenTheNormalMenu,
      setBockOpenTheNormalMenu
    }}>
      {children}
    </SideBarContextValues.Provider>
  )
}
