/* eslint-disable */
import React, { useState } from 'react'
import InputInplace from '../../../components/atoms/misc/InputInplace.atom'

const InputInplaceSandbox = () => {
  const [inplaceActive, setInplaceActive] = useState(false)
  const [description, setDescription] = useState('')
  const [time, setTime] = useState('')
  return (
    <div>
      <InputInplace
        closable
        active={inplaceActive}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        onBlur={() => {
          console.log('onBlur')
          setInplaceActive(false)
        }}
        placeholder='Descripcion'
      />
      <InputInplace
        value={time}
        onChange={(e) => setTime(e.target.value)}
        placeholder='00:00'
        type='number'
        className='max-w-5rem'
      />
    </div>
  )
}

export default InputInplaceSandbox
