/* eslint-disable */
import React, { forwardRef  } from 'react'
import { Dropdown } from 'primereact/dropdown'
import ClientChip from '../molecules/ClientChip.molecule'
import DropdownCustomFilterSearch from '../molecules/DropdownCustomFilterSearch.molecule'
import { formatDropdownProjects } from '../../utils/project.utils'
import {
  checkAllFields,
  fieldsDoesNotHaveErrors
} from '../../utils/validations.utils'
import Label from '../atoms/Label.atom'

const DropdownProjectFilter = forwardRef(({
  value,
  onChange,
  label = null,
  placeholder = 'Elige un proyecto',
  name = 'projectTagName',
  options,
  optionValue = 'projectID',
  optionLabel = 'projectName',
  error,
  badgeTemplate = true,
  onPressEnter,
  fullWidth = true,
  formValidations,
  setFormValidations,
  handleFormSubmit,
  ...props
}, ref) => {
  if (!options) return
  options = formatDropdownProjects(options)


  return (
    <div className='field m-0'>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Dropdown
        ref={ref}
        filter
        name={name}
        placeholder={placeholder}
        options={options}
        optionValue={optionValue}
        optionLabel={optionLabel}
        value={value}
        onChange={onChange}
        itemTemplate={(option) => {
          const currIndex = options.findIndex(o => o[optionValue] === option[optionValue])

          const { clientTagName, projectTagName } = options.find(o => o[optionValue] === option[optionValue])
          return (
            <div className='flex gap-2 align-items-center project-item' style={{
            }}>
              <div className='.selected-highlight' style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                zIndex: 0,
                left: 0,
                top: 0,
              }}></div>
              <ClientChip code={clientTagName} />
              <span className='flex-1 relative z-1'>{projectTagName}</span>
            </div>
          )
        }}
        valueTemplate={(option) => {
          const valueLabel = option?.projectTagName || placeholder
          return (
            badgeTemplate
              ? (
                <div className='flex gap-2 align-items-center'>
                  <ClientChip code={option?.clientTagName} labelClassname='my-1 text-sm' />
                  <span className='flex-1'>{valueLabel}</span>
                </div>
              )
              : valueLabel
          )
        }}
        emptyMessage={'No hay proyectos'}
        filterTemplate={(props) =>
          <DropdownCustomFilterSearch 
            props={props} 
            inputProps={{
              autoFocus: true,
              onKeyUp: (e) => {
                if (e.key === 'Enter') {
                  checkAllFields(formValidations, setFormValidations)
                  if (fieldsDoesNotHaveErrors(formValidations)) {
                    handleFormSubmit()
                    return
                  }
                }

                if (e.key === 'Enter' || e.key === 'Tab') {
                  onPressEnter(e)
                }

              },
              onKeyDown: (e) => {
                // Let the original Dropdown keyboard handler process arrow keys
                props.filterInputKeyDown(e)
              },
              className: fullWidth ? 'w-full' : 'w-9rem'
            }
          }
          />
        }
        filterBy='clientTagName,projectTagName'
      />
    </div>

  )
})

export default DropdownProjectFilter
