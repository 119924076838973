import React from 'react'
import { ConfigProvider } from 'antd'
import esES from 'antd/locale/es_ES'
import DatePicker from '../../../../components/atoms/DatePicker.atom'
import { DateTime } from 'luxon'
const { RangePicker } = DatePicker

const PeriodPicker = ({
  date,
  setDate
}) => {
  const onRangeChange = (dates) => {
    if (dates) {
      setDate({
        startDate: dates[0],
        endDate: dates[1]
      })
    }
  }
  const rangePresets = [
    {
      label: 'Hoy',
      value: [
        DateTime.local(),
        DateTime.local()
      ]
    },
    {
      label: 'Semana actual',
      value: [
        DateTime.local().startOf('week').set({ hour: 12 }),
        DateTime.local().endOf('week').set({ hour: 12 })
      ]
    },
    {
      label: 'Semana anterior',
      value: [
        DateTime.local().startOf('week').plus({ weeks: -1 }).set({ hour: 12 }),
        DateTime.local().endOf('week').plus({ weeks: -1 }).set({ hour: 12 })
      ]
    },
    {
      label: 'Este mes',
      value: [
        DateTime.local().startOf('month').set({ hour: 12 }),
        DateTime.local().endOf('month').set({ hour: 12 })
      ]
    },
    {
      label: 'Mes anterior',
      value: [
        DateTime.local().startOf('month').plus({ months: -1 }).set({ hour: 12 }),
        DateTime.local().endOf('month').plus({ months: -1 }).set({ hour: 12 })
      ]
    },
    {
      label: 'Este año ',
      value: [
        DateTime.local().startOf('year').set({ hour: 12 }),
        DateTime.local().endOf('year').set({ hour: 12 })
      ]
    }
  ]
  return (
    <div>
      <ConfigProvider locale={esES}>
        <RangePicker
          value={[
            DateTime.fromISO(date.startDate).set({ hour: 12 }),
            DateTime.fromISO(date.endDate).set({ hour: 12 })
          ]}
          presets={rangePresets}
          onChange={onRangeChange}
          suffixIcon={<i className='pi pi-calendar clr-secondary' />}
          separator={<i className='pi pi-arrow-right text-xs clr-secondary' />}
        />
      </ConfigProvider>
    </div>
  )
}

export default PeriodPicker
