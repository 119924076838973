import React, { useEffect, useRef, useState } from 'react'
import WeekHoursReportDataTable from './tables/WeekHoursReportDataTable/WeekHoursReportDataTable.controller'
import { generateWeekHoursReport } from '../../../services/report.service'
import ProgressSpinner from '../../../components/atoms/misc/ProgressSpinner.atom'
import WeekPicker from './components/WeekPicker'
import { getMondayOfCurrentWeek } from '../../../utils/date.utils'
import useReportFiltersContext from '../../../hooks/contextConsumers/useReportFiltersContext.hook'
import { DateTime } from 'luxon'
import ResetButton from './components/ResetButton'

const getFullWeekFromDate = (date) => {
  const weekStart = DateTime.fromJSDate(date).startOf('week')
  const weekEnd = weekStart.plus({ days: 6 }).endOf('day')
  return {
    weekStart: weekStart.toJSDate(),
    weekEnd: weekEnd.toJSDate()
  }
}

const WeekHoursReport = React.forwardRef((props, tableRef) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [reportData, setReportData] = useState([])
  const {
    weekHoursDateRange,
    setWeekHoursDateRange
  } = useReportFiltersContext()
  const [sortOrder, setSortOrder] = useState(1)
  const resetSortOrderRef = useRef(null)

  useEffect(() => {
    gettingWeekHoursReport()
  }, [weekHoursDateRange])

  const gettingWeekHoursReport = async () => {
    setError(null)
    setIsLoading(true)
    const fullWeek = getFullWeekFromDate(weekHoursDateRange)
    const response = await generateWeekHoursReport(
      DateTime.fromJSDate(fullWeek.weekStart).startOf('day').toISODate(),
      DateTime.fromJSDate(fullWeek.weekEnd).endOf('day').toISODate()
    )
    if (response.success) {
      setReportData(response.result)
    } else {
      setError(response.message)
    }
    setIsLoading(false)
  }

  return (
    <>
      <div style={{ marginBottom: 17.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <WeekPicker
          date={weekHoursDateRange}
          setDate={setWeekHoursDateRange}
        />
        <ResetButton
          onClick={() => {
            resetSortOrderRef.current()
            tableRef.current?.reset()
            setWeekHoursDateRange(getMondayOfCurrentWeek())
          }}
        />
      </div>
      {isLoading
        ? (
          <div className='w-full h-20rem flex align-items-center justify-content-center'>
            <ProgressSpinner />
          </div>
        )
        : error
          ? (
            <div>Error: {error}</div>
          )
          : (
            <WeekHoursReportDataTable
              ref={tableRef}
              resetSortOrderRef={resetSortOrderRef}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              reportData={reportData}
            />
          )}
    </>
  )
})

export default WeekHoursReport
