import React, { forwardRef } from 'react'
import ContextMenu from '../../../../components/atoms/ContextMenu.atom'
import { reactivateClient } from '../../../../services/client.service'
import { reactivateProject } from '../../../../services/project.service'

const ClientsTableContextMenu = forwardRef((props, ref) => {
  // console.log('props', props)
  const items = []
  // if (props.selectedRow?.data?.type === 'client') {
  //   items.push({
  //     label: 'Editar',
  //     icon: 'pi pi-pencil',
  //     command: () => {
  //       props.onEdit()
  //     }
  //   })
  // }
  if (props.selectedRow?.type === 'client') {
    items.push({
      label: 'Editar',
      icon: 'pi pi-pencil',
      command: () => {
        props.onEdit()
      }
    })
  }
  if (props.selectedRow?.data?.active) {
    items.push({
      label: 'Desactivar',
      icon: 'pi pi-ban',
      command: () => {
        props.setOpenModal(true)
        props.setModalAction('deactivate')
      }
    })
  } else if (props.selectedRow?.data?.isClientActive || props.selectedRow?.isClientActive || props.selectedRow?.type === 'client') {
    items.push({
      label: 'Activar',
      icon: 'pi pi-check',
      command: async () => {
        if (props.selectedRow?.type === 'client') {
          await reactivateClient(props.selectedRow?.id)
        } else if (props.selectedRow?.isClientActive) {
          await reactivateProject(props.selectedRow?.id)
        }
        props.setModalAction('reactivate')
      }
    })
  }
  if (props.selectedRow?.data?.isDeletable) {
    items.push({
      label: 'Eliminar',
      icon: 'pi pi-trash',
      command: () => {
        props.setOpenModal(true)
        props.setModalAction('delete')
      }
    })
  }

  return (
    <ContextMenu
      ref={ref}
      model={items}
    />
  )
})

export default ClientsTableContextMenu
