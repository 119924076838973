import React, { useState } from 'react'
import { checkAllFields, fieldsDoesNotHaveErrors, isNotEmpty, validateFieldValue } from '../../../utils/validations.utils'
import ModalForm from '../../../components/compounds/modalForm/ModalForm.compunds'
import FormContainer from '../../../components/layouts/containers/FormContainer.container'
import FieldContainer from '../../../components/layouts/containers/FieldContainer.container'
import InputPasswordForm from '../../../components/molecules/InputPasswordForm.molecule'
import useToast from '../../../hooks/useToast.hook'
import { useNavigate } from 'react-router-dom'
import { INVALID_PASSWORD } from '../../../consts/firebaseErrorResponses.consts'
import { toastMessages } from '../../../consts/common.const'
import { createToastConfig } from '../../../utils/toast.utils'
import { changePassword } from '../../../services/usuario.service'
import { AppRoutes } from '../../../consts/appRoutes.consts'

const defaultPasswordState = ''

const initialStateValidations = {
  password: { errors: [], value: '', typeValidations: [isNotEmpty] }
}

const FormModalChangePasswordUser = ({ uid, visible, setVisible }) => {
  const [passwordState, setPasswordState] = useState(defaultPasswordState)
  const [validationsStatePassword, setValidationsStatePassword] = useState({
    password: { ...initialStateValidations.password }
  })

  const toast = useToast()
  const navigate = useNavigate()

  const cleanTheModalState = () => {
    setPasswordState(defaultPasswordState)
    setValidationsStatePassword({
      password: { ...initialStateValidations.password }
    })
  }
  const handleChange = (e) => {
    const name = e.target?.name || e.originalEvent?.target?.name
    const defaultValue = ''
    const value = e?.value || e.target?.value || defaultValue
    let newValidations = validationsStatePassword
    if (name === 'password') {
      const errors = validationsStatePassword.password.errors.filter(error => error.idError !== 6)
      const newValidationsWithoutPasswordMessage = {
        ...validationsStatePassword,
        password: {
          ...validationsStatePassword.password,
          errors
        }
      }
      newValidations = newValidationsWithoutPasswordMessage
    }
    setValidationsStatePassword(validateFieldValue(newValidations, name, value))
    setPasswordState(value)
  }
  const handleVisible = (newValue) => {
    setVisible(newValue)
    if (!newValue) cleanTheModalState()
  }
  const handleSave = async () => {
    checkAllFields(validationsStatePassword, setValidationsStatePassword)
    if (
      !fieldsDoesNotHaveErrors(validationsStatePassword) || !uid
    ) {
      return
    }
    const changePasswordData = {
      uid,
      newPassword: passwordState
    }

    const serviceResponse = await changePassword(changePasswordData)
    if (!serviceResponse.success) {
      if (serviceResponse.message === INVALID_PASSWORD) {
        return setValidationsStatePassword({
          password: {
            ...validationsStatePassword.password,
            errors: [{ idError: 6, errorMessage: 'Esta contraseña es invalida' }]
          }
        })
      } else {
        const failToSaveMessage = createToastConfig(toastMessages.failToSave)
        return toast.setMessage(...failToSaveMessage)
      }
    }

    setVisible(false)
    const successMessage = createToastConfig(toastMessages.successToSave)
    toast.setMessage(...successMessage)
    navigate(AppRoutes.usuarios.index)
    cleanTheModalState()
  }
  const header = 'Cambiar contraseña'
  return (
    <ModalForm
      onSave = {handleSave}
      header={header}
      visible={visible}
      setVisible={handleVisible}
    >
      <FormContainer>
        <FieldContainer>
          <InputPasswordForm
            name="password"
            label="Contraseña"
            placeholder="Ingrese una contraseña"
            onChange={handleChange}
            value={passwordState}
            error={validationsStatePassword.password.errors}
          />
        </FieldContainer>
      </FormContainer>
    </ModalForm>
  )
}

export default FormModalChangePasswordUser
