import React, { useLayoutEffect, useRef, useState } from 'react'
import DataTable from '../../../../../components/atoms/table/DataTable.atom'
import Column from '../../../../../components/atoms/table/Column.atom'
import ClientChip from '../../../../../components/molecules/ClientChip.molecule'
import MultiSelect from '../../../../../components/atoms/MultiSelect.atom'
import { createRoot } from 'react-dom/client'
import ClientColumnContent from './ClientColumnContent.controller'
import { groupFooterTemplate, groupHeaderTemplate, StickyFooter } from './ClientGroupTemplates.controller'
import { arraysContainSameStrings } from '../../../../../utils/arrays.utils'
import useIsHoldingCtrlDown from '../../../../../hooks/useIsHoldingCtrlDown'
import useRegisteredTasksDynamicHeight from '../../../../../hooks/useDynamicHeight.hook'

const RegisteredClientTaskTable = React.forwardRef(({
  setSelectedTask,
  data,
  cm,
  period,
  updatingTask
}, ref) => {
  if (!data) return null
  const {
    tasks,
    totals
  } = data
  const [filteredTasks, setFilteredTasks] = useState(tasks || [])
  const [totalsByDay, setTotalsByDay] = useState([])
  const [hasMounted, setHasMounted] = useState(false)
  const fullHeight = useRegisteredTasksDynamicHeight()

  const taskTeamMembers = [...new Map(tasks?.map(item => {
    return [item.teamMemberNameTag, { label: item.teamMemberNameTag, value: item.teamMemberNameTag }]
  })).values()]
  const modules = [...new Map(tasks?.map(item => {
    return [item.module, { label: item.module, value: item.moduleId }]
  })).values()]
  let hasSpecialCategories = false
  const categories = [...new Map(tasks?.map(item => {
    if (item?.specialCategoryId) {
      hasSpecialCategories = true
      return [item.specialCategoryId, { label: item.specialCategoryTitle, value: item.specialCategoryId }]
    }
    return [item.categoryId, { label: item.categoryTitle, value: item.categoryId }]
  })).values()]

  const categoryFilterOptionsRef = useRef(null)

  const categoryFilterTemplate = (options) => {
    categoryFilterOptionsRef.current = options

    return (
      <MultiSelect
        value={options.value}
        options={categories}
        onChange={(e) => {
          options.filterCallback(e.value)
        }}
        placeholder='Filtrar categoría'
        emptyFilterMessage='Misión fallida... no encontramos nada'
      />
    )
  }

  const moduleFilterOptionsRef = useRef(null)

  const moduleFilterTemplate = (options) => {
    moduleFilterOptionsRef.current = options
    if (modules.some(m => m.value === null)) {
      modules.find(m => m.value === null).label = 'Sin módulo'
    }
    return (
      <MultiSelect
        value={options.value}
        options={modules}
        onChange={(e) => {
          options.filterCallback(e.value)
        }}
        placeholder='Filtrar módulo'
        emptyFilterMessage='Misión fallida... no encontramos nada'
      />
    )
  }

  const teamMemberFilterOptionsRef = useRef(null)

  const teamMemberFilterTemplate = (options) => {
    teamMemberFilterOptionsRef.current = options
    return (
      <MultiSelect
        value={options.value}
        options={taskTeamMembers}
        onChange={(e) => {
          options.filterCallback(e.value)
        }}
        placeholder='Filtrar integrante'
        emptyFilterMessage='Misión fallida... no encontramos nada'
      />
    )
  }

  const clientFilterOptionsRef = useRef(null)

  const clientFilterTemplate = (options) => {
    clientFilterOptionsRef.current = options
    const projects = []
    tasks?.forEach((item, index) => {
      let project = projects.find(project => project.name === item.clientName)
      if (!project) {
        project = {
          name: item.clientName,
          code: item.clientCode,
          items: []
        }
        projects.push(project)
      }
      let projectItem = project.items.find(project => project.name === item.projectName)
      if (!projectItem) {
        projectItem = {
          name: item.projectName,
          code: item.projectCode
        }
        project.items.push(projectItem)
      }
    })
    return (
      <MultiSelect
        value={options.value}
        options={projects}
        onChange={(e) => {
          options.filterCallback(e.value)
        }}
        optionLabel='name'
        optionValue='name'
        placeholder='Todos'
        optionGroupLabel='name'
        optionGroupChildren='items'
        optionGroupTemplate={(option) => {
          return (
            <ClientChip code={option.code} className='w-fit' />
          )
        }}
        emptyFilterMessage='Misión fallida... no encontramos nada'
      />
    )
  }

  useLayoutEffect(() => {
    if (!hasMounted || filteredTasks?.length) {
      const dataTable = document.querySelector('.p-datatable-table')
      const footerDiv = document.createElement('table')
      footerDiv.className = 'custom-datatable-footer'
      dataTable.appendChild(footerDiv)
      createRoot(footerDiv).render(<StickyFooter tasks={filteredTasks} totals={totals} />)
      setHasMounted(true)

      const dailyTotals = calculateTotalsByDay(filteredTasks)
      setTotalsByDay(dailyTotals)

      return () => {
        const dataTable = document.querySelector('.p-datatable-table')
        const footerDiv = document.querySelector('.custom-datatable-footer')
        if (footerDiv) {
          dataTable.removeChild(footerDiv)
        }
      }
    }
  }, [hasMounted, filteredTasks])

  const calculateTotalsByDay = (tasks) => {
    return tasks.reduce((acc, task) => {
      const day = new Date(task.date).toISOString().split('T')[0]
      if (!acc[day]) {
        acc[day] = {
          sisuTime: 0,
          billedTime: 0,
          timeDifference: 0
        }
      }
      acc[day].sisuTime += task.sisuTime
      acc[day].billedTime += task.billedTime
      acc[day].timeDifference += Math.abs(task.timeDifference)
      return acc
    }, {})
  }

  const isHoldingCtrlDown = useIsHoldingCtrlDown()

  return (
    <>
      <DataTable
        ref={ref}
        className='individual-report-table'
        value={tasks}
        rowGroupMode='subheader'
        groupRowsBy='date'
        rowGroupHeaderTemplate={groupHeaderTemplate}
        rowGroupFooterTemplate={(rowData) => groupFooterTemplate(rowData, totalsByDay, period)}
        removableSort
        scrollable
        scrollHeight={`${fullHeight}px`}
        onValueChange={filteredData => setFilteredTasks(filteredData)}
        onContextMenu={({ originalEvent, data }) => {
          cm?.current.show(originalEvent)
          setSelectedTask(data)
        }}
        emptyMessageTitle='Nada por aquí. Todavía...'
        emptyMessageContent='Selecciona un cliente para ver sus tareas'
      >
        {ClientColumnContent(
          clientFilterTemplate,
          teamMemberFilterTemplate,
          moduleFilterTemplate,
          categoryFilterTemplate,
          cm,
          setSelectedTask,
          isHoldingCtrlDown,
          (teamMemberCode) => {
            const currValue = teamMemberFilterOptionsRef.current.value
            if (currValue?.length === 1) {
              teamMemberFilterOptionsRef.current.filterApplyCallback([])
            } else {
              teamMemberFilterOptionsRef.current.filterApplyCallback([teamMemberCode])
            }
          },
          (moduleId) => {
            const currValue = moduleFilterOptionsRef.current.value
            if (currValue?.length === 1) {
              moduleFilterOptionsRef.current.filterApplyCallback([])
            } else {
              moduleFilterOptionsRef.current.filterApplyCallback([moduleId])
            }
          },
          (categoryId) => {
            const currValue = categoryFilterOptionsRef.current.value
            if (currValue?.length === 1) {
              categoryFilterOptionsRef.current.filterApplyCallback([])
            } else {
              categoryFilterOptionsRef.current.filterApplyCallback([categoryId])
            }
          },
          (projectName) => {
            const currValue = clientFilterOptionsRef.current.value
            if (currValue?.length === 1) {
              clientFilterOptionsRef.current.filterApplyCallback([])
            } else {
              clientFilterOptionsRef.current.filterApplyCallback([projectName])
            }
          },
          (clientCode) => {
            const currValues = clientFilterOptionsRef.current.value
            const filteredClients = Array.from(new Set(tasks.filter(task => task.clientCode === clientCode).map(client => client.projectName)))
            if (currValues && arraysContainSameStrings(currValues, filteredClients)) {
              clientFilterOptionsRef.current.filterApplyCallback([])
            } else {
              clientFilterOptionsRef.current.filterApplyCallback(filteredClients)
            }
          },
          updatingTask,
          hasSpecialCategories
        )?.map((column, index) => (
          <Column
            key={index}
            {...column}
          />
        ))}
      </DataTable>
    </>
  )
})

export default RegisteredClientTaskTable
