import React, { useState, useContext, useEffect } from 'react'
import FormBasicInfoUsuarios from './FormBasicInfoUsuarios.controller'
import UsersOptionsContext from '../../../contexts/data/UsersOptions.context'
import { createUsuario, getUsuariosById, updateUsuario } from '../../../services/usuario.service'
import { useNavigate, useParams } from 'react-router-dom'
import { createToastConfig } from '../../../utils/toast.utils'
import { toastMessages } from '../../../consts/common.const'
import useToast from '../../../hooks/useToast.hook'
import FormEmailsNotificacionEmbarques from './FormEmailsNotificacionEmbarques.controller'
import { checkAllFields, fieldsDoesNotHaveErrors, isNotEmpty, isAnEmail } from '../../../utils/validations.utils'
import { ROLE_NAMES, USER_PERMISSIONS } from '../../../consts/modules/usuarios.consts'
import FormUploadFirmaDeUsuario from './FormUploadFirmaDeUsuario.controller'
import FormFooterUserActions from './FormFooterUserActions.controller'
import { EMAIL_ALREADY_EXISTS, INVALID_PASSWORD } from '../../../consts/firebaseErrorResponses.consts'
import { AppRoutes } from '../../../consts/appRoutes.consts'
import ButtonRowContainer from '../../../components/layouts/containers/ButtonRowContainer.container'
import PrimaryButton from '../../../components/molecules/buttons/PrimaryButton.molecule'

const { AGENTE, AGENTE_ADUANAL, GROWER, TRANSPORTISTA } = ROLE_NAMES

const FormSectionUsuarios = () => {
  const [basicInfoFormState, setBasicInfoFormState] = useState({
    nombre: '',
    rol: null,
    departamento: null,
    email: '',
    password: '',
    locacion: null,
    nacionalidad: null,
    lineaTransportista: null,
    correosDeNotificacionEmbarques: []
  })

  const initialFieldsState = {
    nombre: { errors: [], value: null, typeValidations: [isNotEmpty] },
    rol: { errors: [], value: null, typeValidations: [isNotEmpty] },
    departamento: { errors: [], value: null, typeValidations: [isNotEmpty] },
    email: { errors: [], value: null, typeValidations: [isNotEmpty, isAnEmail] },
    password: { errors: [], value: null, typeValidations: [isNotEmpty] },
    locacion: { errors: [], value: null, typeValidations: [isNotEmpty] },
    nacionalidad: { errors: [], value: null, typeValidations: [isNotEmpty] },
    firma: { errors: [], value: null, typeValidations: [isNotEmpty] },
    lineaTransportista: { errors: [], value: null, typeValidations: [isNotEmpty] }
  }

  const [validations, setValidations] = useState(initialFieldsState)
  const [correosNotifiacionValidations, setCorreosNotifiacionValidations] = useState({})
  const [firmaFile, setFirmaFile] = useState(null)
  const [isInUserEditionMode, setIsInUserEditionMode] = useState(false)
  const [userDataSelected, setUserDataSelected] = useState(null)
  const navigate = useNavigate()
  const params = useParams()
  const toast = useToast()

  const { usersOptions } = useContext(UsersOptionsContext)

  useEffect(() => {
    const newState = {
      ...basicInfoFormState,
      locacion: basicInfoFormState.rol?.nombre !== AGENTE ? null : basicInfoFormState.locacion,
      nacionalidad: basicInfoFormState.rol?.nombre !== AGENTE_ADUANAL ? null : basicInfoFormState.nacionalidad,
      lineaTransportista: basicInfoFormState.rol?.nombre !== TRANSPORTISTA ? null : basicInfoFormState.lineaTransportista
    }
    if (basicInfoFormState.rol?.nombre !== GROWER) setFirmaFile(null)
    setBasicInfoFormState(newState)
  }, [basicInfoFormState.rol])

  const fillingUserData = async () => {
    const responseGetUser = await getUsuariosById(params.userId)
    if (!responseGetUser.success) {
      const dataDoesNotLoadMessage = createToastConfig(toastMessages.dataDoesNotLoad)
      return toast.setMessage(...dataDoesNotLoadMessage)
    }
    const userData = responseGetUser.result

    const correosDeNotificacionEmbarques = userData.correosDeNotificacionEmbarques.map((correoData, index) => ({
      id: correoData.id,
      optionLabel: `Correo ${index + 1}`,
      optionValue: `correo${index + 1}`,
      value: correoData.correo
    }))
    const embarquesEmailsValidations = userData.correosDeNotificacionEmbarques.reduce((acc, correoData, index) => {
      acc[`correo${index + 1}`] = {
        optionValue: `correo${index + 1}`,
        errors: [],
        value: correoData.correo,
        typeValidations: [isNotEmpty, isAnEmail]
      }
      return acc
    }, {})
    const departamentoData = usersOptions?.departamentos.find(depa => depa.id === userData.departamentoId)
    const rolData = usersOptions?.roles.find(rol => rol.id === userData.rolId)
    const locacionData = usersOptions?.locaciones.find(locacion => locacion.id === userData.locacionId)
    const nacionalidadData = usersOptions?.nacionalidades.find(nacionalidad => nacionalidad.id === userData.nacionalidadId)
    const lineaTransportistaData = usersOptions?.lineasTransportista.find(
      linTrans => linTrans.id === userData.lineaTransportistaId
    )
    setCorreosNotifiacionValidations(embarquesEmailsValidations)
    setUserDataSelected(userData)
    setBasicInfoFormState({
      nombre: userData.nombre,
      rol: rolData || null,
      departamento: departamentoData || null,
      email: userData.auth?.email || '',
      password: '',
      locacion: locacionData || null,
      nacionalidad: nacionalidadData || null,
      lineaTransportista: lineaTransportistaData || null,
      correosDeNotificacionEmbarques
    })
    setValidations({
      nombre: { ...initialFieldsState.nombre, value: userData.nombre },
      rol: { ...initialFieldsState.rol, value: rolData || null },
      departamento: { ...initialFieldsState.departamento, value: departamentoData || null },
      email: { ...initialFieldsState.email, value: userData.auth?.email || '' },
      password: { ...initialFieldsState.password, value: '' },
      locacion: { ...initialFieldsState.locacion, value: locacionData || null },
      nacionalidad: { ...initialFieldsState.locacion, value: nacionalidadData || null },
      lineaTransportista: { ...initialFieldsState.locacion, value: lineaTransportistaData || null },
      firma: { ...initialFieldsState.locacion, value: userData.firmaUrl || null }
    })
  }
  useEffect(() => {
    if (params.userId && usersOptions) {
      setIsInUserEditionMode(true)
      fillingUserData()
    }
  }, [usersOptions])

  const handleSave = async () => {
    const escapeSpecificFields = []
    if (isInUserEditionMode) escapeSpecificFields.push('password')
    if (basicInfoFormState.rol?.nombre !== AGENTE) escapeSpecificFields.push('locacion')
    if (basicInfoFormState.rol?.nombre !== AGENTE_ADUANAL) escapeSpecificFields.push('nacionalidad')
    if (basicInfoFormState.rol?.nombre !== TRANSPORTISTA) escapeSpecificFields.push('lineaTransportista')
    if (basicInfoFormState.rol?.nombre !== GROWER) escapeSpecificFields.push('firma')

    checkAllFields(validations, setValidations, { escapeSpecificFields })
    checkAllFields(correosNotifiacionValidations, setCorreosNotifiacionValidations)
    if (
      !fieldsDoesNotHaveErrors(validations) ||
      !fieldsDoesNotHaveErrors(correosNotifiacionValidations)
    ) return

    const shouldSendfirma = basicInfoFormState.rol?.nombre === GROWER
    if (shouldSendfirma) {
      if (!firmaFile && !userDataSelected?.firmaUrl) {
        const failIncompleteInformationMessage = createToastConfig(toastMessages.failIncompleteInformation)
        return toast.setMessage(...failIncompleteInformationMessage)
      }
    }
    const correosDeNotificacionEmbarquesToSave = basicInfoFormState.correosDeNotificacionEmbarques.map((data) => {
      return { correo: data.value, id: data.id || null }
    })
    const formUsuarioData = new FormData()
    formUsuarioData.append('nombre', basicInfoFormState.nombre.trim())
    formUsuarioData.append('firmaUrl', userDataSelected?.firmaUrl || null)
    formUsuarioData.append('rolId', basicInfoFormState.rol.id)
    formUsuarioData.append('departamentoId', basicInfoFormState.departamento.id)
    formUsuarioData.append('email', basicInfoFormState.email.trim())
    formUsuarioData.append('locacionId', basicInfoFormState?.locacion?.id || null)
    formUsuarioData.append('nacionalidadId', basicInfoFormState?.nacionalidad?.id || null)
    formUsuarioData.append('lineaTransportistaId', basicInfoFormState?.lineaTransportista?.id || null)
    formUsuarioData.append('correosDeNotificacionEmbarques', JSON.stringify(correosDeNotificacionEmbarquesToSave))
    formUsuarioData.append('firma', firmaFile)

    const failMessage = createToastConfig(toastMessages.failToSave)
    let serviceResponse
    try {
      if (isInUserEditionMode) {
        formUsuarioData.append('id', userDataSelected.id)
        formUsuarioData.append('uid', userDataSelected.uid)
        serviceResponse = await updateUsuario(formUsuarioData)
      } else {
        formUsuarioData.append('password', basicInfoFormState.password.trim())
        serviceResponse = await createUsuario(formUsuarioData)
      }
    } catch (error) {
      return toast.setMessage(...failMessage)
    }

    if (!serviceResponse.success) {
      if (serviceResponse.message === EMAIL_ALREADY_EXISTS) {
        setValidations({
          ...validations,
          email: {
            ...validations.email,
            errors: [{ idError: 5, errorMessage: 'Este correo ya existe' }]
          }
        })
        return
      } else if (serviceResponse.message === INVALID_PASSWORD) {
        setValidations({
          ...validations,
          password: {
            ...validations.password,
            errors: [{ idError: 6, errorMessage: 'Esta contraseña es invalida' }]
          }
        })
        return
      } else {
        return toast.setMessage(...failMessage)
      }
    }
    const successMessage = createToastConfig(toastMessages.successToSave)
    toast.setMessage(...successMessage)
    navigate(AppRoutes.usuarios.index)
  }
  let isRolAgenteAduanal = false
  let isRolGrower = false

  if (basicInfoFormState?.rol) {
    isRolAgenteAduanal = basicInfoFormState?.rol.nombre === AGENTE_ADUANAL
    isRolGrower = basicInfoFormState?.rol.nombre === GROWER
  }
  let hasPermissions = false
  const isDisable = userDataSelected?.deshabilitado
  if (userDataSelected && usersOptions) {
    const permisoDeUsuarioData = usersOptions.permisosDeEdicion.find(
      permiso => permiso.id === userDataSelected.permisoDeEdicionId
    )
    hasPermissions = permisoDeUsuarioData?.nombre === USER_PERMISSIONS.UNLIMITED
  }
  return (
    <>
      <FormBasicInfoUsuarios
        isInUserEditionMode={isInUserEditionMode}
        basicInfoFormState={basicInfoFormState}
        setBasicInfoFormState={setBasicInfoFormState}
        validations={validations}
        setValidations={setValidations}
        usersOptions={usersOptions}
      />
      {
        isRolAgenteAduanal &&
          <FormEmailsNotificacionEmbarques
            basicInfoFormState={basicInfoFormState}
            setBasicInfoFormState={setBasicInfoFormState}
            correosNotifiacionValidations={correosNotifiacionValidations}
            setCorreosNotifiacionValidations={setCorreosNotifiacionValidations}
          />
      }
      {
        isRolGrower &&
          <FormUploadFirmaDeUsuario
            userDataSelected={userDataSelected}
            setUserDataSelected={setUserDataSelected}
            validations={validations}
            setValidations={setValidations}
            firmaFile={firmaFile}
            setFirmaFile={setFirmaFile}
            error={validations.firma.errors}
          />
      }
      {
        isInUserEditionMode && <FormFooterUserActions
          hasPermissions={hasPermissions}
          isDisable={isDisable}
          userId={userDataSelected?.id}
          uid={userDataSelected?.uid}
          onSave={handleSave}
        />
      }
      {
        isInUserEditionMode ||
        <ButtonRowContainer>
          <PrimaryButton label='Crear' onClick={handleSave}/>
        </ButtonRowContainer>
      }

    </>
  )
}

export default FormSectionUsuarios
