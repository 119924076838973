import React from 'react'
import { capitalizeFirstLetter } from '../../../../../utils/strings.utils'
import { ErrorBadge } from '../../../../../components/molecules/CellReportBadges.molecule'
import { DateTime } from 'luxon'

export const groupHeaderTemplate = (data) => {
  let dayName = DateTime.fromISO(data.date?.split('T')[0]).setLocale('es').startOf('day').toFormat('cccc')
  dayName = capitalizeFirstLetter(dayName)
  const dayRestOfDay = DateTime.fromISO(data.date?.split('T')[0]).setLocale('es').startOf('day').toFormat("dd 'de' MMMM 'del' yyyy")
  return (
    <div className='flex align-items-center pl-2'>
      <i className='pi pi-calendar' style={{ marginRight: '10px' }}></i>
      {/* <span><strong>{dayName}</strong> {data.date.split('T')[0].replace(/-/g, '/')}</span> */}
      <span><strong>{dayName}</strong> {dayRestOfDay}</span>
    </div>
  )
}

export const groupFooterTemplate = (rowData, totalsByDay, period) => {
  if (period === 'diario') return
  const day = new Date(rowData.date).toISOString().split('T')[0]

  return (
    <>
      <td
        className=''
        role='cell'
        style={{
          width: '100px',
          minWidth: '100px',
          padding: '0.5rem'
        }}
      >
        <div
          className='flex gap-2 align-items-center'
          style={{
            whiteSpace: 'nowrap'
          }}
        >
          <strong>Totales del día</strong>
        </div>
      </td>
      <td
        style={{
          width: '100px',
          minWidth: '100px',
          padding: '0.5rem'
        }}
      ></td>
      <td
        className='white-space-nowrap overflow-hidden text-overflow-ellipsis'
        role='cell'
        style={{
          width: '122px',
          minWidth: '122px',
          padding: '0.5rem'
        }}
      >
        <div className='overflow-hidden white-space-nowrap text-overflow-ellipsis'></div>
      </td>
      <td
        className=''
        role='cell'
        style={{
          width: '360px',
          minWidth: '360px',
          padding: '0.5rem'
        }}
      >
        <div className='w-full flex justify-content-between align-items-center'></div>
      </td>
      <td
        className=''
        role='cell'
        style={{
          width: 120,
          minWidth: 106,
          padding: '0.5rem'
        }}
      >
        <strong>
          {(totalsByDay?.[day]?.sisuTime)?.toFixed(2).replace(/[.,]00$/, '')}
        </strong>
      </td>
      <td
        className=''
        role='cell'
        style={{
          width: '120px',
          minWidth: '106px',
          padding: '0.5rem'
        }}
      >
        <strong>
          {(totalsByDay?.[day]?.billedTime)?.toFixed(2).replace(/[.,]00$/, '')}
        </strong>
      </td>
      <td
        className=''
        role='cell'
        style={{
          paddingLeft: '7px'
        }}
      >
        <div className='flex gap-1'>
          <strong>
            {(totalsByDay?.[day]?.timeDifference)?.toFixed(2).replace(/[.,]00$/, '')}
          </strong>
        </div>
      </td>
      <td
        className=''
        role='cell'
        style={{
          maxWidth: '30px',
          padding: '0.5rem'
        }}
      >
      </td>
    </>
  )
}

export const StickyFooter = ({
  tasks,
  totals
}) => {
  totals = tasks?.reduce((acc, task) => {
    acc.sisuTime += task.sisuTime
    acc.billedTime += task.billedTime
    acc.timeDifference += task.timeDifference
    return acc
  }, {
    sisuTime: 0,
    billedTime: 0,
    timeDifference: 0
  })
  return (
    <>
      <tbody className='p-datatable-tbody'>
        <tr
          role='row'
          style={{
            height: 38,
            background: '#f3f7fc'
          }}
        >
          <td
            className=''
            role='cell'
            style={{ width: '100px', minWidth: '100px', padding: '0.5rem' }}
          >
            <span className='p-column-title'>Proyecto</span>
            <div className='flex gap-2 align-items-center'>
              <strong>Total</strong>
            </div>
          </td>
          <td
            className=''
            role='cell'
            style={{ width: '100px', minWidth: '100px', padding: '0.5rem' }}
          ></td>
          <td
            className='white-space-nowrap overflow-hidden text-overflow-ellipsis'
            role='cell'
            style={{ width: '122px', minWidth: '122px', padding: '0.5rem' }}
          >
            <span className='p-column-title'>Categoría</span>
            <div className='overflow-hidden white-space-nowrap text-overflow-ellipsis'></div>
          </td>
          <td
            className=''
            role='cell'
            style={{ width: '360px', minWidth: '360px', padding: '0.5rem' }}
          >
            <span className='p-column-title'>Tarea</span>
          </td>
          <td
            className=''
            role='cell'
            style={{ width: '120px', minWidth: '106px', padding: '0.5rem' }}
          >
            <strong>
              {(totals?.sisuTime)?.toFixed(2).replace(/[.,]00$/, '')}
            </strong>
          </td>
          <td
            className=''
            role='cell'
            style={{ width: '120px', minWidth: '106px', padding: '0.5rem' }}
          >
            <strong>
              {(totals?.billedTime)?.toFixed(2).replace(/[.,]00$/, '')}
            </strong>
          </td>
          <td className='' role='cell' style={{ paddingLeft: '7px' }}>
            <strong className='flex gap-1'>
              {(totals?.timeDifference)?.toFixed(2).replace(/[.,]00$/, '')}
              {totals?.timeDifference
                ? (
                  <ErrorBadge tooltipValue='Las horas facturadas no coinciden con las horas trabajadas' />
                )
                : null}
            </strong>
          </td>
          <td
            className=''
            role='cell'
            style={{
              maxWidth: '30px',
              padding: '0.5rem',
              display: 'flex',
              justifyContent: 'center'
            }}
          ></td>
        </tr>
      </tbody>
    </>
  )
}
