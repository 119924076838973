import React from 'react'
import { AuthorizedRoutes } from './AuthorizedRoutes.router'
import { UnauthorizedRoutes } from './UnauthorizedRoutes.router'
import { useSession } from '../hooks/auth/useSession.hook'

export function MainRouter () {
  const { user } = useSession()
  if (user === undefined) {
    return null
  }
  return user === null ? <UnauthorizedRoutes /> : <AuthorizedRoutes />
}
