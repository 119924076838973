/* eslint-disable */
export const replaceDashesForSpaces = (string) => {
  return string.split('-').join(' ')
}

export const capitalizeFirstLetter = (text = '') => {
  const textTrimed = text.trim()
  const fistLetter = textTrimed[0]
  const restOfTheText = textTrimed.slice(1, textTrimed.length)
  return `${fistLetter.toUpperCase()}${restOfTheText.toLowerCase()}`
}
/* Obtiene la primera letra de un string en mayúscula. */
export const getFirstLetterCapitalized = (string) => {
  if (!string) return null
  return string[0].toUpperCase()
}
/* Genera id de semana del nombre de semana ingresado. "24 - 2023" => "2423" */
export const generateIdByNombreSemana = (nombreSemana) => {
  if (!nombreSemana) return null
  const fistNameLength = nombreSemana.length
  const fistWeek = `${nombreSemana[0]}${nombreSemana[1]}`
  const fistYear = `${nombreSemana[fistNameLength - 2]}${nombreSemana[fistNameLength - 1]}`
  return fistWeek + fistYear
}

export const getSubstringBetween = (delimiterStart, delimiterEnd, string) => {
  return string.substring(string.indexOf(delimiterStart) + 1, string.indexOf(delimiterEnd))
}
/* Formatea algún valor agregando m². (INT 3) => (STRING "3 m²") */
export const formatM2Suffix = (value) => `${value} m²`
export const formantKgM2Suffix = (value) => `${value} kg/m²`

export const formatPercentageSuffix = (number) => {
  const options = {
    style: 'percent',
    maximumFractionDigits: 0
  }
  const numberFormat = new Intl.NumberFormat('en-US', options)
  return numberFormat.format(number)
}

export const generateRandomId = (length = 17) => {
  const timeId = new Date().getTime().toString(36)
  const mathRandomId = Math.random().toString(36).slice(2)
  const randomId = timeId + mathRandomId
  const randomIdArray = randomId.split('')
  randomIdArray.length = length
  return randomIdArray.join('')
}

export const sanitizeString = (string) => {
  if (!string || typeof string !== 'string') return ''
  return string.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s/g, '')
    .toLowerCase()
}

export const pluralize = (string, quantity) => {
  const vowels = ['a', 'e', 'i', 'o', 'u']
  const specialUnits = ['kg']
  if (!string || string?.length === 0 || isNaN(Number(quantity))) return ''
  if (specialUnits.includes(sanitizeString(string)) || quantity <= 1) return string
  const lastCharacter = sanitizeString(string).charAt(sanitizeString(string).length - 1)
  if (vowels.includes(lastCharacter) && Number(quantity) > 1) return `${sanitizeString(string)}s`
  if (!isNaN(Number(lastCharacter))) return sanitizeString(string)
  if (!vowels.includes(lastCharacter) && Number(quantity) > 1) return `${sanitizeString(string)}es`
}
export const textIncludes = (text = '', string = '') => {
  if (text == null || string == null) return false
  const _text = sanitizeString(text)
  const _string = sanitizeString(string)
  return _text.includes(_string)
}


// https://www.npmjs.com/package/clsx
// https://github.com/lukeed/clsx/blob/master/src/index.js
function toVal(mix) {
	var k, y, str='';

	if (typeof mix === 'string' || typeof mix === 'number') {
		str += mix;
	} else if (typeof mix === 'object') {
		if (Array.isArray(mix)) {
			var len=mix.length;
			for (k=0; k < len; k++) {
				if (mix[k]) {
					if (y = toVal(mix[k])) {
						str && (str += ' ');
						str += y;
					}
				}
			}
		} else {
			for (y in mix) {
				if (mix[y]) {
					str && (str += ' ');
					str += y;
				}
			}
		}
	}

	return str;
}

export function clsx() {
	var i=0, tmp, x, str='', len=arguments.length;
	for (; i < len; i++) {
		if (tmp = arguments[i]) {
			if (x = toVal(tmp)) {
				str && (str += ' ');
				str += x
			}
		}
	}
	return str;
}

export default clsx;