/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import { DateTime } from 'luxon'
import { getIndividualTasksOfClientId } from '../../../services/report.service'
import { getActiveClients } from '../../../services/client.service'
import useToast from '../../../hooks/useToast.hook'
import useReportFiltersContext from '../../../hooks/contextConsumers/useReportFiltersContext.hook'
import ProgressSpinner from '../../../components/atoms/misc/ProgressSpinner.atom'
import ContextMenu from '../../../components/atoms/ContextMenu.atom'
import ModalFormEditTask from '../../../components/compounds/ModalFormEditTask.compound'
import RegisteredClientTaskTable from './tables/RegisteredClientTaskTable/RegisteredClientTaskTable.controller'
import { getMondayOfCurrentWeek, getSundayOfCurrentWeek } from '../../../utils/date.utils'
import ClientPicker from './components/ClientPicker'
import PeriodDropdown from './components/PeriodDropdown'
import DatePicker from './components/DatePicker'
import ResetButton from './components/ResetButton'
import useMountedState from '../../../hooks/useMountedState'
import useMount from '../../../hooks/useMount'

const INITIAL_PERIOD = 'semanal'
const INITIAL_SELECTED_CLIENT = null
/**
 * @type {import('./HoursByClientReport.controller').initialDateRange}
 */
function initialDateRange() {  
  return {
    startDate: getMondayOfCurrentWeek(),
    endDate: getSundayOfCurrentWeek().toJSDate()
  }
}


/**
 * @type {import('./HoursByClientReport.controller').calculateNewDates}
 */
const calculateNewDates = (period, startDate) => {
  let newStartDate = startDate
  let calculatedEndDate = null

  switch (period) {
    case 'diario':
      calculatedEndDate = DateTime.fromJSDate(startDate)
        .set({ hour: 12 })
        .startOf('day')
        .toJSDate()
      break
    case 'semanal':
      newStartDate = DateTime.fromJSDate(startDate)
        .startOf('week')
        .set({ hour: 12 })
        .toJSDate()
      calculatedEndDate = DateTime.fromJSDate(startDate)
        .endOf('week')
        .set({ hour: 12 })
        .toJSDate()
      break
    case 'mensual':
      newStartDate = DateTime.fromJSDate(startDate)
        .startOf('month')
        .set({ hour: 12 })
        .toJSDate()
      calculatedEndDate = DateTime.fromJSDate(startDate)
        .endOf('month')
        .set({ hour: 12 })
        .toJSDate()
      break
    case 'anual':
      newStartDate = DateTime.fromJSDate(startDate)
        .startOf('year')
        .set({ hour: 12 })
        .toJSDate()
      calculatedEndDate = DateTime.fromJSDate(startDate)
        .endOf('year')
        .set({ hour: 12 })
        .toJSDate()
      break
    default:
      break
  }

  return { startDate: newStartDate, endDate: calculatedEndDate }
}

const HoursByClientReport = React.forwardRef((props, ref) => {
  const [tasks, setTasks] = useState([])
  const [selectedTask, setSelectedTask] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { setErrorMessage } = useToast()
  const [editTaskVisible, setEditTaskVisible] = useState(false)
  const [updatingTask, setUpdatingTask] = useState(null)
  const {
    hoursByClientClients,
    hoursByClientSelectedClient,
    setHoursByClientSelectedClient,
    hoursByClientPeriod,
    setHoursByClientPeriod,
    hoursByClientDateRange,
    setHoursByClientDateRange
  } = useReportFiltersContext()
  const DEBOUNCE_DELAY = 500
  const debounceTimeout = useRef(null)

  const isMounted = useMountedState()

  useEffect(() => {
    const newDates = calculateNewDates(hoursByClientPeriod, hoursByClientDateRange.startDate)
    setHoursByClientDateRange({
      startDate: newDates.startDate,
      endDate: newDates.endDate
    })
  }, [hoursByClientPeriod])

  useEffect(() => {
    setUpdatingTask(null)
    setLoading(true)
    if (hoursByClientPeriod === 'anual') {
      return
    }
    clearTimeout(debounceTimeout.current)
    debounceTimeout.current = setTimeout(() => {
      gettingIndividualTasks(true)
        .finally(() => {
          setLoading(false)
        })
    }, DEBOUNCE_DELAY)
    return () => clearTimeout(debounceTimeout.current)
  }, [hoursByClientDateRange, hoursByClientSelectedClient, hoursByClientPeriod])

  useEffect(() => {
    if (error) {
      setErrorMessage({ message: error })
    }
  }, [error])

  const gettingIndividualTasks = async (globalLoad = false) => {
    if (globalLoad) {
      setLoading(true)
    }

    let response = {}
    if (hoursByClientSelectedClient) {
      response = await getIndividualTasksOfClientId(hoursByClientSelectedClient, hoursByClientDateRange?.startDate, hoursByClientDateRange?.endDate)
    }
    if (response.success) {
      setTasks(response.result)
    } else {
      setError(response.message)
    }
    if (globalLoad) {
      setLoading(false)
    }
  }

  const cm = useRef(null)
  const cmItems = [
    {
      label: 'Editar',
      icon: 'pi pi-pencil',
      command: async () => {
        setEditTaskVisible(true)
      }
    }
  ]



  if (loading) {
    return (
      <>
        <div className='w-full h-20rem flex align-items-center justify-content-center'>
          <ProgressSpinner />
        </div>
      </>
    )
  }

  return (
    <>
      <div className='flex justify-content-between align-items-center pb-3 '>
        <div className="mr-0 ml-1 flex flex-1 align-items-center gap-3">
          <ClientPicker
            clients={hoursByClientClients}
            selectedClient={hoursByClientSelectedClient}
            setSelectedClient={setHoursByClientSelectedClient}
          />
          <PeriodDropdown
            period={hoursByClientPeriod}
            setPeriod={setHoursByClientPeriod}
            includeYearly={false}
            style={{ minWidth: 126 }}
          />
          <DatePicker
            period={hoursByClientPeriod}
            dateRange={hoursByClientDateRange.startDate}
            setDateRange={(e) => {
              const newDates = calculateNewDates(hoursByClientPeriod, e)
              setHoursByClientDateRange({
                startDate: newDates.startDate,
                endDate: newDates.endDate
              })
            }}
            endDate={hoursByClientDateRange.endDate}
            includeYearly={false}
          />
        </div>
        <ResetButton onClick={() => {
          setHoursByClientPeriod(INITIAL_PERIOD)
          setHoursByClientSelectedClient(INITIAL_SELECTED_CLIENT)
          ref.current?.reset()
          setHoursByClientDateRange(initialDateRange())
          setTasks([])
        }} />
      </div>
      <RegisteredClientTaskTable
        ref={ref}
        period={hoursByClientPeriod}
        data={tasks}
        dateRange={hoursByClientDateRange}
        cm={cm}
        setSelectedTask={setSelectedTask}
        updatingTask={updatingTask}
      />
      <ContextMenu ref={cm} model={cmItems} />
      <ModalFormEditTask
        visible={editTaskVisible}
        setVisible={setEditTaskVisible}
        task={selectedTask}
        teamMemberId={null}
        onSubmit={() => {
          setUpdatingTask(selectedTask)
        }}
        onEditCompleted={(errorMessage) => {
          if (errorMessage) {
            setError(errorMessage)
          }
          gettingIndividualTasks()
          setEditTaskVisible(false)
          setUpdatingTask(null)
        }}
        onlyActiveProjects={false}
        isAdminEdit
        waitForResponse={false}
      />
    </>
  )
})

export default HoursByClientReport
