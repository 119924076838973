/* eslint-disable */
import React, { createContext, useState, useEffect } from 'react'
import { getMondayOfCurrentWeek, getSundayOfCurrentWeek } from '../utils/date.utils'
import { getActiveClients } from '../services/client.service'
import { DateTime } from 'luxon'

export const ReportFiltersContext = createContext()
export const ReportFiltersContextProvider = ({ children }) => {
  function todayDateRange() {
    return {
      startDate: DateTime.local().toISODate(),
      endDate: DateTime.local().toISODate()
    }
  }

  function initialDateRange() {  
    return {
      startDate: getMondayOfCurrentWeek(),
      endDate: getSundayOfCurrentWeek().toJSDate()
    }
  }

  const INITIAL_PERIOD = 'semanal'
  const INITIAL_SELECTED_CLIENT = null

  const [weekHoursDateRange, setWeekHoursDateRange] = useState(getMondayOfCurrentWeek())

  const [hoursByProjectDateRange, setHoursByProjectDateRange] = useState(todayDateRange())
  const [hoursByProjectExpandedKeys, setHoursByProjectExpandedKeys] = useState({})

  const [hoursByClientClients, setHoursByClientClients] = useState([])
  const [hoursByClientSelectedClient, setHoursByClientSelectedClient] = useState(INITIAL_SELECTED_CLIENT)
  const [hoursByClientPeriod, setHoursByClientPeriod] = useState(INITIAL_PERIOD)
  const [hoursByClientDateRange, setHoursByClientDateRange] = useState(initialDateRange())

  const [hoursByTeamAndTeamMemberReportTeamMembers, setHoursByTeamAndTeamMemberReportTeamMembers] = useState([])
  const [hoursByTeamAndTeamMemberReportSelectedTeamMembers, setHoursByTeamAndTeamMemberReportSelectedTeamMembers] = useState([])
  const [hoursByTeamAndTeamMemberReportDateRange, setHoursByTeamAndTeamMemberReportDateRange] = useState({
    startDate: DateTime.local().toISO(),
    endDate: DateTime.local().toISO()
  })

  const [individualTeamMembers, setIndividualTeamMembers] = useState([])
  const [individualTeamMember, setIndividualTeamMember] = useState(null)
  const [individualPeriod, setIndividualPeriod] = useState('semanal')
  const [individualDateRange, setIndividualDateRange] = useState({
    startDate: getMondayOfCurrentWeek(),
    endDate: getSundayOfCurrentWeek()
  })

  useEffect(() => {
    updateClientDropdown()
  }, [])
  

  const updateClientDropdown = async () => {
    let clientData = []
    const response = await getActiveClients()
    if (response.success) {
      response.result.forEach(client => {
        clientData.push(client.data)
      })
    }
    setHoursByClientClients(clientData)
  }

  return (
    <ReportFiltersContext.Provider value={{
      weekHoursDateRange,
      setWeekHoursDateRange,
      hoursByProjectDateRange,
      setHoursByProjectDateRange,
      hoursByProjectExpandedKeys,
      setHoursByProjectExpandedKeys,
      hoursByClientClients,
      setHoursByClientClients,
      hoursByClientSelectedClient,
      setHoursByClientSelectedClient,
      hoursByClientPeriod,
      setHoursByClientPeriod,
      hoursByClientDateRange,
      setHoursByClientDateRange,
      hoursByTeamAndTeamMemberReportTeamMembers,
      setHoursByTeamAndTeamMemberReportTeamMembers,
      hoursByTeamAndTeamMemberReportSelectedTeamMembers,
      setHoursByTeamAndTeamMemberReportSelectedTeamMembers,
      hoursByTeamAndTeamMemberReportDateRange,
      setHoursByTeamAndTeamMemberReportDateRange,
      individualTeamMembers,
      setIndividualTeamMembers,
      individualTeamMember,
      setIndividualTeamMember,
      individualPeriod,
      setIndividualPeriod,
      individualDateRange,
      setIndividualDateRange
    }}>
      {children}
    </ReportFiltersContext.Provider>
  )
}
