import React from 'react'
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace'
// import InputNumber from '../atoms/InputNumber.atom'
import InputTime from './InputTime.molecule'
import { formatHours } from '../../utils/time.utils'
import Chip from '../atoms/misc/Chip.atom'
/**
 * @typedef {JSX.IntrinsicAttributes & JSX.IntrinsicElements['div']} JSXDivElement
 */

/**
 * @typedef {import('primereact/inplace').InplaceProps & {
*   inplaceDisplayProps: JSXDivElement,
*   inplaceContentProps: JSXDivElement,
*   value: number,
*   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
*   iconClass: string,
*   error: boolean
*   inputNumberProps: import('primereact/inputnumber').InputNumberProps
* }} InputInplaceControlledProps
*/

/**
* @param {InputInplaceControlledProps} props
* @returns {JSX.Element}
*/
export default function InputInplace ({
  value = 0,
  onChange,
  inplaceDisplayProps,
  inplaceContentProps,
  inputNumberProps,
  iconClass = 'pi pi-stopwatch',
  error,
  ...props
}) {
  const randomId = `inplace-text-${Math.random().toString(36).substring(7)}`
  return (
    <Inplace
      id={randomId}
      className={`${props?.className} inplace-time`}
      closable
      {...props}
    >
      <InplaceDisplay {...inplaceDisplayProps}>
        <Chip
          className={`btn btn-primary w-full ${error ? 'error-chip' : ''}`}
          label={
            <div className='flex gap-2 align-items-center'>
              <i className={`${iconClass}`} />
              {formatHours(value || 0) || '00:00'}
              {error ? <i className='pi pi-exclamation-triangle' data-pr-tooltip="Este es un campo requerido" data-pr-position="bottom" /> : null}
            </div>
          }
        />
      </InplaceDisplay>
      <InplaceContent {...inplaceContentProps}>
        <InputTime
          autoFocus
          value={value || 0}
          onValueChange={({ value: eventValue }) => {
            onChange(eventValue)
          }}
          onChange={({ value: eventValue }) => {
            onChange(eventValue)
          }}
          className='w-full w-9rem max-w-12rem'
          step={0.25}
          format={true}
          showButtons
          min={0}
          // placeholder={'00:00'}
          minFractionDigits={0}
          maxFractionDigits={2}
          error={error}
          {...inputNumberProps}
        />
      </InplaceContent>
    </Inplace>
  )
}
