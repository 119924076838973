import { useEffect, useRef } from 'react'

const useEffectAfterMount = (fn, deps) => {
  const didMount = useRef(false)
  useEffect(() => {
    if (didMount.current) fn()
    else didMount.current = true
  }, deps)
}

export default useEffectAfterMount
