import React from 'react'
import Badge from '../atoms/Badge.atom'
import Tooltip from '../atoms/Tooltip.atom'
import { generateRandomNumber } from '../../utils/numbers.utils'

const initials = {
  BEEF: 'B',
  CHERRY: 'Ch',
  COCKTAIL: 'Ck',
  GRAPE: 'G',
  TOV: 'T',
  HEIRLOOM: 'H',
  MEDLEYS: 'M'
}

const ProductBadgeWithTooltip = ({ producto, position = 'bottom', tooltipValue }) => {
  const colorClasses = `rounded-badge rounded-badge--${producto?.toLowerCase()}`
  const id = generateRandomNumber()
  const defaultPosition = 'right'
  return (
    <>
      <Tooltip target={`.asignacion-data-${producto?.toLowerCase()}-${id}`} position={position || defaultPosition}/>
      <Badge
        className={`asignacion-data-${producto?.toLowerCase()}-${id} ${colorClasses}`}
        data-pr-tooltip={tooltipValue}
        value={initials[producto]}
      />
    </>
  )
}

export default ProductBadgeWithTooltip
