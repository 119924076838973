import { API_PERFIL } from '../consts/apiRoutes.consts'
import SERVICE_CONSTS from '../consts/services.consts'
import { api } from './base/api.service'

const {
  RESPONSE_BASIC_STRUCTURE,
  MESSAGES
} = SERVICE_CONSTS

export const getTeamMembers = async () => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.get(`${API_PERFIL}/teamMembers`)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const completeBasicTutorial = async () => {
  const response = { ...RESPONSE_BASIC_STRUCTURE }

  let result
  try {
    result = await api.put(`${API_PERFIL}/completeBasicTutorial`)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const completeAfterTaskCreationTutorial = async () => {
  const response = { ...RESPONSE_BASIC_STRUCTURE }

  let result
  try {
    result = await api.put(`${API_PERFIL}/completeAfterTaskCreationTutorial`)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}
