import React from 'react'
import WPAtom from '../../../../components/atoms/WeekPicker.atom'
import CustomPlaceholder from '../../../../components/molecules/CustomPlaceholder.molecule'
import { DateTime } from 'luxon'

const getFullWeekFromDate = (date) => {
  const weekStart = DateTime.fromJSDate(date).set({ hour: 12 })
  const weekEnd = weekStart.plus({ days: 6 }).endOf('day')
  return {
    weekStart: weekStart.toJSDate(),
    weekEnd: weekEnd.toJSDate()
  }
}

const WeekPicker = ({ date, setDate }) => {
  const { weekStart, weekEnd } = getFullWeekFromDate(date)
  const [width, setWidth] = React.useState(0)
  const ref = React.useRef()

  React.useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth + 20)
    }
  }, [date])

  React.useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth + 20)
      }
    })
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div className='relative w-fit'>
      <WPAtom
        date={date}
        setDate={setDate}
        maxDate={new Date()}
        minDate={null}
        inputStyle={{ color: 'transparent', cursor: 'pointer', width: `${width}px` }}
        selectedweekday='monday'
      />
      <CustomPlaceholder
        ref={ref}
        iconClassName='pi pi-calendar'
        label={
          <>
            {`${weekStart.toLocaleDateString()} - ${weekEnd.toLocaleDateString()}`}
            <i className='pi pi-chevron-down text-xs'></i>
          </>
        }
      />
    </div>
  )
}

export default WeekPicker
