import { AuthErrorCodes } from 'firebase/auth'

const { USER_DELETED, INVALID_EMAIL, INVALID_PASSWORD, USER_DISABLED } = AuthErrorCodes

export const DEFAULT_AUTH_ERROR_MESSAGE = 'Correo electrónico o contraseña incorrectos.'
export const firebaseAuthErrors = {
  [USER_DELETED]: DEFAULT_AUTH_ERROR_MESSAGE,
  [INVALID_PASSWORD]: DEFAULT_AUTH_ERROR_MESSAGE,
  [INVALID_EMAIL]: 'El correo electrónico no es válido.',
  [USER_DISABLED]: 'El usuario ha sido deshabilitado.'
}

export const DEFAULT_RECOVERY_ERROR_MESSAGE = 'No se pudo enviar el correo de recuperación de contraseña.'
export const firebaseRecoveryErrors = {
  ...firebaseAuthErrors,
  [USER_DELETED]: 'No se ha encontrado al usuario con el correo ingresado.'
}
