import React from 'react'
import ClientChip from '../components/molecules/ClientChip.molecule'
import { Link } from 'react-router-dom'

export const ITEM_SIZE_VIRTUAL_SCROLLER = 80

export const TABLE_SUFFIX = {
  kgM2: 'kg/m²',
  m2: 'm²',
  mililitrosXJulio: 'ml/J',
  litrosxMetroCuadrado: ' l/m²'
}

export const DEFAULT_SKELETON_TABLE_CONFIG = {
  loading: true,
  qtyOfRows: 20
}

export const CLIENT_CHIP_COLUMN_PROPS = {
  field: 'data.name',
  header: 'Cliente/Proyecto',
  body: (node) => {
    // console.log('node', node)
    if (node?.data?.code) {
      return (
        <Link
          className='team-member-name'
          to={`/reporte-general/horas-cliente/${node.data.code}/${node.startDate}/${node.endDate}`}
          title='Ir al reporte de cliente ↗'
        >
          <div className='flex gap-2 align-items-center'>
            <ClientChip className='w-fit' code={node?.data?.code}/>
            {node?.children?.length <= 1 && <span>{node?.children?.[0]?.data?.tagName}</span>}
          </div>
        </Link>
      )
    } else {
      return <span>{node?.data?.name}</span>
    }
  },
  filter: true,
  filterField: 'data.name',
  filterMatchMode: 'in'
}

export const OVERFLOW_ELLIPSIS_COLUMN_PROPS = {
  body: (rowData) => (
    <div className='overflow-hidden white-space-nowrap text-overflow-ellipsis'>
      {rowData}
    </div>
  )
}
