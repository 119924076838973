/* eslint-disable */
import { DateTime } from 'luxon'
import React, { useEffect } from 'react'
import { getNombreWeekByDate, getWeekByDate } from '../../utils/date.utils'
import Calendar from './Calendar.atom'
import PropTypes from 'prop-types'

export default function WeekPicker (props) {
  useEffect(() => {
    document.getElementById('root')?.classList.add('weekpicker')
  }, [])

  const selectedWeekDay = props.selectedweekday ? props.selectedweekday : 'monday'

  const handlerWeek = (e) => {
    const date = DateTime.fromISO(new Date(e.value).toISOString())
    const weekday = { monday: 1, tuesday: 2, wednesday: 3, thursday: 4, friday: 5, saturday: 6, sunday: 7 }
    const plusDay = date.weekday - weekday[selectedWeekDay]
    if (weekday[selectedWeekDay] === 1) {
      const weekStart = DateTime.fromJSDate(e.value).startOf('week').set({ hour: 12 })
      const weekEnd = weekStart.plus({ days: 6 }).endOf('day')
      if (props.forminput) {
        const finalDate = { ...e }
        finalDate.target.value = weekStart.toJSDate()
        props.setDate(weekEnd)
      } else {
        props.setDate(weekStart.toJSDate())
      }
      return
    }
    const generateFinalDate = date.minus({ days: plusDay, hours: -1 }).set({ hour: 0 }).toJSDate()
    if (props.forminput) {
      const finalDate = { ...e }
      finalDate.target.value = generateFinalDate
      props.setDate(finalDate)
    } else {
      props.setDate(generateFinalDate)
    }
  }

  // const dateFormat = props.removeWeekLabel
  //   ? `'${getNombreWeekByDate(props.date)}'`
  //   : `'${getWeekByDate(props.date)}'`
  let dateFormat = `'${getWeekByDate(props.date)}'`
  if (props.removeWeekLabel) {
    dateFormat = `'${getNombreWeekByDate(props.date)}'`
  }
  if (props.weekRangeLabel) {
    const startWeek = DateTime.fromJSDate(props.date).setLocale('es').startOf('week').set({ hour: 12 }).toJSDate().toLocaleDateString()
    const endWeek = DateTime.fromJSDate(props.date).endOf('week').toJSDate().toLocaleDateString()
    // console.log(props.date, DateTime.fromJSDate(props.date).startOf('week'))
    dateFormat = `'${startWeek} - ${endWeek}'`
  }


  return <Calendar
    showWeek
    onChange={handlerWeek}
    value={props.date}
    dateFormat={dateFormat}
    readOnlyInput
    minDate={props.minDate}
    maxDate={props.maxDate}
    name={props.name}
    className={props.className}
    placeholder={props.placeholder}
    id={props.id}
    inputStyle={props.inputStyle}
  />
}

WeekPicker.propTypes = {
  selectedWeekDay: PropTypes.oneOf([PropTypes.string, null]),
  date: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.instanceOf(Date)]),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  setDate: PropTypes.func,
  forminput: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool])
}
