import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../consts/appRoutes.consts'
import GlobalTitle from '../../../components/compounds/titles/GlobalTitle.compounds'
import PrimaryButton from '../../../components/molecules/buttons/PrimaryButton.molecule'

const HeaderUsuarios = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(AppRoutes.usuarios.crear)
  }

  return (
    <GlobalTitle title='Usuarios' withButtons buttons={<PrimaryButton label='Crear usuario' onClick={handleClick}/>}/>
  )
}

export default HeaderUsuarios
