import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Login } from '../views/auth/Login.view'
import { RecuperarContrasena } from '../views/auth/RecuperarContrasena.view'
import { View404 } from '../views/systemPages/View404.view'
import { Logout } from '../views/auth/Logout.view'
export function UnauthorizedRoutes () {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<Login />} />
        <Route path={'/recuperar-contrasena'} element={<RecuperarContrasena />} />
        <Route path={'/logout'} element={<Logout/>} />
        <Route path={'*'} element={<View404/>} />
      </Routes>
    </>
  )
}
