import React from 'react'
import { Badge as PrimeBadge } from 'primereact/badge'

const Badge = (props) => {
  return (
    <PrimeBadge
      {...props}
    />
  )
}

export default Badge
