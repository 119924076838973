import React from 'react'
import Column from '../../../components/atoms/table/Column.atom'
import ColumnGroup from '../../../components/atoms/table/ColumnGroup.atom'
import Row from '../../../components/atoms/table/RowColumnGroup.atom'
import { DataTable } from 'primereact/datatable'
import Dropdown from '../../../components/atoms/Dropdown.atom'
import MultiSelect from '../../../components/atoms/MultiSelect.atom'
import ReportBadgeWithTooltip from '../../../components/molecules/ReportBadgeWithTooltip.molecule'
const SAMPLE_DATASET = [
  {
    teamMemberId: 3,
    teamMemberTagName: 'ACV',
    teamMemberName: 'Alejandra',
    teamMemberLastName: 'Case',
    week: {
      '2023-11-14': { sisuTime: 8, billedTime: 8, timeDifferenceComment: 'Por proceso de ob' },
      '2023-11-15': { sisuTime: 8, billedTime: 8 },
      '2023-11-16': { sisuTime: 10, billedTime: 8 },
      '2023-11-17': { sisuTime: 8, billedTime: 8 },
      '2023-11-18': { sisuTime: 8, billedTime: 8, isDayEditedAfterFinished: true }
    }
  },
  {
    teamMemberId: 5,
    teamMemberTagName: 'AG',
    teamMemberName: 'Aurelio',
    teamMemberLastName: 'Gonzalez',
    week: {
      '2023-11-14': { sisuTime: 9, billedTime: 8 },
      '2023-11-15': { sisuTime: 8, billedTime: 8 },
      '2023-11-16': { sisuTime: 4, billedTime: 8 },
      '2023-11-17': { sisuTime: 8, billedTime: 8 },
      '2023-11-18': { sisuTime: 8, billedTime: 0 },
      '2023-11-19': { sisuTime: 8, billedTime: 0 }
    }
  },
  {
    teamMemberId: 6,
    teamMemberTagName: 'AGM',
    teamMemberName: 'Axel Dalí',
    teamMemberLastName: 'Gómez Morales',
    week: {
      '2023-11-14': { sisuTime: 8, billedTime: 8 },
      '2023-11-15': { sisuTime: 8, billedTime: 8, dayFinishedComment: 'Se terminó más tarde por...' },
      '2023-11-16': { sisuTime: 8, billedTime: 8 },
      '2023-11-17': { sisuTime: 8, billedTime: 8 },
      '2023-11-18': { sisuTime: 8, billedTime: 8 }
    }
  }
]

const getUserWithMostDays = (dataset) => {
  const days = Object.keys(dataset[0].week).length
  let user = dataset[0]
  dataset.forEach((item) => {
    const userDays = Object.keys(item.week).length
    if (userDays > days) {
      user = item
    }
  })
  return user
}

const formatWeeklyReportData = (dataset) => {
  const processed = dataset.map(tm => {
    const totalSisuTime = Object.values(tm.week).reduce((acc, day) => acc + day.sisuTime, 0)
    const totalBilledTime = Object.values(tm.week).reduce((acc, day) => acc + day.billedTime, 0)
    const totalDifference = Object.values(tm.week).reduce((acc, day) => acc + (day.sisuTime - day.billedTime), 0)
    const ocupationRatio = (totalBilledTime / totalSisuTime * 100).toFixed(2)
    return {
      ...tm,
      totalSisuTime,
      totalBilledTime,
      totalDifference,
      ocupationRatio
    }
  })

  const totals = {
    totalSisuTime: processed.reduce((acc, tm) => acc + tm.totalSisuTime, 0),
    totalBilledTime: processed.reduce((acc, tm) => acc + tm.totalBilledTime, 0),
    totalDifference: processed.reduce((acc, tm) => acc + tm.totalDifference, 0),
    totalOcupationRatio: (processed.reduce((acc, tm) => acc + parseFloat(tm.ocupationRatio), 0) / processed.length).toFixed(2)
  }

  return { processed, totals }
}

const WEEK_SIZE = Object.keys(getUserWithMostDays(SAMPLE_DATASET)?.week)?.length
const WEEK_DATES = Object.keys(getUserWithMostDays(SAMPLE_DATASET)?.week)

const DataTableSandbox = () => {
  const { processed: processedDataset, totals } = formatWeeklyReportData(SAMPLE_DATASET)
  console.log(processedDataset)
  const teamMemberFilterTemplate = (options) => {
    const teamMembers = processedDataset.map((tm) => {
      return {
        label: `${tm.teamMemberTagName} - ${tm.teamMemberName} ${tm.teamMemberLastName}`,
        value: tm.teamMemberTagName
      }
    })
    return (
      <div className='flex flex-column gap-3'>
        <Dropdown
          options={[
            { label: 'Ascendente', value: 'asc' },
            { label: 'Descendente', value: 'desc' }
          ]}
          value={options.value}
          onChange={(e) => {
            console.log(e.value)
            options.filterCallback(e.value)
          }}
          optionLabel="label"
          placeholder="Ordenar"
        />
        <MultiSelect
          options={teamMembers}
          value={options.value}
          onChange={(e) => {
            console.log(e.value)
            try {
              options.filterCallback(e.value)
            } catch (error) {
              console.error(error)
            }
          }}
          optionLabel="label"
          optionValue="value"
          placeholder="Filtrar integrante"
          selectedItemTemplate={(option) => {
            return <div>{option || 'Filtrar integrante'}</div>
          }}
        />
      </div>
    )
  }

  const headerGroup = () => {
    return (
      <ColumnGroup>
        <Row>
          <Column
            header="Integrante"
            field='teamMemberTagName'
            filter
            filterField='teamMemberTagName'
            filterElement={teamMemberFilterTemplate}
            showFilterMenuOptions={false}
            showFilterMatchModes={false}
            sortable
            rowSpan={3}
          />
        </Row>
        <Row>
          <Column header="Horas trabajadas" colSpan={WEEK_SIZE} rowSpan={1} />
          <Column header='Totales' colSpan={1} rowSpan={2} sortable field='totalSisuTime' />
          <Column header="Ocupación" colSpan={1} rowSpan={2} />
          <Column header="Horas con cliente" colSpan={1} rowSpan={2} />
          <Column header="Horas facturadas" colSpan={1} rowSpan={2} />
          <Column header="Diferencia" colSpan={1} rowSpan={2} field="totalDifference" sortable />
        </Row>
        <Row>
          {
            WEEK_DATES?.map((date) => {
              console.log(date)
              let dayName = new Date(date).toLocaleDateString('es-Mx', { weekday: 'long' })
              dayName = dayName.charAt(0).toUpperCase() + dayName.slice(1)
              return (
                <Column key={date} header={
                  <div className='flex flex-column gap-2'>
                    <span>{dayName}</span>
                    <span className='font-normal text-sm'>{date}</span>
                  </div>
                } colspan={2} rowSpan={1} />
              )
            })
          }
        </Row>
      </ColumnGroup>
    )
  }

  const footerGroup = () => {
    return (
      <ColumnGroup>
        <Row>
          <Column footer="Totales" colSpan={WEEK_SIZE + 1} />
          <Column footer={totals.totalSisuTime} />
          <Column footer={'-'} />
          <Column footer={totals.totalSisuTime} />
          <Column footer={totals.totalBilledTime} />
          <Column footer={totals.totalDifference} />
        </Row>
        <Row>
          <Column footer="Promedios" colSpan={WEEK_SIZE + 1} />
          <Column footer={(totals.totalSisuTime / processedDataset.length).toFixed(2)} />
          <Column footer={totals.totalOcupationRatio} />
          <Column footer={(totals.totalSisuTime / processedDataset.length).toFixed(2)} />
          <Column footer={(totals.totalBilledTime / processedDataset.length).toFixed(2)} />
          <Column footer={(totals.totalDifference / processedDataset.length).toFixed(2)} />
        </Row>
      </ColumnGroup>
    )
  }
  const teamMemberNameTemplate = (rowData) => {
    return (
      <div className='flex align-items-center'>
        <span>{rowData.teamMemberTagName} - {rowData.teamMemberName} {rowData.teamMemberLastName}</span>
      </div>
    )
  }

  const BadgesTemplate = ({ rowData, date }) => {
    const isDayEditedAfterFinished = rowData?.week?.[date]?.isDayEditedAfterFinished
    const dayFinishedComment = rowData?.week?.[date]?.timeDifferenceComment
    const timeDifference = rowData?.week?.[date]?.sisuTime > rowData?.week?.[date]?.billedTime
    return (
      <span className='flex gap-2'>
        {
          isDayEditedAfterFinished &&
          <ReportBadgeWithTooltip
            type='warning'
            iconClassName='pi pi-history text-xs'
            tooltipValue='El día fue editado después de haber sido finalizado'
          />
        }
        {
          dayFinishedComment &&
          <ReportBadgeWithTooltip
            type='info'
            iconClassName='pi pi-comment text-white text-xs'
            tooltipValue={
              <>
                <span className='font-bold'>Comentario: </span>
                <span>{dayFinishedComment}</span>
              </>
            }
          />
        }
        {
          timeDifference &&
          <ReportBadgeWithTooltip
            type='danger'
            iconClassName='pi pi-exclamation-triangle text-white text-xs'
            lagend={timeDifference}
          />
        }
      </span>
    )
  }
  return (
    <>
      <div className="card">
        <DataTable
          value={processedDataset}
          headerColumnGroup={headerGroup()}
          footerColumnGroup={footerGroup()}
          tableStyle={{ minWidth: '50rem' }}
          sortMode="multiple"
        >
          <Column
            body={teamMemberNameTemplate}
            style={{ padding: '7px 7px 7px 0px' }}
            field="teamMemberTagName"
            sortable
          />
          {
            WEEK_DATES?.map((date) => {
              return (
                <Column
                  key={date} field={`week.${date}.sisuTime`}
                  body={(rowData) => {
                    return (
                      <span className='flex align-items-center gap-1'>
                        {rowData?.week?.[date]?.sisuTime || '-'}
                        <BadgesTemplate date={date} rowData={rowData}/>
                      </span>
                    )
                  }}
                />
              )
            })
          }
          <Column field="totalSisuTime" sortable />
          <Column field="ocupationRatio" />
          <Column field="totalSisuTime" />
          <Column field="totalBilledTime" />
          <Column field="totalDifference" />
        </DataTable>
      </div>
    </>
  )
}

export default DataTableSandbox
