import React from 'react'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Column } from 'primereact/column'
import TeamMemberSortFilter from '../../../../../components/compounds/columnFilters/TeamMemberSortFilter.compound'
import { dateFormat, getDayName } from '../../../../../utils/date.utils'

const headerColumnGroup = (
  WEEK_SIZE,
  WEEK_DATES,
  teamMembersFilterOptions,
  sortReportData,
  setSortField,
  setSortOrder,
  sortOrder,
  filteredUsers,
  setRecalculateFooter
) => (
  <ColumnGroup>
    <Row>
      <Column
        header="Integrante"
        field='teamMemberTagName'
        filter
        filterElement={(options) => (
          <TeamMemberSortFilter
            filterOptions={options}
            teamMembers={teamMembersFilterOptions}
            selectedTeamMembers={options.value}
            sortOrder={sortOrder}
            onSortChange={(e) => {
              setSortField('teamMemberTagName')
              setSortOrder(e.value)
            }}
            onTeamMemberChange={(e) => options.filterCallback(e.value)}
          />
        )}
        onFilterApplyClick={(props) => {
          sortReportData()
          setRecalculateFooter(true)
          filteredUsers(props.constraints.constraints[0].value)
        }}
        onFilterClear={(props) => {
          setRecalculateFooter(true)
          setSortOrder(1)
          setSortField('teamMemberTagName')
          filteredUsers([])
          sortReportData(1)
        }}
        showFilterMenuOptions={false}
        showFilterMatchModes={false}
        colspan={2}
        rowSpan={2}
      />
      <Column
        header="Horas trabajadas"
        colSpan={WEEK_SIZE + 1}
        rowSpan={1}
      />
      <Column
        header="Ocupación"
        colSpan={1}
        rowSpan={2}
      />
      <Column
        header="Horas con cliente"
        colSpan={1}
        rowSpan={2}
        style={{ width: '93px' }}
      />
      <Column
        header="Horas facturadas"
        colSpan={1}
        rowSpan={2}
        style={{ width: '93px' }}
      />
      <Column
        header="Diferencia"
        colSpan={1}
        rowSpan={2}
        field="weekTimeDifference"
        sortable
      />
    </Row>
    <Row>
      {
        WEEK_DATES?.map((date) => {
          const dayName = getDayName(date)
          return (
            <Column key={date} header={
              <div className='flex flex-column gap-2'>
                <span>{dayName}</span>
                <span className='font-normal text-sm white-space-nowrap'>{dateFormat(date)}</span>
              </div>
            } colspan={2} rowSpan={1} />
          )
        })
      }
      <Column header='Total' colSpan={1} rowSpan={1} sortable field='weekSisuTime' />
    </Row>
  </ColumnGroup>
)

export default headerColumnGroup
