import React, { useEffect, useState } from 'react'
import DataTable from '../../../../../components/atoms/table/DataTable.atom'
import Column from '../../../../../components/atoms/table/Column.atom'
import {
  roundReportData,
  getUserWithMostDays
} from '../../../../../utils/report.utils'
import HeaderGroup from './HeaderGroup.controller'
import FooterGroup from './FooterGroup.controller'
import ColumnContent from './ColumnContent.controller'

const WeekHoursReportDataTable = React.forwardRef(({
  reportData: originalData,
  sortOrder,
  setSortOrder,
  resetSortOrderRef
}, ref) => {
  const reportData = roundReportData(originalData)
  const userWithMostDays = getUserWithMostDays(reportData.teamMemberReport)
  const WEEK_SIZE = Object.keys(userWithMostDays?.week)?.length
  const WEEK_DATES = Object.keys(userWithMostDays?.week)

  const [sortField, setSortField] = useState(null)
  const [recalculateFooter, setRecalculateFooter] = useState(false)
  const [filteredUsers, setFilteredUsers] = useState([])
  const teamMembersFilterOptions = reportData.teamMemberReport.map((tm) => {
    return {
      label: `${tm.teamMemberTagName} - ${tm.teamMemberName} ${tm.teamMemberLastName}`,
      value: tm.teamMemberTagName
    }
  })

  const sortReportData = (orderType) => {
    const orderToUse = orderType || sortOrder
    const sortedData = reportData.teamMemberReport.sort((a, b) => {
      if (a[sortField] < b[sortField]) return -1 * orderToUse
      if (a[sortField] > b[sortField]) return 1 * orderToUse
      return 0
    })
    reportData.teamMemberReport = sortedData
  }

  useEffect(() => {
    resetSortOrderRef.current = () => {
      console.log('Reset')
      setRecalculateFooter(true)
      setSortOrder(1)
      setSortField('teamMemberTagName')
      setFilteredUsers([])
      sortReportData('teamMemberTagName') // Por problema de render, resuelto mejor en SISUAIK-
    }
  }, [])

  return (
    <DataTable
      ref={ref}
      value={reportData.teamMemberReport}
      headerColumnGroup={
        HeaderGroup(
          WEEK_SIZE,
          WEEK_DATES,
          teamMembersFilterOptions,
          sortReportData,
          setSortField,
          setSortOrder,
          sortOrder,
          setFilteredUsers,
          setRecalculateFooter
        )
      }
      footerColumnGroup={
        FooterGroup(
          WEEK_SIZE,
          reportData,
          recalculateFooter,
          filteredUsers
        )
      }
      responsiveLayout='scroll'
      sortMode="multiple"
      // sortField={sortField}
      sortOrder={sortOrder}
      // globalFilterFields={['teamMemberTagName']}
      removableSort
    >
      {
        ColumnContent(
          WEEK_DATES
        )?.map((column, index) => (
          <Column
            key={index}
            {...column}
          />
        ))
      }
    </DataTable>
  )
})

export default WeekHoursReportDataTable
