import React from 'react'
import { AppRoutes } from '../../consts/appRoutes.consts'
import { UsersOptionsProvider } from '../../contexts/data/UsersOptions.context'

import Home from '../../views/home/Home.view'
import Usuarios from '../../views/usuarios/Usuarios.view'
import FormUsuarios from '../../views/usuarios/FormUsuarios.view'
import TimeRegister from '../../views/timeRegister/TimeRegister.view'
import Clients from '../../views/clients/Clients.view'
import CreateClient from '../../views/clients/CreateClient.view'
import EditClient from '../../views/clients/EditClient.view'

const home = (viewOnly = false) => {
  return {
    path: AppRoutes.home.index,
    element: <Home/>
  }
}

const usuariosIndex = (viewOnly = false) => {
  return {
    path: AppRoutes.usuarios.index,
    element: <UsersOptionsProvider>
      <Usuarios viewOnly={viewOnly}/>
    </UsersOptionsProvider>
  }
}

const usuariosCrear = (viewOnly = false) => {
  return {
    path: AppRoutes.usuarios.crear,
    element: <UsersOptionsProvider>
      <FormUsuarios viewOnly={viewOnly}/>
    </UsersOptionsProvider>
  }
}

const usuariosActualizar = (viewOnly = false) => {
  return {
    path: AppRoutes.usuarios.actualizar,
    element: <UsersOptionsProvider>
      <FormUsuarios viewOnly={viewOnly}/>
    </UsersOptionsProvider>
  }
}

const registroDeTiempos = (viewOnly = false) => {
  return {
    path: AppRoutes.registroTiempos.index,
    element: <TimeRegister/>
  }
}

const reporteGeneralIndex = (viewOnly = false) => {
  return {
    path: AppRoutes.reporteGeneral.index,
    element: <>Reporte general</>
  }
}

const reporteGeneralSemanal = (viewOnly = false) => {
  return {
    path: AppRoutes.reporteGeneral.horasSemana,
    element: <>Reporte general semanal</>
  }
}
const reporteGeneralProyecto = (viewOnly = false) => {
  return {
    path: AppRoutes.reporteGeneral.horasProyecto,
    element: <>Reporte general semanal</>
  }
}
const reporteGeneralEquipo = (viewOnly = false) => {
  return {
    path: AppRoutes.reporteGeneral.horasEquipo,
    element: <>Reporte general semanal</>
  }
}

const reporteGeneralCliente = (viewOnly = false) => {
  return {
    path: AppRoutes.reporteGeneral.horasCliente,
    element: <>Reporte general semanal</>
  }
}

const reporteCliente = (viewOnly = false) => {
  return {
    path: AppRoutes.reporteCliente.index,
    element: <>Reporte equipo</>
  }
}

const clientes = (viewOnly = false) => {
  return {
    path: AppRoutes.clientes.index,
    element: <Clients/>
  }
}

const clientesCrear = (viewOnly = false) => {
  return {
    path: AppRoutes.clientes.crear,
    element: <CreateClient/>
  }
}

const clientesEditar = (viewOnly = false) => {
  return {
    path: AppRoutes.clientes.editar,
    element: <EditClient/>
  }
}

const APP_ROUTES = {
  home,
  usuariosIndex,
  usuariosCrear,
  usuariosActualizar,
  registroDeTiempos,
  // reporteGeneral,
  reporteGeneralIndex,
  reporteGeneralSemanal,
  reporteGeneralProyecto,
  reporteGeneralEquipo,
  reporteGeneralCliente,
  reporteCliente,
  clientes,
  clientesCrear,
  clientesEditar
}

export default APP_ROUTES
