import React, { useEffect, useState } from 'react'
import TabView from '../../../components/atoms/tabView/TabView.atom'
import TabPanel from '../../../components/atoms/tabView/TabPanel.atom'
import RegisteredTasks from './RegisteredTasks.controller'
import IndividualReportControls from './IndividualReportControls.controller'
import IndividualReportSummary from './IndividualReportSummary.controller'
import { getLastIndividualTeamMemberReport } from '../../../services/report.service'
import useReportFiltersContext from '../../../hooks/contextConsumers/useReportFiltersContext.hook'
import { DateTime } from 'luxon'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const IndividualReportContent = React.forwardRef(({
  currentTab,
  setCurrentTab
}, ref) => {
  const {
    individualTeamMembers,
    setIndividualTeamMembers,
    individualTeamMember,
    setIndividualTeamMember,
    individualPeriod,
    setIndividualPeriod,
    individualDateRange,
    setIndividualDateRange
  } = useReportFiltersContext()
  const [showControls, setShowControls] = useState(false)
  const { state } = useLocation()
  const redirectParams = useParams()
  const [obtainedDataFromParams, setObtainedDataFromParams] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (currentTab === 0 && individualPeriod === 'anual') {
      setIndividualPeriod('mensual')
    }
    const newDates = calculateNewDates(individualPeriod, individualDateRange.startDate)
    setIndividualDateRange({
      startDate: newDates.startDate,
      endDate: newDates.endDate
    })
  }, [individualPeriod, currentTab])

  const calculateNewDates = (period, startDate) => {
    let newStartDate = startDate
    let calculatedEndDate = individualDateRange.endDate

    switch (period) {
    case 'diario':
      calculatedEndDate = DateTime.fromJSDate(startDate)
        // .setZone('UTC-6')
        .set({ hour: 12 })
        .startOf('day')
        .toJSDate()
      break
    case 'semanal':
      newStartDate = DateTime.fromJSDate(startDate)
        .startOf('week')
        // .setZone('UTC-6')
        .set({ hour: 12 })
        .toJSDate()
      calculatedEndDate = DateTime.fromJSDate(startDate)
        .endOf('week')
        // .setZone('UTC-6')
        .set({ hour: 12 })
        .toJSDate()
      break
    case 'mensual':
      newStartDate = DateTime.fromJSDate(startDate)
        .startOf('month')
        // .setZone('UTC-6')
        .set({ hour: 12 })
        .toJSDate()
      calculatedEndDate = DateTime.fromJSDate(startDate)
        // .setZone('UTC-6')
        .endOf('month')
        .set({ hour: 12 })
        .toJSDate()
      break
    case 'anual':
      newStartDate = DateTime.fromJSDate(startDate)
        .startOf('year')
        // .setZone('UTC-6')
        .set({ hour: 12 })
        .toJSDate()
      calculatedEndDate = DateTime.fromJSDate(startDate)
        // .setZone('UTC-6')
        .endOf('year')
        .set({ hour: 12 })
        .toJSDate()
      break
    default:
      break
    }

    return { startDate: newStartDate, endDate: calculatedEndDate }
  }

  useEffect(() => {
    const { startDate, endDate } = individualDateRange
    if (individualTeamMember && startDate && endDate && individualPeriod) {
      if (shouldUpdateUrl()) {
        const params = {
          teamMemberId: individualTeamMember.id || 2,
          startDate: DateTime.fromJSDate(startDate).toISODate(),
          endDate: DateTime.fromJSDate(endDate).toISODate(),
          period: individualPeriod
        }
        navigate(`/reporte-individual/${params.teamMemberId}/${params.startDate}/${params.endDate}/${params.period}`, { replace: true })
      }
    }
  }, [individualTeamMember, individualDateRange, individualPeriod, navigate])

  const shouldUpdateUrl = () => {
    const { startDate, endDate } = individualDateRange
    const currentParams = {
      teamMemberId: individualTeamMember?.id || 2,
      startDate: DateTime.fromJSDate(startDate).toISODate(),
      endDate: DateTime.fromJSDate(endDate).toISODate(),
      period: individualPeriod
    }

    return (
      redirectParams.teamMemberId !== String(currentParams.teamMemberId) ||
      redirectParams.startDate !== currentParams.startDate ||
      redirectParams.endDate !== currentParams.endDate ||
      redirectParams.period !== currentParams.period
    )
  }

  useEffect(() => {
    if (obtainedDataFromParams) return
    if (redirectParams && redirectParams?.teamMemberId) {
      if (
        redirectParams.teamMemberId !== individualTeamMember ||
        redirectParams.startDate !== DateTime.fromJSDate(individualDateRange.startDate).toISODate() ||
        redirectParams.endDate !== DateTime.fromJSDate(individualDateRange.endDate).toISODate() ||
        redirectParams.period !== individualPeriod
      ) {
        setIndividualTeamMember({
          id: Number(redirectParams.teamMemberId)
        })
        setIndividualDateRange({
          startDate: DateTime.fromISO(redirectParams.startDate).toJSDate(),
          endDate: DateTime.fromISO(redirectParams.endDate).toJSDate()
        })
        if (redirectParams.period === 'anual' && currentTab === 0) {
          setIndividualPeriod('semanal')
        } else {
          setIndividualPeriod(redirectParams.period)
        }
        setObtainedDataFromParams(true)
      }
    } else {
      gettingLastTeamMemberReport()
    }
  }, [redirectParams])

  const gettingLastTeamMemberReport = async () => {
    if (state) return
    const response = await getLastIndividualTeamMemberReport()
    if (response.success) {
      setIndividualTeamMember(response.result || 2)
    }
  }
  return (
    <div id="individual-report">
      <IndividualReportControls
        key={individualTeamMember || showControls}
        teamMember={individualTeamMember}
        setTeamMember={setIndividualTeamMember}
        period={individualPeriod}
        setPeriod={setIndividualPeriod}
        dateRange={individualDateRange.startDate}
        endDate={individualDateRange.endDate}
        setDateRange={(e) => {
          const newDates = calculateNewDates(individualPeriod, e)
          setIndividualDateRange({
            startDate: newDates.startDate,
            endDate: newDates.endDate
          })
        }}
        setRefresh={setShowControls}
        currentTab={currentTab}
        setTeamMembers={setIndividualTeamMembers}
      />
      <TabView
        onBeforeTabChange={e => {
          setCurrentTab(e.index)
        }}
      >
        <TabPanel header='Tareas registradas'>
          <RegisteredTasks
            ref={ref}
            teamMembers={individualTeamMembers}
            teamMember={individualTeamMember}
            period={individualPeriod}
            dateRange={individualDateRange}
            setShowControls={setShowControls}
          />
        </TabPanel>
        <TabPanel header='Resumen'>
          <IndividualReportSummary
            teamMember={individualTeamMember}
            period={individualPeriod}
            dateRange={individualDateRange}
          />
        </TabPanel>
      </TabView>
    </div>
  )
})

export default IndividualReportContent
