import React from 'react'

const FormSectionTitle = ({
  title,
  subtitle
}) => {
  return (
    <>
      {title && (<h2 className="form-title text-3xl font-semibold font-cairo m-0">
        {title}
      </h2>)}
      {subtitle && (
        <h3 className="form-subtitle text-2xl font-semibold text-gray-900 font-cairo m-0">
          {subtitle}
        </h3>
      )}
    </>
  )
}

export default FormSectionTitle
