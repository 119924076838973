/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { generateTotalHoursPerProjectReport } from '../../../services/report.service'
import useReportFiltersContext from '../../../hooks/contextConsumers/useReportFiltersContext.hook'
import ProgressSpinner from '../../../components/atoms/misc/ProgressSpinner.atom'
import HoursByProjectReportDataTable from './tables/HoursByProjectReportDataTable/HoursByProjectReportDataTable'
import PeriodPicker from './components/PeriodPicker'
import { DateTime } from 'luxon'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import ResetButton from './components/ResetButton'

function todayDateRange() {
  return {
    startDate: DateTime.local().toISODate(),
    endDate: DateTime.local().toISODate()
  }
}

const HoursByProjectReport = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [reportData, setReportData] = useState([])
  const [tabNavElement, setTabNavElement] = useState(null)
  const {
    hoursByProjectDateRange,
    setHoursByProjectDateRange,
    hoursByProjectExpandedKeys,
    setHoursByProjectExpandedKeys
  } = useReportFiltersContext()
  useEffect(() => {
    const element = document.querySelector('#general-report .p-tabview-nav')
    if (element) {
      setTabNavElement(element)
    }
  }, [])
  useEffect(() => {
    if (!hoursByProjectDateRange?.startDate || !hoursByProjectDateRange?.endDate) return
    gettingReport()
  }, [hoursByProjectDateRange])

  const gettingReport = async () => {
    setError(null)
    setIsLoading(true)
    const response = await generateTotalHoursPerProjectReport(hoursByProjectDateRange.startDate, hoursByProjectDateRange.endDate)
    if (response.success) {
      const newReportData = []
      response.result.data.forEach((project) => {
        // console.log(dateRange)
        newReportData.push({
          startDate: hoursByProjectDateRange.startDate,
          endDate: hoursByProjectDateRange.endDate,
          ...project
        })
      })

      // console.log('New Report Data: ', {
      //   data: newReportData,
      //   totals: response.result.totals
      // })
      setReportData({
        data: newReportData,
        totals: response.result.totals
      })
    } else {
      setError(response.message)
    }
    setIsLoading(false)
  }

  const collapseAll = () => {
    setHoursByProjectExpandedKeys({})
  }

  const expandAll = () => {
    const newExpandedKeys = {}
    reportData.data.forEach((node) => {
      newExpandedKeys[node.key] = true
    })
    setHoursByProjectExpandedKeys(newExpandedKeys)
  }

  return (
    <>
      <div style={{ marginBottom: 17.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className='flex gap-2 align-items-center'>
          <PeriodPicker
            date={hoursByProjectDateRange}
            setDate={setHoursByProjectDateRange}
          />
          <span
            className={`${reportData?.data?.length ? 'cursor-pointer' : 'cursor-none opacity-80'} flex align-items-center w-fit h-fit white-space-nowrap`}
            style={{
              color: '#F8F9FA'
            }}
            onClick={() => {
              if (Object.keys(hoursByProjectExpandedKeys).length === 0) {
                expandAll()
              } else {
                collapseAll()
              }
            }}
          >
            {Object.keys(hoursByProjectExpandedKeys).length === 0 ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
            {Object.keys(hoursByProjectExpandedKeys).length === 0 ? 'Expandir todo' : 'Contraer todo'}
          </span>
        </div>
        <ResetButton onClick={() => {
          ref.current?.reset();
          setHoursByProjectDateRange(todayDateRange())
          }} />
      </div>
      {isLoading
        ? (
          <div className='w-full h-20rem flex align-items-center justify-content-center'>
            <ProgressSpinner />
          </div>
        )
        : error
          ? (
            <div>Error: {error}</div>
          )
          : (
            <HoursByProjectReportDataTable
              ref={ref}
              reportData={reportData}
              expandedKeys={hoursByProjectExpandedKeys}
              setExpandedKeys={setHoursByProjectExpandedKeys}
            />
          )}
    </>
  )
})

export default HoursByProjectReport
