import React from 'react'
import ContentLayoutHeader from '../../../components/layouts/ContentLayoutHeader.layout'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../consts/appRoutes.consts'

const ClientsHeader = () => {
  const navigate = useNavigate()
  return (
    <ContentLayoutHeader
      title='Clientes'
      buttonProps={{
        label: 'Crear Cliente',
        icon: 'pi pi-plus',
        className: 'h-fit',
        onClick: () => {
          navigate(AppRoutes.clientes.crear)
        }
      }}
    />
  )
}

export default ClientsHeader
