import React from 'react'
import { Checkbox } from 'primereact/checkbox'

/**
 * Componente base de checkbox
 * @param {import("primereact/checkbox").CheckboxProps} props
 * @returns
 */
export function InputCheckbox (props) {
  return (
    <Checkbox {...props} />
  )
}
