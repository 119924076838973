import { SvgIcon } from '@mui/material'
import React from 'react'
const IconMaterial = (props) => {
  const { icon, ...allOtherProps } = { ...props }
  return (
    <SvgIcon {...allOtherProps} component={icon} />
  )
}

export default IconMaterial
