import React, { createContext, useState, useEffect } from 'react'
import { generateDateMondayCurrentWeek } from '../utils/date.utils'
import { getFiltersOptions } from '../services/menu.service'
import { createToastConfig } from '../utils/toast.utils'
import { toastMessages } from '../consts/common.const'
import useToast from '../hooks/useToast.hook'
import { useLocalStorage } from '../hooks/useLocalStorage.hook'
import { SWITCH_TYPE_OPTIONS } from '../consts/filtersMenu.consts'
import APP_ROUTES from '../routers/routes/appRoutes.router'
import { useLocation } from 'react-router-dom'

export const FiltersMenuContext = createContext()

const FiltersMenuContextProvider = ({ children }) => {
  const DEFAULT_GLOBAL_DATE = generateDateMondayCurrentWeek()
  const location = useLocation()
  const toast = useToast()
  const [filtersOptions, setFiltersOptions] = useState(null)
  const [globalWeek, setGlobalWeek] = useState(DEFAULT_GLOBAL_DATE)
  const [globalMonth, setGlobalMonth] = useState(DEFAULT_GLOBAL_DATE)
  const [globalGroupedInvernaderosVirtuales, setGlobalGroupedInvernaderosVirtuales] = useState([])
  const [filterTypeSelected, setFilterTypeSelected] = useState(SWITCH_TYPE_OPTIONS.FIRST)

  const [filtersMenuState, setFiltersMenuState] = useLocalStorage('filtersMenuState', {
    globalInvernaderoVirtual: null,
    globalProductoId: null,
    globalSitioDeEmpaqueId: null,
    globalInvernaderoFisicoId: null,
    globalClienteId: null,
    globalClientesIds: [],
    globalInvernaderosVirtuales: [],
    globalAsignaciones: [],
    globalProveedorId: null,
    globalBudget: null,
    globalProveedores: []
  })

  useEffect(() => {
    const found = Object.values(APP_ROUTES).find(route => route().path === location.pathname)
    if (found?.isSwitchMultiple) setFilterTypeSelected(SWITCH_TYPE_OPTIONS.SECOND)
  }, [location.pathname])

  const updateFiltersMenuState = (filterStateToUpdate, newValue) => {
    setFiltersMenuState({
      ...filtersMenuState,
      [filterStateToUpdate]: newValue
    })
  }

  const getInitialData = async () => {
    const responseServiceOptions = await getFiltersOptions()
    if (!responseServiceOptions.success) {
      const dataDoesNotLoadMessage = createToastConfig(toastMessages.dataDoesNotLoad)
      return toast.setMessage(...dataDoesNotLoadMessage)
    }
    setFiltersOptions(responseServiceOptions.result)
  }
  useEffect(() => {
    getInitialData()
  }, [])

  return (
    <FiltersMenuContext.Provider value={{
      globalWeek,
      setGlobalWeek,
      globalMonth,
      setGlobalMonth,
      filtersMenuState,
      updateFiltersMenuState,
      filtersOptions,
      globalGroupedInvernaderosVirtuales,
      setGlobalGroupedInvernaderosVirtuales,
      filterTypeSelected,
      setFilterTypeSelected
    }}>
      {filtersOptions && children}
    </FiltersMenuContext.Provider>
  )
}

export default FiltersMenuContextProvider
