import React from 'react'
// import Home from '../../views/home/Home.view'
import TimeRegister from '../../views/timeRegister/TimeRegister.view'
import { AppRoutes } from '../../consts/appRoutes.consts'
import Home from '../../views/home/Home.view'
// import { Navigate } from 'react-router-dom'

export const TEAM_MEMBER_ROUTES = [
  {
    path: AppRoutes.home.index,
    element: <Home />
  },
  {
    path: AppRoutes.registroTiempos.index,
    element: <TimeRegister />
  }
]
