export const Rol = {
  ADMIN: 'admin',
  ADMIN_WITHOUT_REPORT: 'adminWithoutReport',
  TEAM_LEADER: 'teamLeader',
  TEAM_MEMBER: 'teamMember'
}

export const ROLES_TO_SHOW = {
  admin: 'Administrador',
  teamMember: 'Integrante del equipo',
  teamLeader: 'Líder de equipo',
  adminWithoutReport: 'Administrador'
}

export const TEAM_AREAS_NAMES = {
  1: 'Desarrollo',
  2: 'Diseño'
}
