import React, { forwardRef } from 'react'

const CustomPlaceholder = forwardRef(({
  iconClassName,
  label,
  ...props
}, ref) => {
  return (
    <span
      ref={ref}
      className='pointer-events-none white-space-nowrap absolute flex align-items-center gap-2 clr-secondary absolute-center-v'
      style={{ left: '7px' }}
      {...props}
    >
      {iconClassName && <i className={iconClassName}></i>}
      {label}
    </span>
  )
})

export default CustomPlaceholder
