/* eslint-disable */
import React, { useState } from 'react'
import ModalForm from '../../../components/compounds/modalForm/ModalForm.compunds'
import FormContainer from '../../../components/layouts/containers/FormContainer.container'
import { InputText } from 'primereact/inputtext'
import InputTextForm from '../../../components/molecules/InputTextForm.molecule'
import PrimaryButton from '../../../components/molecules/buttons/PrimaryButton.molecule'
import {
  checkAllFields,
  fieldsHaveErrors,
  isNotEmpty,
  validateFieldValue
} from '../../../utils/validations.utils'
import Button from '../../../components/atoms/Button.atom'
import FieldContainer from '../../../components/layouts/containers/FieldContainer.container'

const emptyFieldsState = {
  nombre: {
    errors: [],
    value: '',
    typeValidations: [isNotEmpty],
    validationOptions: {}
  },
  edad: {
    errors: [],
    value: '',
    typeValidations: [isNotEmpty],
    validationOptions: {}
  }
}
const ModalFormSandbox = () => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState({
    nombre: ''
  })
  const initialFieldsState = {
    nombre: {
      errors: [],
      value: '',
      typeValidations: [isNotEmpty],
      validationOptions: {}
    },
    edad: {
      errors: [],
      value: '',
      typeValidations: [isNotEmpty],
      validationOptions: {}
    }
  }
  const [validations, setValidations] = useState(initialFieldsState)
  function handleChange ({ target: { name, value } }) {
    setData({ ...data, [name]: value })
    setValidations(validateFieldValue(validations, name, value))
  }
  async function handleSubmit () {
    checkAllFields(validations, setValidations)
    if (fieldsHaveErrors(validations)) {
      return
    }

    const res = {
      success: true
    }

    if (res.success) {
      setSuccessMessage({ message: 'Formulario enviado!' })
    }
    return res
  }
  function cleanUp () {
    setData({
      nombre: '',
      edad: ''
    })
    setValidations(emptyFieldsState)
  }

  return (
    <>
      <ModalForm
        visible={visible}
        setVisible={setVisible}
        header={
          <div className="flex gap-3 align-items-center">
            <i className="pi pi-exclamation-triangle text-4xl" style={{ color: '#FC3D39' }}/>
            <span className="text-3xl font-semibold">Confirm Dialog</span>
          </div>
        }
        footer={
          <div>
            <PrimaryButton icon='pi pi-check' label='Entendido' onClick={handleSubmit}/>
          </div>
        }
        service={handleSubmit}
        cleanUp={cleanUp}
        postSubmit={cleanUp}
        closable={false}
      >
        Aceptas los términos y condiciones?
      </ModalForm>
      <Button label='Abrir confirm dialog' onClick={() => setVisible(true)}/>
    </>
  )
}

export default ModalFormSandbox
