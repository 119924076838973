import React, { createContext, useEffect, useState } from 'react'
import { onAuthStateChanged } from '../../services/auth.service'
import { getRoutesByRole } from '../../utils/routes.utils'

/**
 * @type {React.Context<{user: CurrentUser | undefined | null}>}
 */
export const SessionContext = createContext({ user: undefined })

export function SessionProvider ({ children }) {
  /**
   * @type {[CurrentUser | undefined | null, function(CurrentUser | null): void]}
   */
  const [user, setUser] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [routes, setRoutes] = useState([])

  useEffect(() => {
    setLoading(true)
    const unsubscribe = onAuthStateChanged(setUser)

    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (user) {
      setRoutes(getRoutesByRole(user?.projectRole))
    } else {
      setRoutes([])
    }
    setLoading(false)
  }
  , [user])

  return (
    <SessionContext.Provider value={{ user, routes, loading }}>
      {children}
    </SessionContext.Provider>
  )
}
