import React, { useEffect, useState } from 'react'
import MultiSelect from '../../../../components/atoms/MultiSelect.atom'

const TeamMemberPicker = ({
  teamMembers,
  selectedTeamMembers,
  setSelectedTeamMembers
}) => {
  const [label, setLabel] = useState('Todos')
  useEffect(() => {
    if (selectedTeamMembers.length === 0) {
      setLabel('Todos')
    } else if (selectedTeamMembers.length === 1) {
      const name = teamMembers.find((member) => member.teamMemberTagName === selectedTeamMembers[0]).teamMemberName
      setLabel(name)
    } else if (selectedTeamMembers.length < teamMembers.length) {
      setLabel(`${selectedTeamMembers.length} integrantes seleccionados`)
    } else if (selectedTeamMembers.length === teamMembers.length) {
      setLabel('Todos')
    }
  }, [selectedTeamMembers])
  const labelTemplate = () => {
    return (
      <span>
        <i className='pi pi-user mr-2'></i>
        {label}
      </span>
    )
  }
  return (
    <MultiSelect
      className='w-15rem'
      value={selectedTeamMembers}
      options={teamMembers}
      onChange={(e) => setSelectedTeamMembers(e.value)}
      optionLabel="teamMemberName"
      optionValue="teamMemberTagName"
      placeholder={labelTemplate()}
      style={{ width: 'fit-content' }}
      maxSelectedLabels={0}
      selectedItemsLabel={labelTemplate()}
    />
  )
}

export default TeamMemberPicker
