import React, { useState } from 'react'
import InputTextForm from '../../../components/molecules/InputTextForm.molecule'
import FormContainer from '../../../components/layouts/containers/FormContainer.container'
import FieldContainer from '../../../components/layouts/containers/FieldContainer.container'
import { isNotEmpty, validateFieldValue, checkAllFields, fieldsDoesNotHaveErrors, isAnEmail } from '../../../utils/validations.utils'
import PrimaryButton from '../../../components/molecules/buttons/PrimaryButton.molecule'
import { LoginFieldNames } from '../../../consts/modules/loginForm.consts'
import { useMessage } from '../../../hooks/useMessage.hook'
import { Message } from '../../../components/atoms/Message.atom'
import { useNavigate } from 'react-router-dom'
import { recoverPassword } from '../../../services/auth.service'
import { DEFAULT_RECOVERY_ERROR_MESSAGE, firebaseRecoveryErrors } from '../../../consts/modules/firebase.consts'

const { EMAIL: EMAIL_FIELD } = LoginFieldNames

export function RecuperarContrasenaForm () {
  const { ref, setErrorMessage, setSuccessMessage } = useMessage()

  const [email, setEmail] = useState('')
  const [disabled, setDisabled] = useState(false)
  const navigate = useNavigate()

  const initialFieldsState = {
    [EMAIL_FIELD]: { errors: [], value: null, typeValidations: [isNotEmpty, isAnEmail], validationOptions: {} }
  }
  const [validations, setValidations] = useState(initialFieldsState)

  const handleSubmit = (e) => {
    e.preventDefault()
    checkAllFields(validations, setValidations)
    if (!fieldsDoesNotHaveErrors(validations)) return

    setDisabled(true)
    recoverPassword(email)
      .then(() => {
        setSuccessMessage({ summary: 'Se ha enviado un correo a tu cuenta para recuperar tu contraseña' })
        setTimeout(() => {
          navigate('/')
        }, 5000)
      }).catch(({ code }) => {
        setDisabled(false)
        const message = firebaseRecoveryErrors[code] || DEFAULT_RECOVERY_ERROR_MESSAGE
        setErrorMessage({ summary: message })
      })
  }

  const handleEmailChange = ({ target }) => {
    const { value } = target
    setEmail(value)
    setValidations(validateFieldValue(validations, EMAIL_FIELD, value))
  }

  return (
    <>
      <Message ref={ref} />
      <form onSubmit={handleSubmit}>
        <FormContainer>
          <FieldContainer className="w-full">
            <InputTextForm
              type="email"
              label="Correo electrónico"
              name="email"
              placeholder="correo@sisu.mx"
              value={email}
              onChange={handleEmailChange}
              error={validations.email.errors}
            />
          </FieldContainer>
        </FormContainer>

        <div className='mt-2'>
          <PrimaryButton rounded={false} label="Enviar correo" btnType="block" disabled={disabled} icon="pi pi-check"/>
        </div>
        {/* <div className="flex align-items-center justify-content-start mt-5">
          <Link to="/" replace className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">
            Ir a iniciar sesión
          </Link>
        </div> */}
      </form>
    </>
  )
}
