import React from 'react'
import ClientChip from '../../../../components/molecules/ClientChip.molecule'
import Dropdown from '../../../../components/atoms/Dropdown.atom'

const ClientPicker = ({
  clients,
  selectedClient,
  setSelectedClient
}) => {
  const itemTemplate = (option) => {
    return (
      <div className='flex gap-2 align-items-center'>
        <ClientChip
          code={option.code}
        />
        <span>{option.name}</span>
      </div>
    )
  }

  const valueTemplate = (option) => {
    if (option) {
      return (
        <div className='flex gap-2 align-items-center' style={{ marginTop: '-3px' }}>
          <ClientChip
            code={option.code}
          />
        </div>
      )
    } else {
      return (
        <span>
          Elige un cliente
        </span>
      )
    }
  }

  return (
    <Dropdown
      className='w-15rem'
      value={selectedClient}
      options={clients}
      onChange={(e) => setSelectedClient(e.value)}
      optionLabel="name"
      optionValue="code"
      placeholder="Elige un cliente"
      style={{ width: 'fit-content', height: '40px' }}
      itemTemplate={itemTemplate}
      valueTemplate={valueTemplate}
    />
  )
}

export default ClientPicker
