import { DateTime } from 'luxon'

export const getWeekFromMondayUntilToday = () => {
  const today = DateTime.local()
  const startOfWeek = today.startOf('week')
  const week = []
  for (let i = startOfWeek; i <= today; i = i.plus({ days: 1 })) {
    week.push(i.toJSDate())
  }
  return week
}
