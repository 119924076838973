import React from 'react'
import { Dialog } from 'primereact/dialog'

/**
 * @param {import("primereact/dialog").DialogProps} props
 */
const Modal = (props) => {
  return (
    <Dialog {...props} resizable={false}/>
  )
}

export default Modal
