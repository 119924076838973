/* eslint-disable */
import React from 'react'
import Calendar from '../../../../components/atoms/Calendar.atom'
import WeekPicker from '../../../../components/atoms/WeekPicker.atom'
import { capitalizeFirstLetter } from '../../../../utils/strings.utils'
import CustomPlaceholder from '../../../../components/molecules/CustomPlaceholder.molecule'
import { DateTime } from 'luxon'

const DatePicker = ({
  period,
  dateRange,
  setDateRange,
  endDate,
  includeYearly = false
}) => {
  // dateRange = new Date(dateRange)
  // endDate = new Date(endDate)
  // console.log('dateRange', dateRange)
  let label
  let view
  if (period === 'diario') {
    label = dateRange.toLocaleDateString()
  } else if (period === 'semanal') {
    label = `${dateRange.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
  } else if (period === 'mensual') {
    label = `${dateRange.toLocaleDateString('es-ES', { month: 'long' })}`
    label = capitalizeFirstLetter(label)
    view = 'month'
  } else if (period === 'anual' && includeYearly) {
    label = `${new Date(dateRange).getFullYear()}`
    view = 'year'
  }
  return (
    <span className="p-input-icon-left">
      <i className="pi pi-calendar" />
      <span className="p-input-icon-right">
        {
          period === 'semanal'
            ? (
              <WeekPicker
                date={dateRange}
                setDate={setDateRange}
                selectedweekday='monday'
                inputStyle={{
                  background: 'transparent',
                  cursor: 'pointer',
                  paddingLeft: '2rem'
                }}
                weekRangeLabel
                maxDate={DateTime.now().toJSDate()}
              />
            )
            : <Calendar
              readOnlyInput
              dateFormat={`'${label}'`}
              inputStyle={{
                background: 'transparent',
                cursor: 'pointer',
                paddingLeft: '2rem'
              }}
              value={dateRange}
              onChange={e => setDateRange(e.value)}
              view={view}
            />
        }
        <i
          className="pi pi-chevron-down text-xs flex align-items-center h-full my-auto"
          style={{
            top: '50%',
            transform: 'translateY(-50%)',
            lineHeight: '1rem'
          }}
        />
      </span>
    </span>
  )
}

export default DatePicker
