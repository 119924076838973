/* eslint-disable */
import React from 'react'
import Chart from '../atoms/Chart.atom'
import useThemeContext from '../../hooks/contextConsumers/useThemeContext.hook'
import Tooltip from '../atoms/Tooltip.atom'
import { Skeleton } from '../atoms/misc/Skeleton.atom'
import EmptyDataTeamMemberMessage from './EmptyDataTeamMemberMessage.molecule'
import { formatDecimals } from '../../utils/numbers.utils'

const defaultOptions = {
  cutout: '70%',
  responsive: false,
  layout: {
    padding: {
      left: 77,
      right: 77
    }
  },
  plugins: {
    legend: {
      display: false,
      position: 'right',
      align: 'start',
      labels: {
        color: '#24292F',
        boxWidth: 10,
        boxHeight: 10,
        padding: 1,
        font: {
          size: 14,
          family: 'Mulish, Arial, sans-serif'
        }
      }
    },
    tooltip: {
      backgroundColor: '#424953',
      padding: 10.5,
      titleFont: {
        size: 14,
        weight: 'bold',
        family: 'Mulish, Arial, sans-serif'
      },
      bodyFont: {
        size: 12,
        family: 'Mulish, Arial, sans-serif'
      },
      displayColors: false,
      callbacks: {
        label: function (context) {
          return `${formatDecimals(context.raw)} horas`
        }
      }
    }
  }
}

const ChartLabels = ({ labels, colors, labelsTooltips }) => {
  return (
    <div className='flex flex-column gap-2 w-10rem'>
      {[...new Set(labels)].map((label, index) => (
        <React.Fragment key={label + index}>
          <div key={index} id={`chart-label-${label.replace(' ', '-').replace('/', '\\')}-${index}`} className='flex align-items-center gap-2'>
            <div
              style={{ backgroundColor: colors[label] || '#64748B', minWidth: 10, minHeight: 10 }}
            ></div>
            <span className='text-sm white-space-nowrap'>{label}</span>
          </div>
          {
            labelsTooltips &&
            <Tooltip
              key={'t-' + index}
              target={`#chart-label-${label.replace(' ', '-').replace('/', '\\')}-${index}`}
              content={labelsTooltips[index]}
              position='bottom'
              style={{
                width: '227px'
              }}
            />
          }
        </React.Fragment>
      ))}
    </div>
  )
}

const DoughnutChart = ({
  title = '',
  labels,
  labelsTooltips,
  data,
  colors,
  options = null,
  tooltipOptions,
  loading,
  width,
  height,
  ...props
}) => {
  const { theme } = useThemeContext()
  const chartOptions = {
    ...defaultOptions,
    plugins: {
      ...defaultOptions.plugins,
      tooltip: {
        ...defaultOptions.plugins.tooltip,
        ...tooltipOptions
      },
      legend: {
        ...defaultOptions.plugins.legend,
        labels: {
          ...defaultOptions.plugins.legend.labels,
          color: theme === 'dark' ? '#F8F9FA' : '#24292F'
        }
      }
    }
  }
  if (theme === 'dark') {
    defaultOptions.plugins.legend.labels.color = '#F8F9FA'
  } else {
    defaultOptions.plugins.legend.labels.color = '#24292F'
  }
  const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: labels?.map(label => colors[label] || '#64748B')
      }
    ]
  }
  if (loading) {
    return (
      <div className='doughnut-chart skeleton'>
        <span
          className='chart-title text-4xl font-semibold'
        >
          {title}
        </span>
        <div className={`${title ? 'mt-4' : null} flex gap-3`}>
          <div style={{ width: width }}>
            <div className={`${title ? 'mt-4' : null} mx-auto flex gap-3 doughnut-skeleton-container`}>
              <div className="doughnut-section section-1"></div>
              <div className="doughnut-section section-2"></div>
              <div className='doughnut-hole' />
            </div>
          </div>
          <div className='flex flex-column gap-2'>
            {Array(5).fill(null).map((label, index) => (
              <div key={index} className='flex align-items-center gap-2'>
                <Skeleton width='110px' height='10px' />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  if (!data || data.length === 0) {
    return (
      <div className='doughnut-chart skeleton'>
        <span
          className='chart-title text-4xl font-semibold'
        >
          {title}
        </span>
        <EmptyDataTeamMemberMessage
          height={height}
        />
      </div>
    )
  }

  return (
    <div className='doughnut-chart'>
      <span
        className='chart-title text-4xl font-semibold'
      >
        {title}
      </span>
      <div className='w-fit mx-auto'>
        <div className={`${title ? 'mt-4' : null} flex flex-column gap-3 md:flex-row align-items-center md:align-items-start`}>
          <Chart
            type='doughnut'
            data={chartData}
            options={chartOptions || defaultOptions}
            width={width}
            height={height}
            className={`${props.className}`}
            {...props}
          />
          <ChartLabels labelsTooltips={labelsTooltips} labels={labels} colors={colors} />
        </div>
      </div>
    </div>
  )
}
export default DoughnutChart
