import React, { createContext, useEffect, useState } from 'react'

export const ScreenDimensionsContext = createContext()

export const ScreenDimensionsContextProvider = ({ children }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [hideMobileMenu, setHideMobileMenu] = useState(true)
  const [showFiltersMenu, setShowFiltersMenu] = useState(false)

  const defineWidth = () => {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', defineWidth)
  }, [])

  return (
    <ScreenDimensionsContext.Provider value={{
      screenWidth,
      setScreenWidth,
      hideMobileMenu,
      setHideMobileMenu,
      showFiltersMenu,
      setShowFiltersMenu
    }}>
      {children}
    </ScreenDimensionsContext.Provider>
  )
}
