import React, { useEffect } from 'react'
import ActiveProjectsChart from './components/ActiveProjectsChart'
import CategoriesChart from './components/CategoriesChart'
import PerformanceTable from './components/PerformanceTable'
import {
  getIndividualActiveProjectsChart,
  getIndividualCategoriesChart,
  getIndividualResumeOfTeamMemberId
} from '../../../services/report.service'
import Divider from '../../../components/atoms/Divider.atom'
import TextIconWithTooltip from '../../../components/molecules/TextIconWithTooltip.molecule'
import { formatDecimals } from '../../../utils/numbers.utils'
import useScreenDimensionsContext from '../../../hooks/contextConsumers/useScreenDimensionsContext.hook'
import { Skeleton } from '../../../components/atoms/misc/Skeleton.atom'

const IndividualSummary = ({ data }) => {
  if (!data) {
    return null
  }
  for (const key in data) {
    if (data[key] === null) {
      data[key] = 0
    }
    data[key] = parseFloat(data[key]).toFixed(2)
  }
  return (
    <div className='flex justify-content-between flex-wrap gap-3'>
      <div className='flex flex-column'>
        <TextIconWithTooltip
          content='Total de horas trabajadas'
          contentProps={{ className: 'clr-secondary' }}
          iconClassName='pi pi-info-circle'
          tooltipValue='Total de horas registradas, incluyendo proyectos internos.'
          tooltipProps={{
            style: {
              width: '267px'
            }
          }}
        />
        <span className='text-4xl font-semibold font-cairo'>{formatDecimals(data.totalSisuTime)}</span>
      </div>
      <div className='flex flex-column'>
        <span className='clr-secondary'>Horas facturadas</span>
        <span className='text-4xl font-semibold font-cairo'>{formatDecimals(data.totalBilledTime)}</span>
      </div>
      <div className='flex flex-column'>
        <span className='clr-secondary'>Diferencia</span>
        <span className='text-4xl font-semibold font-cairo'>{formatDecimals(data.totalTimeDifference)}</span>
      </div>
      <div className='flex flex-column'>
        <span className='clr-secondary'>Rendimiento promedio</span>
        <span className='text-4xl font-semibold font-cairo'>{formatDecimals(data.totalPerformance * 100)}%</span>
      </div>
      <div className='flex flex-column'>
        <TextIconWithTooltip
          content='Horas trabajadas con cliente'
          contentProps={{ className: 'clr-secondary' }}
          iconClassName='pi pi-info-circle'
          tooltipValue='Horas registradas, sin incluir proyectos internos.'
          position='left'
          tooltipProps={{
            style: {
              width: '209px'
            }
          }}
        />
        <span className='text-4xl font-semibold font-cairo'>{formatDecimals(data.sisuTimeExcludingClient)}</span>
      </div>
    </div>
  )
}

const IndividualReportSummary = ({
  teamMember,
  period,
  dateRange
}) => {
  const { screenWidth } = useScreenDimensionsContext()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [activeProjectsChart, setActiveProjectsChart] = React.useState(undefined)
  const [categoriesChart, setCategoriesChart] = React.useState([])
  const [resume, setResume] = React.useState(null)
  useEffect(() => {
    waitForReport()
  }, [teamMember, dateRange])

  const waitForReport = async () => {
    setLoading(true)
    await Promise.all([
      gettingActiveProjectsChart(),
      gettingCategoriesChart(),
      gettingIndividualResume()
    ])
    setLoading(false)
  }
  const gettingActiveProjectsChart = async () => {
    const response = await getIndividualActiveProjectsChart(teamMember?.id, dateRange?.startDate, dateRange?.endDate)
    if (response.success) {
      const projects = response.result.sort((a, b) => a.clientId - b.clientId)
      setActiveProjectsChart(projects)
    } else {
      setError(response.message)
    }
  }

  const gettingCategoriesChart = async () => {
    const response = await getIndividualCategoriesChart(teamMember?.id, dateRange?.startDate, dateRange?.endDate)
    if (response.success) {
      setCategoriesChart(response.result)
    } else {
      setError(response.message)
    }
  }

  const gettingIndividualResume = async () => {
    const response = await getIndividualResumeOfTeamMemberId(teamMember?.id, dateRange?.startDate, dateRange?.endDate)
    if (response.success) {
      setResume(response.result)
    } else {
      setError(response.message)
    }
  }
  if (loading) {
    return (
      <div className='flex flex-column gap-3'>
        <Skeleton
          className='summary-skeleton'
          height={88}
        />
        <div className='flex summary-charts-container'>
          <div className='flex flex-column flex-1'>
            <Skeleton
              id='clients-skeleton'
              height={255}
            />
            <Divider />
            <Skeleton
              id='categories-skeleton'
              height={255}
            />
          </div>
          <Divider
            vertical={screenWidth > 1300}
          />
          <div className='flex-1'>
            <Skeleton
              id='performance-skeleton'
              height={screenWidth < 1300 ? 255 : '100%'}
            />
          </div>
        </div>
      </div>
    )
  }
  if (error) {
    return <p>{error}</p>
  }
  return (
    <div className='flex flex-column gap-3'>
      <IndividualSummary
        key={resume?.length}
        data={resume?.[0]}
      />
      <div className='flex summary-charts-container'>
        <div className='flex flex-column flex-1'>
          <ActiveProjectsChart
            data={activeProjectsChart}
          />
          <Divider />
          <CategoriesChart
            data={categoriesChart}
          />
        </div>
        <Divider
          vertical={screenWidth > 1300}
        />
        <div className='flex-1'>
          <PerformanceTable
            key={activeProjectsChart?.length}
            data={activeProjectsChart}
          />
        </div>
      </div>
    </div>
  )
}

export default IndividualReportSummary
