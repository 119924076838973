import { signInWithEmailAndPassword, setPersistence, browserSessionPersistence, browserLocalPersistence, sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../config/firebase.config'
import { api } from './base/api.service'
import { API_PERFIL } from '../consts/apiRoutes.consts'

/**
 *
 * @param {string} email
 * @param {string} password
 * @param {boolean} rememberme
 * @returns
 */
export function loginWithEmail (email, password, rememberme = false) {
  const persistence = rememberme ? browserLocalPersistence : browserSessionPersistence
  return setPersistence(auth, persistence).then(() => {
    return signInWithEmailAndPassword(auth, email, password).then((res) => {
      const { uid } = res.user
      return { uid }
    })
  })
}

export async function logout () {
  auth.signOut()
}

/**
 *
 * @param {function(CurrentUser): void} callback
 * @returns {import('firebase/auth').Unsubscribe}
 */
export function onAuthStateChanged (callback) {
  return auth.onAuthStateChanged(async (user) => {
    let data = null
    if (user) {
      try {
        let {
          id,
          name,
          lastName,
          email,
          nameTag,
          urlSpreadSheet,
          teamMemberID,
          userRole,
          projectRole,
          hasCompletedBasicTutorial,
          hasCompletedTaskTutorial,
          teamAreaId
        } = await getUserData()
        if (!id && !name && !userRole) {
          return logout()
        }
        if (projectRole === 'admin' && !urlSpreadSheet) {
          projectRole = 'adminWithoutReport'
        }
        data = {
          id,
          uid: user.uid,
          nombre: `${name} ${lastName}`,
          email,
          nameTag,
          teamMemberID,
          rol: userRole,
          projectRole,
          hasCompletedBasicTutorial,
          hasCompletedTaskTutorial,
          teamAreaId
        }
      } catch (error) {
        console.error('Error getting user data', error)
      }
    }
    callback(data)
  })
}

export function getToken () {
  return auth.currentUser?.getIdToken()
}

/**
 * Queries user current user data from backend
 * @returns {Promise<UserProfile>}
 */
function getUserData () {
  return api.get(API_PERFIL).then(r => r.json()).then(r => r.result || {})
}

/**
 *
 * @param {string} email
 * @returns
 */
export function recoverPassword (email) {
  return sendPasswordResetEmail(auth, email)
}
