import React from 'react'
import ReportBadgeWithTooltip from './ReportBadgeWithTooltip.molecule'

export const InfoBadge = ({
  iconClassName = 'pi pi-comment',
  ...props
}) => {
  return (
    <ReportBadgeWithTooltip
      type='info'
      iconClassName={`${iconClassName} text-white`}
      {...props}
    />
  )
}

export const WarningBadge = ({
  iconClassName = 'pi pi-history',
  ...props
}) => {
  return (
    <ReportBadgeWithTooltip
      type='warning'
      iconClassName={`${iconClassName}`}
      {...props}
    />
  )
}

export const ErrorBadge = ({
  iconClassName = 'pi pi-exclamation-triangle',
  legendProps = {
    style: {
      color: 'var(--clr-danger)',
      fontSize: '12px',
      fontWeight: 'bold'
    }
  },
  ...props
}) => {
  return (
    <ReportBadgeWithTooltip
      type='danger'
      iconClassName={`${iconClassName} text-white`}
      legendProps={legendProps}
      {...props}
    />
  )
}
