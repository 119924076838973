import React from 'react'
import { Calendar as PrimeCalendar } from 'primereact/calendar'
import { DateTime } from 'luxon'

/**
 *
 * @param {import("primereact/calendar").CalendarProps} props
 * @returns
 */
const Calendar = (props) => {
  const { time, ...proptypes } = props
  let date
  if (time && props.value) {
    const [hour, minute, second] = props.value.toString().split(' ')[4].split(':')
    date = props.value
      ? DateTime.fromISO(new Date(props.value).toISOString()).set({ hour, minute, second }).toJSDate()
      : props.value
  } else {
    date = props.value
    if (props.value) {
      if (Array.isArray(props.value)) {
        date = props.value.map((_date) => {
          if (!_date) {
            return _date
          }
          return DateTime.fromISO(_date).set({ hour: 12 }).toJSDate()
        })
      } else {
        // date = DateTime.fromISO(new Date(props.value).toISOString()).toJSDate()
        date = DateTime.fromISO(new Date(props.value).toISOString()).set({ hour: 12 }).toJSDate()
      }
    }
  }
  const newProps = { ...proptypes, value: date }
  return (
    <PrimeCalendar {...newProps} dateFormat={props.dateFormat ? props.dateFormat : 'dd/mm/yy'}/>
  )
}

export default Calendar
