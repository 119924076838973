import React, { useRef } from 'react'
import Card from '../../../components/atoms/Card.atom'
import ErrorMessageForm from '../../../components/atoms/ErrorMessageForm.atom'
import FileUpload from '../../../components/atoms/FileUpload.atom'
import ImageView from '../../../components/atoms/ImageView.atom'
import SecondaryTitle from '../../../components/atoms/SecondaryTitle.atom'
import DangerButton from '../../../components/molecules/buttons/DangerButton.molecule'
import { TYPES_IMAGES_ACCEPTED } from '../../../consts/modules/usuarios.consts'
import { hasAnyErrors, validateFieldValue } from '../../../utils/validations.utils'

const FormUploadFirmaDeUsuario = ({
  firmaFile,
  userDataSelected,
  setUserDataSelected,
  validations,
  setValidations,
  setFirmaFile,
  error
}) => {
  const ref = useRef()
  const firmaUrl = userDataSelected?.firmaUrl
  const handleSelect = (e) => {
    setFirmaFile(e.files[0])
    setValidations(validateFieldValue(validations, 'firma', e.files[0]))
  }
  const handleCleanFile = () => {
    ref.current.clear()
    setUserDataSelected({ ...userDataSelected, firmaUrl: null })
    setFirmaFile(null)
    setValidations(validateFieldValue(validations, 'firma', null))
  }
  const renderImage = firmaFile || firmaUrl
  let imageSource = null

  if (firmaFile) {
    imageSource = firmaFile.objectURL
  } else if (firmaUrl) {
    imageSource = firmaUrl
  }
  const footer = hasAnyErrors(error) ? <ErrorMessageForm errorMessage={error[0]?.errorMessage }/> : null
  return (
    <>
      <SecondaryTitle title="Firma del usuario"/>
      <Card footer={footer}>
        {
          renderImage && <ImageView src={imageSource}/>
        }
        <FileUpload
          ref={ref}
          mode="basic"
          chooseLabel="Seleccione una firma"
          accept={TYPES_IMAGES_ACCEPTED}
          maxFileSize="10000000"
          customUpload={true}
          cancelLabel="Cancelar"
          onSelect={handleSelect}
          className={'p-invalid'}
        />
        <DangerButton label="Eliminar" onClick={handleCleanFile}/>
      </Card>
    </>
  )
}

export default FormUploadFirmaDeUsuario
