/* eslint-disable */
import React from 'react'
import Button from '../../../../components/atoms/Button.atom'
import resetFilterIcon from '../../../../design/assests/images/icons/reset_filter.svg'
import Tooltip from '../../../../components/atoms/Tooltip.atom'

/**
 * @typedef {import("primereact/button").ButtonProps} ButtonProps
 * @param {ButtonProps} props
 * @returns {JSX.Element}
 */
function ResetButton(props) {
    return (
        <>
            <Tooltip target=".reset-button" content="Restablecer filtros" position="top" />
            <Button
                style={{ width: 45, height: 45, display: "flex", justifyContent: "center", alignItems: "center", background: "#006AA6" }}
                className="p-button-rounded p-button-secondary reset-button"
            {...props} >
                <img src={resetFilterIcon} />
            </Button>
        </>
    )
}

export default ResetButton