import React from 'react'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'
import FlagIcon from '@mui/icons-material/Flag'
import IconMaterial from '../atoms/IconMaterial.atom'

export function FiltroGuiaIcon ({ esFiltroGuia, onToggleFiltroGuia, name }) {
  const handleToggleFiltroGuia = (esFiltroGuia) => {
    onToggleFiltroGuia?.({ esFiltroGuia, name })
  }

  if (esFiltroGuia === true) {
    return (
      <IconMaterial
        icon={FlagIcon}
        style={{ color: '#167DD7' }}
        onClick={() => handleToggleFiltroGuia?.(false)}
      />
    )
  }
  if (esFiltroGuia === false) {
    return (
      <IconMaterial
        icon={FlagOutlinedIcon}
        style={{ color: '#4F5555' }}
        onClick={() => handleToggleFiltroGuia?.(true)}
      />
    )
  }
  return null
}
