import React, { useEffect, useState, useRef } from 'react'
import { getIndividualTasksOfTeamMemberId } from '../../../services/report.service'
import RegisteredTasksDataTable from './tables/RegisteredTasksTable/RegisteredTaskTable.controller'
import ContextMenu from '../../../components/atoms/ContextMenu.atom'
import ModalFormEditTask from '../../../components/compounds/ModalFormEditTask.compound'
import ProgressSpinner from '../../../components/atoms/misc/ProgressSpinner.atom'
import useToast from '../../../hooks/useToast.hook'

const RegisteredTasks = React.forwardRef(({
  teamMembers,
  teamMember,
  period,
  dateRange,
  setShowControls
}, ref) => {
  const [tasks, setTasks] = useState([])
  const [selectedTask, setSelectedTask] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [editTaskVisible, setEditTaskVisible] = useState(false)
  const [updatingTask, setUpdatingTask] = useState(null)
  const { setErrorMessage } = useToast()
  const DEBOUNCE_DELAY = 500
  const debounceTimeout = useRef(null)

  useEffect(() => {
    if (!teamMember?.id || !dateRange?.startDate || !dateRange?.endDate || !period) {
      return
    }
    if (!teamMember || Object.keys(teamMember || {}).length < 2
    ) {
      return
    }
    setUpdatingTask(null)
    setLoading(true)
    if (period === 'anual') {
      return
    }
    clearTimeout(debounceTimeout.current)
    debounceTimeout.current = setTimeout(() => {
      gettingIndividualTasks(true)
        .finally(() => {
          setLoading(false)
          setShowControls(true)
        })
    }, DEBOUNCE_DELAY)
    return () => clearTimeout(debounceTimeout.current)
  }, [teamMember, dateRange, period])

  useEffect(() => {
    if (error) {
      setErrorMessage({ message: error })
    }
  }, [error])

  const gettingIndividualTasks = async (globalLoad = false) => {
    try {
      if (!teamMember?.id || !dateRange?.startDate || !dateRange?.endDate) {
        setLoading(false)
        return
      }
      if (globalLoad) {
        setLoading(true)
      }
      const response = await getIndividualTasksOfTeamMemberId(teamMember.id, dateRange.startDate, dateRange.endDate)
      if (response.success) {
        setTasks(response.result)
      } else {
        setError(response.message || 'Error desconocido')
      }
    } catch (err) {
      setError('Ocurrió un error al obtener las tareas')
    } finally {
      setLoading(false)
    }
  }

  const cm = useRef(null)
  const cmItems = [
    {
      label: 'Editar',
      icon: 'pi pi-pencil',
      command: async () => {
        setEditTaskVisible(true)
      }
    }
  ]

  if (loading) {
    return (
      <div className='w-full h-20rem flex align-items-center justify-content-center'>
        <ProgressSpinner />
      </div>
    )
  }
  // if (teamMember && Object.keys(teamMember).length < 2) return 'XD'
  return (
    <>
      <RegisteredTasksDataTable
        ref={ref}
        period={period}
        data={tasks}
        setSelectedTask={setSelectedTask}
        updatingTask={updatingTask}
        cm={cm}
      />
      <ContextMenu ref={cm} model={cmItems} />
      <ModalFormEditTask
        visible={editTaskVisible}
        setVisible={setEditTaskVisible}
        task={selectedTask}
        teamMemberId={teamMember?.id}
        onSubmit={() => {
          setUpdatingTask(selectedTask)
        }}
        onEditCompleted={(errorMessage) => {
          if (errorMessage) {
            setError(errorMessage)
          }
          gettingIndividualTasks()
          setEditTaskVisible(false)
          setUpdatingTask(null)
        }}
        onlyActiveProjects={false}
        isAdminEdit
        waitForResponse={false}
      />
    </>
  )
})

export default RegisteredTasks
