import { useContext } from 'react'
import { SessionContext } from '../../contexts/auth/SessionContext'

/**
 * Hook to access the session context. It returns the logged in user object.
 * @returns {{ user: CurrentUser | undefined | null }}
 */
export function useSession () {
  const context = useContext(SessionContext)
  if (context === undefined) {
    throw new Error('useSession must be used within a SessionProvider')
  }
  return context
}
