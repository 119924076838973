/**
 * @param {import("./buttons.utils").ButtonType} type
 * @returns {string}
 */
export function chooseButtonClass (type) {
  switch (type) {
  case 'text': return 'p-button-text clr-secondary'
  case 'outlined': return 'p-button-outlined'
  case 'block': return 'w-full'
  case 'link': return 'p-button-link'
  case 'rounded': return 'p-button-rounded'
  default: return ''
  }
}
