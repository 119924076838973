import React from 'react'
import Button from '../../atoms/Button.atom'
import PropTypes from 'prop-types'
import { chooseButtonClass } from '../../../utils/buttons.utils'

export default function DangerButton ({ btnType = 'regular', ...props }) {
  return <Button label={props.label} icon={props.icon} onClick={props.onClick} className={`p-button-danger ${chooseButtonClass(btnType)}`} disabled={props.disabled}/>
}

DangerButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['regular', 'text', 'outlined']),
  disabled: PropTypes.bool
}
