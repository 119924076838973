import React, { useEffect, useState } from 'react'
import Dropdown from '../../../../components/atoms/Dropdown.atom'
import { getTeamMembers } from '../../../../services/teamMember.service'
import DropdownCustomFilterSearch from '../../../../components/molecules/DropdownCustomFilterSearch.molecule'

const TeamMemberDropdown = ({
  selectedTeamMember,
  setSelectedTeamMember,
  setTeamMembers,
  placeholder = 'Selecciona un miembro',
  optionLabel = 'name',
  ...props
}) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    const fetchTeamMembers = async () => {
      const response = await getTeamMembers()
      if (response.success) {
        setOptions(response.result)
        setTeamMembers(response.result)
      }
    }
    fetchTeamMembers()
  }, [])

  useEffect(() => {
    if (options.length > 0 && selectedTeamMember) {
      const member = options.find(option => option.id === selectedTeamMember.id)
      setSelectedTeamMember(member)
    }
  }, [options, selectedTeamMember, setSelectedTeamMember])

  const itemTemplate = item => (
    <div className='overflow-hidden white-space-nowrap max-w-14rem text-overflow-ellipsis'>
      <i className="pi pi-user mr-2"></i>
      <span>{item?.nameTag} - </span>
      <span>
        {item?.name} {item?.lastName}
      </span>
    </div>
  )

  return (
    // options.length > 0 &&
    <Dropdown
      options={options}
      value={selectedTeamMember}
      onChange={e => {
        const selected = options.find(option => option.id === e.value.id)
        setSelectedTeamMember(selected)
      }}
      optionLabel={optionLabel}
      placeholder={placeholder}
      itemTemplate={itemTemplate}
      valueTemplate={(item) => {
        if (!item) return placeholder
        return itemTemplate(item)
      }}
      filter
      filterBy='nameTag,name,lastName'
      filterTemplate={(props) => <DropdownCustomFilterSearch props={props} />}
      emptyFilterMessage='Sin resultados ¿Seguro que trabaja aquí?'
      disabled={options.length === 0}
    />
  )
}

export default TeamMemberDropdown
