/* eslint-disable */
import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'

const SORT_ORDER_OPTIONS = [
  { label: 'Ascendente', value: 1 },
  { label: 'Descendente', value: -1 }
]

const TeamMemberSortFilter = ({
  filterOptions,
  teamMembers,
  sortOrder,
  onSortChange
}) => {
  return (
    <div className='flex flex-column gap-3' data-testid='team-member-sort-filter'>
      <Dropdown
        options={SORT_ORDER_OPTIONS}
        value={sortOrder}
        onChange={onSortChange}
        optionLabel="label"
        placeholder="Ordenar"
      />
      <MultiSelect
        options={teamMembers}
        value={filterOptions.value}
        onChange={(e) => filterOptions.filterCallback(e.value)}
        optionLabel="label"
        optionValue="value"
        placeholder="Filtrar integrante"
        maxSelectedLabels={1}
        selectedItemsLabel='{0} integrantes seleccionados'
      />
    </div>
  )
}

export default TeamMemberSortFilter
