import React from 'react'
import DataTable from '../../../../components/atoms/table/DataTable.atom'
import Column from '../../../../components/atoms/table/Column.atom'
import ClientChip from '../../../../components/molecules/ClientChip.molecule'
import {
  ErrorBadge
} from '../../../../components/molecules/CellReportBadges.molecule'
import TextIconWithTooltip from '../../../../components/molecules/TextIconWithTooltip.molecule'
import { floatToPercentage, formatDecimals } from '../../../../utils/numbers.utils'
import EmptyDataTeamMemberMessage from '../../../../components/molecules/EmptyDataTeamMemberMessage.molecule'

const PerformanceTable = ({
  data
}) => {
  data = data?.sort((a, b) => a.clientId - b.clientId)
  return (
    <div className='flex flex-column gap-4'>
      <span className='text-4xl font-semibold'>Rendimiento por proyectos</span>
      <DataTable
        value={data}
        emptyCustomMessage={<EmptyDataTeamMemberMessage height={188}/>}
      >
        <Column
          header='Proyecto'
          body={(rowData) => (
            <div className='flex align-items-center' style={{ gap: '10px' }}>
              <ClientChip code={rowData.clientTagName} className='w-3rem text-center flex justify-content-center'/>
              <span>{rowData.projectTagName}</span>
            </div>
          )}
          style={{ paddingLeft: '7px' }}
        />
        <Column
          header='Horas trabajadas'
          // field='sisuTime'
          style={{ paddingLeft: '7px' }}
          body={(rowData) => (
            <div className='flex gap-1 w-4rem justify-content-between'>
              {formatDecimals(rowData.sisuTime)}
            </div>
          )}
        />
        <Column
          header='Horas facturadas'
          field='billedTime'
          style={{ paddingLeft: '7px' }}
          body={(rowData) => (
            <div className='flex gap-1 w-4rem justify-content-between'>
              {formatDecimals(rowData.billedTime)}
              {rowData.billedTime !== rowData.sisuTime && (
                <ErrorBadge
                  tooltipValue='Las horas facturadas no coinciden con las horas trabajadas'
                />
              )}
            </div>
          )}
        />
        <Column
          header={
            <TextIconWithTooltip
              icon='pi pi-info-circle'
              tooltipValue='Porcentaje de horas facturadas respecto al total de horas trabajadas'
              content='Rendimiento'
            />
          }
          body={(rowData) => {
            if (rowData?.clientId === 1) return 'N/A'
            return (
              <span>{floatToPercentage(formatDecimals(rowData.performance, 4))}</span>
            )
          }}
          style={{ paddingLeft: '7px' }}
        />
      </DataTable>
    </div>
  )
}

export default PerformanceTable
