import ViewCozyIcon from '@mui/icons-material/ViewCozy'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import BarChartIcon from '@mui/icons-material/BarChart'
import StorageIcon from '@mui/icons-material/Storage'
import AddIcon from '@mui/icons-material/Add'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'
import ArticleIcon from '@mui/icons-material/Article'
import ReplayIcon from '@mui/icons-material/Replay'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import BadgeIcon from '@mui/icons-material/BadgeOutlined'
import PaymentsIcon from '@mui/icons-material/Payments'
import HandymanIcon from '@mui/icons-material/Handyman'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import GroupIcon from '@mui/icons-material/GroupOutlined'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOnOutlined'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import ThermostatIcon from '@mui/icons-material/Thermostat'
import PestControlIcon from '@mui/icons-material/PestControlOutlined'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import Inventory2Icon from '@mui/icons-material/Inventory2Outlined'
import ScaleIcon from '@mui/icons-material/Scale'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import PointOfSaleIcon from '@mui/icons-material/PointOfSaleOutlined'
import EngineeringIcon from '@mui/icons-material/Engineering'
import LocalMallIcon from '@mui/icons-material/LocalMall'
import DnsIcon from '@mui/icons-material/Dns'
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined'
import PlumbingIcon from '@mui/icons-material/Plumbing'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import NextWeekIcon from '@mui/icons-material/NextWeekOutlined'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ClearIcon from '@mui/icons-material/Clear'
import MenuIcon from '@mui/icons-material/Menu'
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined'

export const COMMON_ICONS = {
  PLUMBING: PlumbingIcon,
  DNS: DnsIcon,
  DNS_OUTLINED: DnsOutlinedIcon,
  LOCAL_MALL: LocalMallIcon,
  ENGINEERING: EngineeringIcon,
  POINT_OF_SALE: PointOfSaleIcon,
  LOCAL_SHIPPING: LocalShippingIcon,
  LOCAL_SHIPPING_OUTLINED: LocalShippingOutlinedIcon,
  SCALE: ScaleIcon,
  SCALE_OUTLINED: ScaleOutlinedIcon,
  INVENTORY2: Inventory2Icon,
  TROUBLESHOOT: TroubleshootIcon,
  QUERY_STATS: QueryStatsIcon,
  PEST_CONTROL: PestControlIcon,
  THERMOSTAT: ThermostatIcon,
  LOCAL_ATM: LocalAtmIcon,
  MONETIZATION_ON: MonetizationOnIcon,
  GROUP: GroupIcon,
  AUTO_GRAPH: AutoGraphIcon,
  HANDYMAN: HandymanIcon,
  PAYMENTS: PaymentsIcon,
  BADGE: BadgeIcon,
  CARDS: ViewCozyIcon,
  GRAFICAS: BarChartIcon,
  ACTIVIDADES: CalendarMonthIcon,
  CONSULTA: StorageIcon,
  FORMULARIO_CREAR: AddIcon,
  MEDICO: LocalHospitalIcon,
  PEOPLE: PeopleAltIcon,
  BAG: ShoppingBagIcon,
  REPORTE: ArticleIcon,
  CICLOS: ReplayIcon,
  PROVEEDORES: SupervisorAccountIcon,
  ADD_FORMULARIO: PlaylistAddIcon,
  NEXT_WEEK: NextWeekIcon,
  ARROW_BACK: ArrowBackIosNewIcon,
  CLEAR_ICON: ClearIcon,
  MENU_ICON: MenuIcon
}
