import React from 'react'
import ProductBadgeWithTooltip from '../../molecules/ProductBadgeWithTooltip.molecule'

const ProductSubtitleWithBadge = ({ invernadero, withTooltip = false, productoBaseConfig }) => {
  const getVariedad = () => {
    if (!invernadero?.id) return ''
    if (invernadero?.variedadNombre) return invernadero.variedadNombre
    if (productoBaseConfig) return productoBaseConfig?.variedad || ''
    return invernadero?.esMedley ? invernadero?.medleyNombre : invernadero?.porcentajesCultivo[0]?.nombre
  }

  const productoCompleto = `${invernadero?.productoNombre || productoBaseConfig?.nombre} ${getVariedad() && `/ ${getVariedad()}`}`
  return (
    <div className='flex gap-2 align-items-center'>
      <ProductBadgeWithTooltip producto={invernadero?.productoNombre || productoBaseConfig?.nombre} tooltipValue={withTooltip || productoBaseConfig?.withTooltip ? productoCompleto : ''}/>
      {(invernadero?.id && !withTooltip) || !productoBaseConfig?.withTooltip
        ? <span className='text-2xl'>
          {productoCompleto}
        </span>
        : ''}
    </div>
  )
}

export default ProductSubtitleWithBadge
