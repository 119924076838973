import React, { useEffect } from 'react'
import Dropdown from '../../../../components/atoms/Dropdown.atom'
import { PERIODS } from '../../../../consts/reports.consts'
import useIsHoldingCtrlDown from '../../../../hooks/useIsHoldingCtrlDown'

/** @typedef {JSX.IntrinsicAttributes & JSX.IntrinsicElements['div']} JSXDivElement */

/**
 * @typedef {import("primereact/dropdown").DropdownProps & JSXDivElement & {
*   period: string,
*   setPeriod: (e: React.ChangeEvent<HTMLSelectElement>) => void,
*   includeYearly: boolean,
* }} PeriodDropdownProps
*/

/**
* @param {PeriodDropdownProps} props
* @returns {JSX.Element}
*/
const PeriodDropdown = ({
  period,
  setPeriod,
  includeYearly = false,
  ...props
}) => {
  const periods = [...PERIODS]
  if (includeYearly && !periods.find(p => p.value === 'anual')) {
    periods.push({ name: 'Anual', value: 'anual' })
  } else {
    const index = periods.findIndex(p => p.value === 'anual')
    if (index !== -1) {
      periods.splice(index, 1)
    }
  }

  const isHoldingCtrlDown = useIsHoldingCtrlDown()

  const onClick = (e) => {
    if (isHoldingCtrlDown) {
      e.stopPropagation()

      if (period === 'diario') {
        setPeriod('semanal')
      } else if (period === 'semanal') {
        setPeriod('mensual')
      } else {
        setPeriod('diario')
      }
    }
  }

  useEffect(() => {
    const dropdown = document.getElementById('period-droddown-individual-reports')
    dropdown.addEventListener('click', onClick)
    return () => {
      dropdown.removeEventListener('click', onClick)
    }
  }, [isHoldingCtrlDown, period])

  return (
    <Dropdown
      options={periods}
      value={period}
      onChange={e => setPeriod(e.value)}
      id="period-droddown-individual-reports"
      optionLabel='name'
      placeholder='Selecciona un periodo'
      className={`${isHoldingCtrlDown ? 'ctrl-selectable-dropdown' : ''}`}
      {...props}
    />
  )
}

export default PeriodDropdown
