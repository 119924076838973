import React from 'react'
import { ConfigProvider } from 'antd'
import esES from 'antd/locale/es_ES'
import DatePicker from '../../../components/atoms/DatePicker.atom'
import { DateTime } from 'luxon'
const { RangePicker } = DatePicker
const AntCalendarSandbox = () => {
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      console.log('From: ', dates[0], ', to: ', dates[1])
      console.log('From: ', dateStrings[0], ', to: ', dateStrings[1])
    } else {
      console.log('Clear')
    }
  }
  const rangePresets = [
    {
      label: 'Hoy',
      value: [
        DateTime.local(),
        DateTime.local()
      ]
    },
    {
      label: 'Semana actual',
      value: [
        DateTime.local().startOf('week'),
        DateTime.local().endOf('week')
      ]
    },
    {
      label: 'Semana anterior',
      value: [
        DateTime.local().startOf('week').plus({ weeks: -1 }),
        DateTime.local().endOf('week').plus({ weeks: -1 })
      ]
    },
    {
      label: 'Este mes',
      value: [
        DateTime.local().startOf('month'),
        DateTime.local().endOf('month')
      ]
    },
    {
      label: 'Mes anterior',
      value: [
        DateTime.local().startOf('month').plus({ months: -1 }),
        DateTime.local().endOf('month').plus({ months: -1 })
      ]
    },
    {
      label: 'Este año ',
      value: [
        DateTime.local().startOf('year'),
        DateTime.local().endOf('year')
      ]
    }
  ]
  return (
    <div>
      <ConfigProvider locale={esES}>
        <RangePicker
          presets={rangePresets}
          onChange={onRangeChange}
          suffixIcon={<i className='pi pi-calendar clr-secondary' />}
          separator={<i className='pi pi-arrow-right text-xs clr-secondary' />}
        />
      </ConfigProvider>
    </div>
  )
}

export default AntCalendarSandbox
