import React from 'react'

const EmptyDataTeamMemberMessage = ({
  height = 'auto',
  width = 'auto'
}) => {
  return (
    <div className='empty-data-teammember-message text-center flex flex-column justify-content-center' style={{ height: height, width: width }}>
      <i className="pi pi-folder-open text-3xl"></i>
      <h3 className='text-xl title'>Sin información</h3>
      <p className='message'>El integrante del equipo seleccionado no tiene registros, intenta cambiando los filtros.</p>
    </div>
  )
}

export default EmptyDataTeamMemberMessage
