import React, { useState } from 'react'
import ModalForm from '../../../../../components/compounds/modalForm/ModalForm.compunds'
import { formatHours } from '../../../../../utils/time.utils'
import InputTextForm from '../../../../../components/molecules/InputTextForm.molecule'
import SimpleMessage from '../../../../../components/atoms/SimpleMessage.atom'
import useToast from '../../../../../hooks/useToast.hook'
import TimeTemplate from './TimeTemplate'
import useTimeRegisterContext from '../../../hooks/useTimeRegisterContext.hook'

const MIN_HOURS = 7.5
const MAX_HOURS = 9

const FinishDay = ({
  visible,
  setVisible,
  task,
  todaySisuTime,
  todayBilledTime,
  onSubmit
}) => {
  const {
    showedMissingDayDialog,
    setShowedMissingDayDialog,
    setEditingMissingDay
  } = useTimeRegisterContext()
  const noBilledTime = Math.abs(todaySisuTime - todayBilledTime) || 0
  const [comment, setComment] = useState('')
  const [error, setError] = useState()
  const { setSuccessMessage } = useToast()

  const messageTemplate = () => (
    <div className='flex px-2 py-1 w-full'>
      <div className='flex flex-column gap-2'>
        <strong className='text-xl'>Toma nota</strong>
        <span>Evita editar las tareas después de finalizar tu día. Esto podría afectar el reporte de horas de los clientes ¡Gracias!</span>
      </div>
      <div>
        <i className="pi pi-paperclip clr-info text-2xl pl-4"></i>
      </div>
    </div>
  )

  return (
    <ModalForm
      visible={visible}
      setVisible={setVisible}
      header={
        <div className="flex gap-3 align-items-center">
          <i className="pi pi-check text-4xl" style={{ color: '#00B4FF' }} />
          <span className="text-3xl font-semibold font-cairo">Finalizar día</span>
        </div>
      }
      service={() => {
        if ((todaySisuTime < MIN_HOURS || todaySisuTime > MAX_HOURS) && !comment) {
          setError('Por favor, escribe un comentario')
          return
        }
        setVisible(false)
        setVisible(false)
        onSubmit(comment)
          .then(() => {
            setSuccessMessage({
              title: 'Finalizaste tu día',
              message: 'Tus tareas de hoy se han guardado correctamente.'
            })
            setError()
            if (showedMissingDayDialog) {
              setShowedMissingDayDialog(false)
              setEditingMissingDay(false)
            }
          })
          .catch((error) => {
            setError(error)
          })
      }}
    >
      <div className='finish-day-modal flex flex-column gap-3 pb-2'>
        {
          todaySisuTime !== 8
            ? <SimpleMessage
              severity='info'
              content={messageTemplate}
            />
            : <span>Antes de continuar, por favor, revisa que hayas registrado correctamente todas tus tareas de hoy.</span>
        }
        <span className='font-bold'>Resumen del día</span>
        <div className="grid w-full px-2">
          <div className="col-5 worker-hours flex flex-column gap-3">
            <TimeTemplate
              icon='pi pi-clock total-time-this-week-icon'
              label='Horas trabajadas'
              time={todaySisuTime}
              min={MIN_HOURS}
              max={MAX_HOURS}
            />
          </div>
          <div className="col-5 no-billed-hours flex flex-column gap-3">
            <span className='flex gap-2 align-items-center'>
              <i className="pi pi-dollar" style={{ color: '#F3D079' }}></i>
              Horas no facturadas
            </span>
            <span className='text-2xl font-semibold'>{formatHours(noBilledTime)}</span>
          </div>
        </div>
        {
          (todaySisuTime >= MIN_HOURS && todaySisuTime <= MAX_HOURS) && <span>¿Estás seguro de que deseas finalizar tu día?</span>
        }
        {
          todaySisuTime > MAX_HOURS &&
          <span>Parece que hoy has trabajado un poco más ¿Quieres compartir algún comentario?</span>
        }
        {
          todaySisuTime < MIN_HOURS &&
          <span>Parece que hoy has trabajado un poco menos ¿Quieres compartir algún comentario?</span>
        }
        {
          (todaySisuTime < MIN_HOURS || todaySisuTime > MAX_HOURS) &&
          <InputTextForm
            label='Comentario'
            placeholder='Escribe aquí tus comentarios'
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            error={error ? [{ errorMessage: error }] : null}
          />
        }
      </div>
    </ModalForm>
  )
}

export default FinishDay
