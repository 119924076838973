import React, { createContext, useEffect, useState } from 'react'

export const ThemeContext = createContext()
export function ThemeContextProvider ({ children }) {
  const [theme, setTheme] = useState('dark')
  useEffect(() => {
    const localTheme = localStorage.getItem('aikaTheme')
    if (localTheme) {
      setTheme(localTheme)
    }
  }, [])
  useEffect(() => {
    if (!window.navigator.onLine) return
    const themeTag = document.getElementById('theme-css')
    const layoutTag = document.getElementById('layout-css')
    // themeTag.href = `/assets/layout/css/blue/theme-${theme}.css`
    // layoutTag.href = `/assets/layout/css/blue/layout-${theme}.css`
    themeTag.href = `/assets/custom/${theme}/theme.css`
    layoutTag.href = `/assets/custom/${theme}/layout.css`
    localStorage.setItem('aikaTheme', theme)
    document.documentElement.style.setProperty('color-scheme', theme)
  }, [theme])
  const changeTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light'
    setTheme(newTheme)
    localStorage.setItem('theme', newTheme)
  }
  return (
    <>
      <ThemeContext.Provider value={{ changeTheme, theme }}>
        {children}
      </ThemeContext.Provider>
    </>
  )
}
