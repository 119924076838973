/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { generateHoursByTeamAndTeamMemberReport } from '../../../services/report.service'
import useReportFiltersContext from '../../../hooks/contextConsumers/useReportFiltersContext.hook'
import ProgressSpinner from '../../../components/atoms/misc/ProgressSpinner.atom'
import HoursByTeamAndTeamMemberReportDataTable from './tables/HoursByTeamAndTeamMemberReportDataTable/HoursByTeamAndTeamMemberReportDataTable'
import TeamMemberPicker from './components/TeamMemberPicker'
import PeriodPicker from './components/PeriodPicker'
import { DateTime } from 'luxon'
import ResetButton from './components/ResetButton'

function initialDateRange() {
  return {
    startDate: DateTime.local().toISODate(),
    endDate: DateTime.local().toISODate()
  }
}

const HoursByTeamAndTeamMemberReport = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [reportData, setReportData] = useState([])
  const [tabNavElement, setTabNavElement] = useState(null)
  const {
    hoursByTeamAndTeamMemberReportTeamMembers,
    setHoursByTeamAndTeamMemberReportTeamMembers,
    hoursByTeamAndTeamMemberReportSelectedTeamMembers,
    setHoursByTeamAndTeamMemberReportSelectedTeamMembers,
    hoursByTeamAndTeamMemberReportDateRange,
    setHoursByTeamAndTeamMemberReportDateRange
  } = useReportFiltersContext()

  useEffect(() => {
    if (!hoursByTeamAndTeamMemberReportDateRange?.startDate || !hoursByTeamAndTeamMemberReportDateRange?.endDate) return
    gettingReport()
  }, [hoursByTeamAndTeamMemberReportDateRange])

  const gettingReport = async () => {
    setError(null)
    setIsLoading(true)
    const response = await generateHoursByTeamAndTeamMemberReport(hoursByTeamAndTeamMemberReportDateRange.startDate, hoursByTeamAndTeamMemberReportDateRange.endDate, hoursByTeamAndTeamMemberReportSelectedTeamMembers)
    if (response.success) {
      setHoursByTeamAndTeamMemberReportTeamMembers(response.result.teamMembers)
      setReportData(response.result)
    } else {
      setError(response.message)
    }
    setIsLoading(false)
  }

  return (
    <>
      <div className={`mr-0 ml-1 flex flex-1 justify-content-between align-items-center pb-3`}>
        <div className='flex gap-3'>
          <TeamMemberPicker
            teamMembers={hoursByTeamAndTeamMemberReportTeamMembers}
            selectedTeamMembers={hoursByTeamAndTeamMemberReportSelectedTeamMembers}
            setSelectedTeamMembers={setHoursByTeamAndTeamMemberReportSelectedTeamMembers}
          />
          <PeriodPicker
            date={hoursByTeamAndTeamMemberReportDateRange}
            setDate={setHoursByTeamAndTeamMemberReportDateRange}
            showDateRange={false}
          />
        </div>
        <ResetButton onClick={() => {
          setHoursByTeamAndTeamMemberReportSelectedTeamMembers([])
          setHoursByTeamAndTeamMemberReportDateRange(initialDateRange())
          
          ref.current?.reset()
        }} />
      </div>

      {isLoading
        ? (
          <div className='w-full h-20rem flex align-items-center justify-content-center'>
            <ProgressSpinner />
          </div>
        )
        : error
          ? (
            <div>Error: {error}</div>
          )
          : (
            <HoursByTeamAndTeamMemberReportDataTable
              ref={ref}
              reportData={reportData}
              teamMembers={hoursByTeamAndTeamMemberReportSelectedTeamMembers}
              dateRange={hoursByTeamAndTeamMemberReportDateRange}
            />
          )}
    </>
  )
})

export default HoursByTeamAndTeamMemberReport
