/* eslint-disable */
import React from 'react'
import CustomDialog from '../../../../components/molecules/CustomDialog.molecule'
import PrimaryButton from '../../../../components/molecules/buttons/PrimaryButton.molecule'
import SecondaryButton from '../../../../components/molecules/buttons/SecondaryButton.molecule'
import useTimeRegisterContext from '../../hooks/useTimeRegisterContext.hook'
import { DateTime, Info } from 'luxon'
import {
  compareDates
} from '../../../../utils/date.utils'
const { createIgnoredWorkDay } = require('../../../../services/workDay.service')
const {useSession} = require('../../../../hooks/auth/useSession.hook')

const MissingDayDialog = () => {
  const {
    // missingDay,
    showMissingDayDialog,
    setShowMissingDayDialog,
    showedMissingDayDialog,
    setShowedMissingDayDialog,
    selectedDate,
    setSelectedDate,
    editingMissingDay, setEditingMissingDay,
    missingDays, setMissingDays, 
    setIsDayEditable,
    setRefresh,
    showUncompleteDayDialog,
    showedUncompleteDayDialog,
    lastWorkDay
    // hasTasks,
    // setTriggerFinishDay
  } = useTimeRegisterContext()
  const {
    user
  } = useSession()
  // if (showUncompleteDayDialog || showedUncompleteDayDialog) return null
  return (
    <CustomDialog
      key={selectedDate}
      visible={showMissingDayDialog}
      setVisible={setShowMissingDayDialog}
      headerTitle="Día sin registrar"
      headerIconClass="pi pi-exclamation-triangle clr-danger"
      withoutDivider
      footer={(
        <div className="flex justify-content-end">
          <SecondaryButton
            label="Registrar tareas"
            onClick={() => {
              setShowMissingDayDialog(false)
              setShowedMissingDayDialog(true)
              // setSelectedDate(new Date())
              setEditingMissingDay(true)
              setIsDayEditable(true)
            }}
            className='clr-secondary'
            type='text'
          />
          <PrimaryButton
            label="Ignorar día"
            icon="pi pi-check"
            className='m-0'
            onClick={async () => {
              console.log('ignorar dia', selectedDate)
              const createdIgnoredWorkDay = await createIgnoredWorkDay(user.id, DateTime.fromJSDate(selectedDate).toISODate())
              setRefresh()
              setShowMissingDayDialog(false)
              setShowedMissingDayDialog(false)
              setEditingMissingDay(false)
              setSelectedDate(new Date())
            }}
            borderAccent
            rounded
          />
        </div>
      )}
    >
      {/* ¡Ups! No puedes continuar con tu registro de hoy, ya que tienes un dia sin registrar. ¿Qué te gustaría hacer? */}
      ¡Ups! Tienes el día { Info.weekdays('long')[DateTime.fromJSDate(selectedDate).weekday - 1]} en la semana actual sin registrar. ¿Qué te gustaría hacer?  
    </CustomDialog>
  )
}

export default MissingDayDialog
