import React from 'react'
import { Tooltip as PrimeTooltip } from 'primereact/tooltip'

/**
 * @param {import("primereact/tooltip").TooltipProps} props
 * @returns
 */
export default function Tooltip (props) {
  return <PrimeTooltip {...props}/>
}
