import React from 'react'
import DoughnutChart from '../../../../components/molecules/DoughnutChart.molecule'
import { formatDecimals } from '../../../../utils/numbers.utils'

const CategoriesChart = ({
  data
}) => {
  return (
    <DoughnutChart
      title="Categorías"
      labels={data?.map(cat => cat.specialCategoryTitle || cat.categoryTitle)}
      labelsTooltips={data?.map(cat => cat.specialCategoryTitle ? null : cat.categorySubtitle)}
      data={data?.map(cat => cat.sisuTime)}
      colors={data?.reduce((acc, cat, index) => {
        acc[cat.categoryTitle] = cat?.categoryColor || '#64748B'
        return acc
      }, {})}
      loading={data === undefined}
      width={375}
      height={219}
      tooltipOptions={{
        backgroundColor: '#424953',
        padding: 10.5,
        titleFont: {
          size: 14,
          weight: 'bold',
          family: 'Mulish, Arial, sans-serif'
        },
        bodyFont: {
          size: 12,
          family: 'Mulish, Arial, sans-serif'
        },
        displayColors: false,
        callbacks: {
          label: function (context) {
            const sum = context.dataset.data.reduce((a, b) => a + b, 0)
            const percentage = context.raw / sum * 100
            return [`${formatDecimals(context.raw)} horas`, `${formatDecimals(percentage, 1)}%`]
          }
        }
      }}
    />
  )
}

export default CategoriesChart
