import React from 'react'
import { AuthLayout } from '../../components/layouts/AuthLayout.layout'
import { LoginForm } from './controllers/LoginForm.controller'
import { LOGIN_PHRASES } from '../../consts/auth.consts'

export function Login () {
  const RANDOM_LOGIN_PHRASE = LOGIN_PHRASES[Math.floor(Math.random() * LOGIN_PHRASES.length)]
  return (
    <AuthLayout title={RANDOM_LOGIN_PHRASE} float>
      <LoginForm />
    </AuthLayout>
  )
}
