/* eslint-disable */
import React, { useEffect } from "react";
import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";
import InputText from "../atoms/InputText.atom";

/**
 * @typedef {JSX.IntrinsicAttributes & JSX.IntrinsicElements['div']} JSXDivElement
 */

/**
 * @typedef {import('primereact/inplace').InplaceProps & {
 *   inplaceDisplayProps: JSXDivElement,
 *   inplaceContentProps: JSXDivElement,
 *   inplaceInputProps: omit<import("primereact/inputtext").InputTextProps, 'value' | 'onChange' | 'className'>,
 *   value: string,
 *   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
 *   placeholder: string,
 *   error: boolean
 * }} InputInplaceControlledProps
 */

/**
 * @param {InputInplaceControlledProps} props
 * @returns {JSX.Element}
 */
export default function InputInplaceControlled({
  value,
  onChange,
  inplaceDisplayProps,
  inplaceContentProps,
  inplaceInputProps,
  placeholder,
  error,
  ...props
}) {
  const [hastInteracted, setHasInteracted] = React.useState(false)
  useEffect(() => {
    if (value && !hastInteracted) {
      setHasInteracted(true)
    }
  }, [value])
  return (
    <Inplace {...props} className={`${props?.className} inplace-text ${!hastInteracted ? 'highlight' : ''}`}>
      <InplaceDisplay {...inplaceDisplayProps}>
        <div>
          {value || placeholder}
          {error ? (
            <i
              className="pi pi-exclamation-triangle mx-1"
              data-pr-tooltip="Este es un campo requerido"
              data-pr-position="bottom"
              style={{ color: "#FC3D39" }}
            />
          ) : null}
        </div>
      </InplaceDisplay>
      <InplaceContent {...inplaceContentProps}>
        <InputText
          autoFocus
          value={value}
          onChange={onChange}
          className="w-full"
          {...inplaceInputProps}
        />
      </InplaceContent>
    </Inplace>
  );
}
