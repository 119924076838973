import React, { useEffect } from 'react'
import { MainRouter } from './routers/MainRouter.router'
import PrimeReact, { addLocale, locale } from 'primereact/api'
import { ToastContextProvider } from './contexts/ToastContext.context'
import { SessionProvider } from './contexts/auth/SessionContext'
import { ThemeContextProvider } from './contexts/ThemeContext.context'
// import { Settings, FixedOffsetZone, DateTime } from 'luxon'

export default function App () {
  PrimeReact.ripple = true
  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    apply: 'Aplicar',
    clear: 'Limpiar',
    weekHeader: 'Sem',
    emptyFilterMessage: 'Misión fallida... no encontramos nada'
  })
  locale('es')
  // Settings.defaultZoneName = 'America/Mexico_City'
  // const fixedOffset = -6
  // Settings.defaultZone = FixedOffsetZone.instance(fixedOffset * 60)
  // console.log('Horario sincronizado con CDMX: ', DateTime.local().toISO())
  useEffect(() => {
    document.title = process.env.REACT_APP_TAB_TITLE
  }, [])
  return (
    <ThemeContextProvider>
      <ToastContextProvider>
        <SessionProvider>
          <MainRouter />
        </SessionProvider>
      </ToastContextProvider>
    </ThemeContextProvider>
  )
}
