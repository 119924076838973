import React from 'react'
import FormContainer from '../../../../../components/layouts/containers/FormContainer.container'
import FieldContainer from '../../../../../components/layouts/containers/FieldContainer.container'
import InputTextForm from '../../../../../components/molecules/InputTextForm.molecule'
import InputSwitchForm from '../../../../../components/molecules/InputSwitchForm.molecule'
import Label from '../../../../../components/atoms/Label.atom'

const FormProject = ({
  formData,
  handleChange,
  validations,
  layout = 'vertical'
}) => {
  if (!formData) return
  const cols = layout === 'horizontal' ? 4 : 12
  return (
    <FormContainer className='mt-3'>
      <FieldContainer md={cols}>
        <InputTextForm
          label='Nombre'
          placeholder='Nombre del proyecto'
          name='name'
          value={formData.name}
          onChange={handleChange}
          error={validations.name.errors}
        />
      </FieldContainer>
      <FieldContainer md={cols} className='mb-0'>
        <InputTextForm
          label='Código'
          placeholder='Código del proyecto'
          name='code'
          value={formData.code}
          onChange={handleChange}
          error={validations.code.errors}
        />
      </FieldContainer>
      {formData.active !== undefined && (
        <FieldContainer col={4} md={4} className='mb-0'>
          <Label className='mb-2'>
            Estatus
          </Label>
          <InputSwitchForm
            className='mt-1 success-slider'
            name="active"
            label={formData.active ? 'Activo' : 'Inactivo'}
            value={Boolean(formData.active)}
            onChange={handleChange}
          />
        </FieldContainer>
      )}
    </FormContainer>
  )
}

export default FormProject
