import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import TeamMemberDropdown from './components/TeamMemberDropdown'
import PeriodDropdown from './components/PeriodDropdown'
import DatePicker from './components/DatePicker'
import TabviewControllersContainer from '../../../components/layouts/containers/TabviewControllersContainer.container'
import { setLastIndividualTeamMemberReport } from '../../../services/report.service'

const IndividualReportControls = ({
  teamMembers,
  teamMember,
  setTeamMember,
  period,
  setPeriod,
  dateRange,
  endDate,
  setDateRange,
  setRefresh,
  currentTab,
  setTeamMembers
}) => {
  const [pickerContainer, setPickerContainer] = useState(null)
  useEffect(() => {
    const container = document.querySelector('#individual-report .p-tabview-nav')
    if (container) {
      setPickerContainer(container)
      setRefresh(true)
    }
  }, [setRefresh])

  useEffect(() => {
    if (teamMember) {
      settingLastTeamMemberReport(teamMember.id)
    }
  }, [teamMember])

  const settingLastTeamMemberReport = async (teamMemberId = null) => {
    await setLastIndividualTeamMemberReport(teamMemberId || teamMember?.id)
  }

  if (!pickerContainer) return null

  return createPortal(
    <TabviewControllersContainer>
      <TeamMemberDropdown
        teamMembers={teamMembers}
        selectedTeamMember={teamMember}
        setSelectedTeamMember={setTeamMember}
        setTeamMembers={setTeamMembers}
      />
      <PeriodDropdown
        period={period}
        setPeriod={setPeriod}
        includeYearly={currentTab === 1}
        style={{ minWidth: 126 }}
      />
      <DatePicker
        period={period}
        dateRange={dateRange}
        setDateRange={setDateRange}
        endDate={endDate}
        includeYearly={currentTab === 1}
      />
    </TabviewControllersContainer>,
    pickerContainer
  )
}

export default IndividualReportControls
