/* eslint-disable */
import React, { useEffect } from 'react'
import useSidebarContext from '../../hooks/contextConsumers/useSidebarContext.hook'
import { useNavigate } from 'react-router-dom'

const AppFiltersMenu = () => {
  const { keyPath } = useSidebarContext()
  const navigate = useNavigate()

  useEffect(() => {
    // if (!View && keyPath && location.pathname !== keyPath) {
    //   console.warn('ruteo', View, keyPath, location.pathname)
    //   return navigate(keyPath)
    // }
    if (!keyPath && location.pathname && location.pathname !== '/') return navigate(location.pathname)
    if (keyPath && keyPath !== '/') return navigate(keyPath)
  }, [keyPath])

  // this used to contain logic to render a non-used mobile nav menu

  return (
    <></>
  )
}

export default AppFiltersMenu