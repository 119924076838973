import React from 'react'
import { AuthLayout } from '../../components/layouts/AuthLayout.layout'
import { RecuperarContrasenaForm } from './controllers/RecuperarContrasenaForm.controller'

export function RecuperarContrasena () {
  return (
    <AuthLayout
      title="¡Tu contraseña fue secuestrada!"
      subtitle="Necesitamos tu correo para rescatarla."
      float
    >
      <RecuperarContrasenaForm />
    </AuthLayout>
  )
}
