import { AppRoutes } from './appRoutes.consts'
// import { COMMON_ICONS } from './filtersMenuCommonsIcons.consts'

export const APP_MENU = [
  {
    name: 'Registro de tareas',
    iconClass: 'pi pi-calendar-plus',
    path: AppRoutes.registroTiempos.index
  },
  {
    name: 'Reportes',
    iconClass: 'pi pi-chart-line',
    modules: [
      {
        name: 'Reporte general',
        path: AppRoutes.reporteGeneral.index
      },
      {
        name: 'Reporte individual',
        path: AppRoutes.reporteIndividual.index
      }
    ]
  },
  {
    name: 'Administración',
    iconClass: 'pi pi-briefcase',
    modules: [
      {
        name: 'Clientes',
        path: AppRoutes.clientes.index
      }
    ]
  },
  {
    name: 'Sandbox',
    iconClass: 'pi pi-code',
    path: AppRoutes.sandbox.index
  }
]
