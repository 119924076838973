export const roundReportData = (obj) => {
  for (const key in obj) {
    if (typeof obj[key] === 'number') {
      obj[key] = parseFloat(obj[key].toFixed(2))
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      roundReportData(obj[key])
    }
  }
  return obj
}

export const getUserWithMostDays = (dataset) => {
  if (!dataset || dataset.length === 0) return null
  const days = Object.keys(dataset[0].week).length
  let user = dataset[0]
  dataset.forEach((item) => {
    const userDays = Object.keys(item.week).length
    if (userDays > days) {
      user = item
    }
  })
  return user
}
