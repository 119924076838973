import { API_WORKDAY } from '../consts/apiRoutes.consts'
import SERVICE_CONSTS from '../consts/services.consts'
import { api } from './base/api.service'

const {
  RESPONSE_BASIC_STRUCTURE,
  MESSAGES
} = SERVICE_CONSTS

export const finishWorkDay = async (teamMemberID, date, comment, isEditFinishedDay = false) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_WORKDAY}/finishWorkDay`, {
      teamMemberID,
      date,
      comment,
      isEditFinishedDay
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const editFinishedWorkDay = async (teamMemberID, date, comment, isEditFinishedDay = true) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_WORKDAY}/editFinishedWorkDay`, {
      teamMemberID,
      date,
      comment,
      isEditFinishedDay
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getLastWorkDay = async (teamMemberID) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_WORKDAY}/getLastWorkDay`, {
      teamMemberData: {
        teamMemberID
      }
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getWorkDayAtDate = async (teamMemberID, date) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_WORKDAY}/getWorkDayAtDate`, {
      teamMemberData: {
        teamMemberID,
        date
      }
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const createIgnoredWorkDay = async (teamMemberID, date) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_WORKDAY}/createIgnoredWorkDay`, {
      teamMemberID,
      date
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getFirstWorkDayByCanSaveChanges = async (teamMemberID) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_WORKDAY}/getFirstWorkDayByCanSaveChanges`, {
      teamMemberID
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getLastWorkDayFromDate = async (teamMemberID, date) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_WORKDAY}/getLastWorkDayFromDate`, {
      teamMemberData: {
        teamMemberID,
        date
      }
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}
