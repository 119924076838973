/**
 * @description Contiene todas las rutas de la aplicación
 */

const administracionBaseRoute = '/administracion'
const usuariosRoutes = {
  index: `${administracionBaseRoute}/usuarios`,
  crear: `${administracionBaseRoute}/usuarios/formulario/crear`,
  actualizar: `${administracionBaseRoute}/usuarios/formulario/actualizar/:userId`
}

const registroTiemposBaseRoute = '/registro-de-tiempos'
const registroTiemposRoutes = {
  index: `${registroTiemposBaseRoute}`
}

const reporteGeneralBaseRoute = '/reporte-general'
const reporteGeneralRoutes = {
  index: `${reporteGeneralBaseRoute}`,
  horasSemana: `${reporteGeneralBaseRoute}/horas-semana`,
  horasProyecto: `${reporteGeneralBaseRoute}/horas-proyecto`,
  horasEquipo: `${reporteGeneralBaseRoute}/horas-equipo`,
  horasCliente: `${reporteGeneralBaseRoute}/horas-cliente`,
  horasClienteRedirect: `${reporteGeneralBaseRoute}/horas-cliente/:clientCode/:startDate/:endDate`
}

const reporteIndividualBaseRoute = '/reporte-individual'
const reporteIndividualRoutes = {
  index: `${reporteIndividualBaseRoute}`,
  redirect: `${reporteIndividualBaseRoute}/:teamMemberId/:startDate/:endDate/:period`
}

const reporteClienteBaseRoute = '/reporte-client'
const reporteClienteRoutes = {
  index: `${reporteClienteBaseRoute}`,
  redirect: `${reporteClienteBaseRoute}/:clientId/:startDate/:endDate/:period`
}

const clientesRoutes = {
  index: `${administracionBaseRoute}/clientes`,
  crear: `${administracionBaseRoute}/clientes/crear`,
  editar: `${administracionBaseRoute}/clientes/editar/:clientId`
}

const sandboxBaseRoute = '/sandbox'
const sandboxRoutes = {
  index: `${sandboxBaseRoute}`
}

export const AppRoutes = {
  home: {
    index: '/'
  },
  auth: {
    login: '/',
    logout: '/logout'
  },
  usuarios: usuariosRoutes,
  registroTiempos: registroTiemposRoutes,
  reporteGeneral: reporteGeneralRoutes,
  reporteIndividual: reporteIndividualRoutes,
  reporteCliente: reporteClienteRoutes,
  clientes: clientesRoutes,
  sandbox: sandboxRoutes
}
