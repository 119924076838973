import React, { useState } from 'react'
import ModalForm from '../../../../../components/compounds/modalForm/ModalForm.compunds'
import { checkAllFields, fieldsDoesNotHaveErrors, isNotEmpty, validateFieldValue } from '../../../../../utils/validations.utils'
import FormProject from './FormProject.controller'

const ModalFormCreateProject = ({
  visible,
  setVisible,
  setProject,
  projects
}) => {
  const initialFieldState = {
    name: {
      errors: [],
      value: null,
      typeValidations: [isNotEmpty]
    },
    code: {
      errors: [],
      value: null,
      typeValidations: [isNotEmpty]
    }
  }
  const [validations, setValidations] = useState(initialFieldState)
  const [formData, setFormData] = useState({
    name: '',
    code: ''
  })

  const handleChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value })
    const isDuplicate = projects.some(project => project[name] === value)
    const customError = {
      errorName: name,
      errorMessage: 'Ya existe un proyecto con estos datos'
    }
    setValidations(validateFieldValue(validations, name, value))
    // console.log('validations', validations, isDuplicate)
    setValidations({
      ...validations,
      [name]: {
        ...validations[name],
        errors: [
          ...(isDuplicate ? [customError] : [])
        ]
      }
    })
  }

  return (
    <ModalForm
      visible={visible}
      setVisible={setVisible}
      headerTitle='Crear Proyecto'
      headerIconClass='pi pi-folder-open'
      saveLabel='Crear'
      service={() => {
        checkAllFields(validations, setValidations)
        if (!fieldsDoesNotHaveErrors(validations)) return
        setProject(formData)
        setVisible(false)
        setFormData({ name: '', code: '' })
      }}
      disabledSaveBtn={validations.name.errors.length > 0 || validations.code.errors.length > 0}
    >
      <FormProject
        formData={formData}
        handleChange={handleChange}
        validations={validations}
      />
    </ModalForm>
  )
}

export default ModalFormCreateProject
