import React from 'react'
import AikaLogo from '../../design/assests/images/aika-logo.svg'
import AikaLogoDark from '../../design/assests/images/aika-logo-dark.svg'
import InputSwitch from '../../components/atoms/InputSwitch.atom'
import useThemeContext from '../../hooks/contextConsumers/useThemeContext.hook'

const AppHeader = () => {
  const { theme, changeTheme } = useThemeContext()
  return (
    <header className='desktop-header-container fixed top-0 left-0 w-full flex justify-content-between py-2 px-4 align-items-center shadow-2'
      style={{ zIndex: '1000', height: '60px', backgroundColor: `${theme === 'dark' ? '#0A5587' : 'white'}` }}
    >
      <img
        src={theme === 'dark' ? AikaLogoDark : AikaLogo}
        alt="Aika"
        height={42}
        className="block"
        style={{
          margin: '0 39px'
        }}
      />

      <div className='flex gap-4'>
        <div className="theme-toggler flex align-items-center gap-3">
          <InputSwitch
            checked={theme === 'dark'}
            onChange={changeTheme}
          />
          <span>Modo oscuro</span>
        </div>
      </div>
    </header>
  )
}

export default AppHeader
