import React from 'react'
import Card from '../../../components/atoms/Card.atom'
import SecondaryTitle from '../../../components/atoms/SecondaryTitle.atom'
import DynamicInputsForm from '../../../components/compounds/DynamicInputsForm.compound'
import { isAnEmail } from '../../../utils/validations.utils'

const FormEmailsNotificacionEmbarques = ({
  basicInfoFormState,
  setBasicInfoFormState,
  correosNotifiacionValidations,
  setCorreosNotifiacionValidations
}) => {
  return (
    <>
      <SecondaryTitle title="Correos a notificar embarques"/>
      <Card>
        <DynamicInputsForm
          label="Correo"
          name="correo"
          extraValidations={[isAnEmail]}
          placeholder="Ingrese un correo"
          value={basicInfoFormState.correosDeNotificacionEmbarques}
          validationsValue={correosNotifiacionValidations}
          onChange={(e) => setBasicInfoFormState({ ...basicInfoFormState, correosDeNotificacionEmbarques: e.newState })}
          onChangeValidations={(e) => setCorreosNotifiacionValidations(e.newState)}
        />
      </Card>
    </>
  )
}

export default FormEmailsNotificacionEmbarques
