import React from 'react'
import PropTypes from 'prop-types'

const SecondaryTitle = (props) => {
  return (
    <h2>{props.title}</h2>
  )
}

SecondaryTitle.propTypes = {
  title: PropTypes.string
}

export default SecondaryTitle
