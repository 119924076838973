import React from 'react'
import { BREAKPOINTS } from '../../consts/responsiveBreakpoints.consts'
import useScreenDimensionsContext from '../../hooks/contextConsumers/useScreenDimensionsContext.hook'

export default function AppOverlayBackground () {
  const { screenWidth, hideMobileMenu, setHideMobileMenu } = useScreenDimensionsContext()

  const toggleHideMobileMenu = () => {
    if (hideMobileMenu) {
      setHideMobileMenu(false)
    } else {
      setHideMobileMenu(true)
    }
  }

  if (screenWidth < BREAKPOINTS.TABLET_BREAKPOINT) {
    return (<div className='layout-bg-overlay' onClick={() => toggleHideMobileMenu()}></div>)
  }
  return <></>
}
