import React, { forwardRef } from 'react'
import { Dropdown as PrimeDropdown } from 'primereact/dropdown'

/**
 * @returns {JSX.Element}
 */
const Dropdown = forwardRef(
  /**
   * @param {import("primereact/dropdown").DropdownProps & React.RefAttributes<HTMLDivElement>} props
   * @param {React.Ref<HTMLDivElement>} ref
   * @returns {JSX.Element}
   */
  (props, ref) => {
    return (
      <PrimeDropdown
        emptyMessage={props.emptyMessage || 'Misión fallida... no encontramos nada'}
        ref={ref}
        {...props}
      />
    )
  }
)

export default Dropdown
