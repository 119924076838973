/* eslint-disable */
import React from 'react'
import MultiSelect from '../atoms/MultiSelect.atom'
import ClientChip from '../molecules/ClientChip.molecule'

const MultiSelectClientProjectFilter = ({
  data,
  options
}) => {
  const projects = data.map(client => ({
    clientId: client?.data?.clientId,
    name: client.data.name,
    code: client.data.code,
    items: client.children.map(child => ({
      clientId: client?.data?.clientId,
      name: child.data.name,
      code: child.data.name.split('-')[0].trim()
    }))
  }))
  const getSelectedItemsLabel = () => {
    const selectedValues = options.value || []
    const selectedParents = selectedValues.filter(value =>
      projects.some(project => project.name === value)
    )
    const adjustedSelectedCount = selectedValues.length - selectedParents.length
    return adjustedSelectedCount > 0
      ? `${adjustedSelectedCount} seleccionados`
      : 'Ningún proyecto seleccionado'
  }
  return (
    <MultiSelect
      value={options.value}
      options={projects}
      onChange={(e) => {
        if (!e?.value || !Array.isArray(e.value)) {
          return
        }
        const selectedNames = e.value.map(project => project?.name || project)
        const parentNames = [...new Set(
          projects
            .filter(project =>
              project.items.some(item => selectedNames.includes(item.name))
            )
            .map(project => project.name)
        )]
        const cleanedSelection = selectedNames.filter(name => {
          const parent = projects.find(project => project.name === name)
          if (parent) {
            const hasSelectedChildren = parent.items.some(item => selectedNames.includes(item.name))
            return hasSelectedChildren
          }
          return true
        })
        const finalSelection = [...new Set([...parentNames, ...cleanedSelection])]
        options.filterCallback(finalSelection)
      }}
      optionLabel="name"
      optionValue="name"
      placeholder={!projects || !projects?.length ? 'Sin opciones' : 'Todos'}
      optionGroupLabel='name'
      optionGroupChildren='items'
      optionGroupTemplate={(option) => {
        return (
          <ClientChip code={option.code} className='w-fit' />
        )
      }}
      maxSelectedLabels={0}
      selectedItemsLabel={getSelectedItemsLabel()}
      disabled={!projects || !projects?.length}
    />
  )
}

export default MultiSelectClientProjectFilter
