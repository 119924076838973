import { useState, useEffect } from 'react'

const getElementFullHeight = (element) => {
  if (!element) return 0
  const styles = window.getComputedStyle(element)
  const marginTop = parseFloat(styles.marginTop)
  const marginBottom = parseFloat(styles.marginBottom)
  const totalHeight = element.offsetHeight + marginTop + marginBottom
  return totalHeight
}
const useRegisteredTasksDynamicHeight = () => {
  const [fullHeight, setFullHeight] = useState(0)

  useEffect(() => {
    const updateHeight = () => {
      const mainLayout = document.querySelector('.layout-main-content.p-5')
      const title = document.querySelector('.flex.justify-content-between.mb-4')
      const tabViewNavContainer = document.querySelector('.p-tabview-nav-container')
      const tabViewPanels = document.querySelector('.p-tabview-panels')
      const tabViewPanelsPadding = (tabViewPanels && parseInt(window.getComputedStyle(tabViewPanels).paddingTop.replace('px', '')) +
        parseInt(window.getComputedStyle(tabViewPanels).paddingBottom.replace('px', ''))) || 0
      const desktopHeaderContainer = document.querySelector('.desktop-header-container')

      const newHeight = mainLayout?.offsetHeight -
        getElementFullHeight(title) -
        getElementFullHeight(tabViewNavContainer) -
        tabViewPanelsPadding -
        getElementFullHeight(desktopHeaderContainer)
      setFullHeight(Math.max(newHeight, 600))
    }

    window.addEventListener('resize', updateHeight)
    updateHeight()

    return () => window.removeEventListener('resize', updateHeight)
  }, [])

  return fullHeight
}
export default useRegisteredTasksDynamicHeight
