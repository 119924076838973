export const ERROR_RESPONSE_STRUCTURE = {
  success: false,
  message: 'Hubo un error',
  result: null
}
export const SUCCESS_RESPONSE_STRUCTURE = {
  success: true,
  message: 'Petición exitosa',
  result: null
}

export default {
  RESPONSE_BASIC_STRUCTURE: { success: false, message: 'Hubo un error' },
  MESSAGES: {
    SUCCESS: 'Petición exitosa'
  }
}
