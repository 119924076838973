/* eslint-disable */
import React, { useState } from 'react'
import Column from '../../../../../components/atoms/table/Column.atom'
import ColumnGroup from '../../../../../components/atoms/table/ColumnGroup.atom'
import Row from '../../../../../components/atoms/table/RowColumnGroup.atom'
import { CustomTreeTable as TreeTable } from '../../../../../components/atoms/table/CustomTreeTable.atom'
import { roundReportData } from '../../../../../utils/report.utils'
import { CLIENT_CHIP_COLUMN_PROPS } from '../../../../../consts/tables.consts'
import TextIconWithTooltip from '../../../../../components/molecules/TextIconWithTooltip.molecule'
import { floatToPercentage, formatDecimals, isNumber } from '../../../../../utils/numbers.utils'
import { TimeDifferenceBadge } from '../../components/CellReportBadges'
import MultiSelectClientProjectFilter from '../../../../../components/compounds/MultiSelectClientProjectFilter.compound'
import DifferenceReasonTable from '../../components/DifferenceReasonTable'

const HoursByProjectReportDataTable = React.forwardRef(({
  reportData: originalData,
  expandedKeys,
  setExpandedKeys
}, ref) => {
  // const [expandedKeys, setExpandedKeys] = useState({})
  const [filteredData, setFilteredData] = useState([])
  const [filters, setFilters] = useState({
    'data.name': { value: null, matchMode: 'in' }
  })
  const reportData = roundReportData(originalData)
  const resetFilters = () => {
    collapseAll()
    setFilters({
      'data.name': { value: null, matchMode: 'in' }
    })
    setFilteredData([])
  }

  const newRef = React.useRef()
  React.useImperativeHandle(ref, () => ({
    reset() {
      collapseAll()
      setFilters({
        'data.name': { value: null, matchMode: 'in' }
      });
      setFilteredData([])
      newRef.current.reset()
    }
  }), [])

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="Cliente/Proyecto"
          rowSpan={3}
          colSpan={2}
          field='data.name'
          filter
          filterField='data.name'
          filterElement={(options) =>
            <MultiSelectClientProjectFilter
              key={reportData.data.length}
              options={options}
              data={reportData.data}
            />
          }
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          filterMatchMode={'in'}
          onFilterApplyClick={(e) => {
            if (!e.constraints?.value) {
              collapseAll()
            } else {
              expandAll()
              setFilteredData(e.constraints?.value)
              setFilters({
                'data.name': { value: e.constraints?.value, matchMode: 'in' }
              })
            }
          }}
          onFilterClear={resetFilters}
        />
      </Row>
      <Row>
        <Column header="Distribución de horas" colSpan={2} rowSpan={1} />
        <Column header="Horas totales" colSpan={3} rowSpan={1} />
        <Column
          header={
            <TextIconWithTooltip
              content='Rendimiento'
              tooltipValue='Porcentaje de horas facturadas respecto al total de horas trabajadas'
            />
          }
          rowSpan={2}
          sortField='data.timePerformance'
          removableSort
        />
      </Row>
      <Row>
        <Column
          header="Trabajadas"
          colSpan={1}
          rowSpan={1}
          sortable
          sortField='data.distributionSisuTime'
          removableSort
        />
        <Column
          header="Facturadas"
          colSpan={1}
          rowSpan={1}
          sortable
          sortField='data.distributionBilledTime'
          removableSort
        />
        <Column
          header="Trabajadas"
          rowSpan={1}
          sortable
          sortField='data.sisuTime'
          removableSort
        />
        <Column
          header="Facturadas"
          rowSpan={1}
          sortable
          sortField='data.billedTime'
          removableSort
        />
        <Column
          header="Diferencia"
          rowSpan={1}
          sortable
          sortField='data.timeDifference'
          removableSort
        />
      </Row>
    </ColumnGroup>
  )

  const footerGroup = () => {
    return (
      <ColumnGroup>
        <Row>
          <Column footer="Totales" colSpan={2} />
          <Column footer={floatToPercentage(reportData?.totals?.distributionSisuTime)} />
          <Column footer={floatToPercentage(reportData?.totals?.distributionBilledTime)} />
          <Column footer={formatDecimals(reportData?.totals?.sisuTime)} />
          <Column footer={formatDecimals(reportData?.totals?.billedTime?.toFixed(2))} />
          <Column
            footer={() => {
              const totalDifference = reportData?.totals?.timeDifference
              const totalSisuTime = reportData?.totals?.sisuTime
              const percentageDifference = totalDifference / totalSisuTime
              return (
                <span className='flex gap-2 w-full'>
                  <span className='w-4'>{formatDecimals(totalDifference?.toFixed(2))}</span>
                  {
                    totalDifference > 0 &&
                    <TimeDifferenceBadge
                      value={totalDifference}
                      minTreshold={0}
                      maxTreshold={0}
                      referenceValue={0}
                      percentage={floatToPercentage(percentageDifference)}
                    />
                  }
                </span>
              )
            }}
          />
          <Column
            footer={() => {
              return isNumber(reportData?.totals?.timePerformance) ? floatToPercentage(reportData?.totals?.timePerformance, 1) : '-'
            }}
          />
        </Row>
      </ColumnGroup>
    )
  }
  const collapseAll = () => {
    setExpandedKeys({})
  }

  const expandAll = () => {
    const newExpandedKeys = {}
    reportData.data.forEach((node) => {
      newExpandedKeys[node.key] = true
    })
    setExpandedKeys(newExpandedKeys)
  }
  return (
    <>
      <TreeTable
        ref={newRef} 
        value={reportData.data}
        headerColumnGroup={headerGroup}
        footerColumnGroup={footerGroup()}
        expandedKeys={expandedKeys}
        onToggle={(e) => setExpandedKeys(e.value)}
        removableSort
        filters={filters}
        globalFilterFields={['data.name']}
      >
        <Column
          {...CLIENT_CHIP_COLUMN_PROPS}
        />
        <Column
          field="data.distributionSisuTime"
          header="Distribución horas trabajadas"
          body={(node) => <span>{floatToPercentage(node?.data?.distributionSisuTime)}</span>}
        />
        <Column
          field="data.distributionBilledTime"
          header="Distribución horas facturadas"
          sortable
          body={(node) => {
            return (
              <span>{node?.data?.distributionBilledTime < 0.001 ? '< 1%' : floatToPercentage(node?.data?.distributionBilledTime)}</span>
            )
          }}
        />
        <Column 
          field="data.sisuTime" 
          header="Total horas trabajadas"
          sortable 
        />
        <Column 
          field="data.billedTime" 
          header="Total horas facturadas"
          sortable />
        <Column
          field="data.timeDifference"
          header="Diferencia de horas"
          sortable
          body={(node) => {
            return (
              <span className="flex justify-content-between align-items-center w-full gap-2">
                <span className="w-4 text-left">
                  {node.data.timeDifference}
                </span>
                {
                  node.data.timeDifference > 0 &&
                  <span className="flex-grow-1 text-left">
                    <TimeDifferenceBadge
                      value={node.data.timeDifference}
                      minTreshold={0}
                      maxTreshold={0}
                      referenceValue={0}
                      percentage={floatToPercentage(node.data.timeDifference / node.data.sisuTime, 1)}
                      tooltip={
                        <DifferenceReasonTable
                          header={['Integrante', 'Motivo', 'Horas']}
                          body={node?.data?.taskTimeDifferenceComments}
                        />
                      }
                    />
                  </span>
                }
              </span>
            )
          }}
        />
        <Column
          field="data.timePerformance"
          header="Rendimiento"
          sortable
          body={(node) => {
            return (
              <span>
                {
                  node?.data?.timePerformance !== 'N/A' && node?.data?.clientId !== 1
                    ? floatToPercentage(node?.data?.timePerformance, 1)
                    : 'N/A'
                }
              </span>
            )
          }}
        />
      </TreeTable>
    </>
  )
})

export default React.memo(HoursByProjectReportDataTable)
