import React, { useEffect, useState } from 'react'
import Chip from '../atoms/misc/Chip.atom'
import DropdownWithTitleSubtitle from '../molecules/DropdownWithTitleSubtitle.molecule'

const DropdownCategoryFilter = ({
  value,
  onChange,
  label = 'Elige una categoría',
  name = 'categoryGroup',
  options,
  optionValue,
  optionLabel = 'categoryTitle',
  error,
  withoutChip = false,
  dropdownRef,
  isEditingCategoryDropdown,
  setIsEditingCategoryDropdown,
  onPressEnter,
  loading,
  formValidations,
  setFormValidations,
  handleFormSubmit,
  ...props
}) => {
  optionValue = options?.[0]?.specialCategoryID ? 'specialCategoryID' : 'categoryID'
  options = options?.map(o => ({ ...o, categoryFull: o?.categoryGroup + o?.categoryTitle + o?.categorySubtitle }))
  optionLabel = 'categoryFull'
  if (options) {
    if (options?.[0]?.specialCategoryID) {
      options = options?.sort((a, b) => a?.categoryTitle?.localeCompare(b?.categoryGroup))
    } else {
      options = options?.sort((a, b) => a?.categoryTitle?.localeCompare(b?.categoryTitle))
    }
  }

  const [categoryName, setCategoryName] = useState('')
  // const dropdownRef = useRef(null)
  const getCurrentOption = (value, key) => {
    return options?.find(o => o?.[key || optionValue] === value)
  }
  useEffect(() => {
    if (value) {
      const name = getCurrentOption(value)
      setCategoryName(name?.categoryTitle || name?.categoryGroup)
    } else {
      setCategoryName('')
    }
  }, [value])
  return (
    <div className='field m-0'>
      {
        isEditingCategoryDropdown
          ? <DropdownWithTitleSubtitle
            ref={dropdownRef}
            label={label}
            options={options}
            optionValue={optionValue}
            optionLabel={optionLabel}
            value={value}
            onChange={(e) => {
              onChange(options?.find(o => o?.[optionValue] === e?.value), e)
            }}
            name={name}
            // titleKey={`${optionValue === 'specialCategoryID' ? 'categoryName' : 'categoryTitle'}`}
            titleKey={`${optionValue === 'specialCategoryID' ? 'categoryGroup' : 'categoryTitle'}`}
            subtitleKey='categorySubtitle'
            fullWidth
            panelClassName={`max-w-15rem ${loading ? 'loading' : ''}`}
            onPressEnter={onPressEnter}
            formValidations={formValidations}
            setFormValidations={setFormValidations}
            handleFormSubmit={handleFormSubmit}
            {...props}
          />
          : <Chip
            className={`btn btn-primary w-full ${error ? 'error-chip' : ''}`}
            template={
              // eslint-disable-next-line
              <span className='p-chip-text text-overflow-ellipsis white-space-nowrap overflow-hidden max-w-12rem'>{categoryName || label} {error ? <i className='pi pi-exclamation-triangle' data-pr-tooltip="Este es un campo requerido" data-pr-position="bottom" /> : null}</span>
            }
            onClick={() => {
              setIsEditingCategoryDropdown(true)
              setTimeout(() => {
                dropdownRef.current?.show()
              }, 100)
            }}
          />
      }
    </div>
  )
}

export default DropdownCategoryFilter
