import React from 'react'
import DoughnutChart from '../../../../components/molecules/DoughnutChart.molecule'
import useClientColorsContext from '../../../../hooks/contextConsumers/useClientColorsContext.hook'
import { formatDecimals } from '../../../../utils/numbers.utils'

const ActiveProjectsChart = ({
  data
}) => {
  data = data?.sort((a, b) => a.clientName.localeCompare(b.clientName))
  const { colors } = useClientColorsContext()
  return (
    <DoughnutChart
      title="Proyectos activos"
      labels={data?.map(project => project.clientName)}
      data={data?.map(project => project.sisuTime)}
      colors={colors.reduce((acc, client) => { acc[client.name] = client.color; return acc }, {})}
      width={375}
      height={219}
      tooltipOptions={{
        backgroundColor: '#424953',
        padding: 10.5,
        titleFont: {
          size: 14,
          weight: 'bold',
          family: 'Mulish, Arial, sans-serif'
        },
        bodyFont: {
          size: 12,
          family: 'Mulish, Arial, sans-serif'
        },
        displayColors: false,
        callbacks: {
          title: (context) => {
            const project = data?.find(project => project?.clientName === context[0].label && project?.sisuTime === context[0].raw)
            if (!project) return context[0].label
            return `${project.clientTagName} - ${project.projectTagName}`
          },
          label: function (context) {
            const sum = context.dataset.data.reduce((a, b) => a + b, 0)
            const percentage = context.raw / sum * 100
            return [`${formatDecimals(context.raw)} horas`, `${formatDecimals(percentage, 1)}%`]
          }
        }
      }}
    />
  )
}

export default ActiveProjectsChart
